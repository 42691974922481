import { manageConstants } from '../constants';

export function museumblockslot(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_BLOCK_SLOT_REQUEST:
			return { gotblockedslot: false };
		case manageConstants.GET_MUSEUM_BLOCK_SLOT_SUCCESS:
			return { gotblockedslot: action.data };
		case manageConstants.GET_MUSEUM_BLOCK_SLOT_FAILURE:
			return { gotblockedslot: false };
		default:
			return state;
	}
}

export function postMuseumblockslot(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_MUSEUM_BLOCK_SLOT_REQUEST:
			return { addedblockedslot: 'loading' };
		case manageConstants.POST_MUSEUM_BLOCK_SLOT_SUCCESS:
			return { addedblockedslot: true };
		case manageConstants.POST_MUSEUM_BLOCK_SLOT_FAILURE:
			return { addedblockedslot: false };
		default:
			return state;
	}
}

export function putMuseumblockslot(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_MUSEUM_BLOCK_SLOT_REQUEST:
			return { updatedblockedslot:  'loading' };
		case manageConstants.PUT_MUSEUM_BLOCK_SLOT_SUCCESS:
			return { updatedblockedslot: true };
		case manageConstants.PUT_MUSEUM_BLOCK_SLOT_FAILURE:
			return { updatedblockedslot: false };
		default:
			return state;
	}
}

export function deleteMuseumblockslot(state = {}, action) {
	switch (action.type) {
		case manageConstants.DELETE_MUSEUM_BLOCK_SLOT_REQUEST:
			return { deletedblockedslot:  'loading' };
		case manageConstants.DELETE_MUSEUM_BLOCK_SLOT_SUCCESS:
			return { deletedblockedslot: true };
		case manageConstants.DELETE_MUSEUM_BLOCK_SLOT_FAILURE:
			return { deletedblockedslot: false };
		default:
			return state;
	}
}
export function getBlockedSlotDropDown(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_DROPDOWN_BLOCK_SLOT_REQUEST:
			return { blockedslotDropdown:  false};
		case manageConstants.GET_DROPDOWN_BLOCK_SLOT_SUCCESS:
			return { blockedslotDropdown: action.data };
		case manageConstants.GET_DROPDOWN_BLOCK_SLOT_FAILURE:
			return { blockedslotDropdown: false };
		default:
			return state;
	}
}