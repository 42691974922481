import { teacherbookingsConstants } from '../constants';

export function getBookingDetails(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.GET_BOOKED_TEACHER_REQUEST:
			return { teacherBooking: false, isLoading: true };
		case teacherbookingsConstants.GET_BOOKED_TEACHER_SUCCESS:
			return { teacherBooking: action.data, isLoading: false };
		case teacherbookingsConstants.GET_BOOKED_TEACHER_FAILURE:
			return { teacherBooking: false, isLoading: false };
		default:
			return state;
	}
}

export function PostMyBookings(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.POST_MY_BOOKINGS_REQUEST:
			return {myBookings: false, isLoading: true };
		case teacherbookingsConstants.POST_MY_BOOKINGS_SUCCESS:
			return {myBookings: action.data,isLoading: false };
		case teacherbookingsConstants.POST_MY_BOOKINGS_FAILURE:
			return {myBookings: false, isLoading: false };
		default:
			return state;
	}
}

export function DeleteMyBooking(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.DELETE_MY_BOOKING_REQUEST:
			return {isRemoved: false };
		case teacherbookingsConstants.DELETE_MY_BOOKING_SUCCESS:
			return {isRemoved: true };
		case teacherbookingsConstants.DELETE_MY_BOOKING_FAILURE:
			return {isRemoved: false };
		default:
			return state;
	}
}

export function PutMyBooking(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.PUT_MY_BOOKING_REQUEST:
			return { isChanged: false };
		case teacherbookingsConstants.PUT_MY_BOOKING_SUCCESS:
			return { isChanged: true };
		case teacherbookingsConstants.PUT_MY_BOOKING_FAILURE:
			return { isChanged: false };
		default:
			return state;
	}
}
export function GetMyBooking(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.GET_MY_BOOKING_REQUEST:
			return { myBooking: false, isLoading: true };
		case teacherbookingsConstants.GET_MY_BOOKING_SUCCESS:
			return { myBooking: action.data,isLoading: false };
		case teacherbookingsConstants.GET_MY_BOOKING_FAILURE:
			return { myBooking: false, isLoading: false };
		default:
			return state;
	}
}
export function getFeedbackQuestions(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_REQUEST:
			return { feedbackQuestions: false };
		case teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_SUCCESS:
			return { feedbackQuestions: action.data };
		case teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_FAILURE:
			return { feedbackQuestions: false };
		default:
			return state;
	}
}
export function postFeedbackQuestions(state = {}, action) {
	switch (action.type) {
		case teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_REQUEST:
			return { feedBackPost: false };
		case teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_SUCCESS:
			return { feedBackPost: action.data };
		case teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_FAILURE:
			return { feedBackPost: false };
		default:
			return state;
	}
}