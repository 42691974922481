import {favoriteConstants } from '../constants';
import {favoriteService} from '../services';
import { alertActions } from './';

export const favoriteActions = {
	getFavorite,
	postFavorite,
	deleteFavorite
}

function getFavorite(data) {
	return dispatch => {
		dispatch(request({ data }));
		favoriteService.getFavorite(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: favoriteConstants.GET_FAVORITE_REQUEST, data };
	}

	function success(data) {
		return { type: favoriteConstants.GET_FAVORITE_SUCCESS, data };
	}

	function failure(data) {
		return { type: favoriteConstants.GET_FAVORITE_FAILURE, data };
	}
}

function postFavorite(data) {
	return dispatch => {
		dispatch(request({ data }));
		favoriteService.postFavorite(data).then(
			response => {
				dispatch(success(response,data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: favoriteConstants.POST_FAVORITE_REQUEST, data };
	}

	function success(data,addedData) {
		return { type: favoriteConstants.POST_FAVORITE_SUCCESS, data,addedData:addedData };
	}

	function failure(data) {
		return { type: favoriteConstants.POST_FAVORITE_FAILURE, data };
	}
}

function deleteFavorite(data) {
	return dispatch => {
		dispatch(request({ data }));
		favoriteService.deleteFavorite(data).then(
			response => {
				dispatch(success(response,data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: favoriteConstants.DELETE_FAVORITE_REQUEST, data };
	}

	function success(data,deletedData) {
		return { type: favoriteConstants.DELETE_FAVORITE_SUCCESS, data,deletedData:deletedData };
	}

	function failure(data) {
		return { type: favoriteConstants.DELETE_FAVORITE_FAILURE, data };
	}
}
