export const tourBookingConstants = {
	PUT_PROGRAMME_SLOT_REQUEST: 'PUT_PROGRAMME_SLOT_REQUEST',
	PUT_PROGRAMME_SLOT_SUCCESS: 'PUT_PROGRAMME_SLOT_SUCCESS',
	PUT_PROGRAMME_SLOT_FAILURE: 'PUT_PROGRAMME_SLOT_FAILURE',
	
	BOOK_TOUR_REQUEST: 'BOOK_TOUR_REQUEST',
	BOOK_TOUR_SUCCESS: 'BOOK_TOUR_SUCCESS',
	BOOK_TOUR_FAILURE: 'BOOK_TOUR_FAILURE',

	GET_BOOKED_TOUR_REQUEST: 'GET_BOOKED_TOUR_REQUEST',
	GET_BOOKED_TOUR_SUCCESS: 'GET_BOOKED_TOUR_SUCCESS',
	GET_BOOKED_TOUR_FAILURE: 'GET_BOOKED_TOUR_FAILURE',

	
	STROED_BOOKED_ID: 'STROED_BOOKED_ID',
	CLEAR_BOOKED_ID: 'CLEAR_BOOKED_ID',
	
	GET_CONFIRM_SLOT_REQUEST: 'GET_CONFIRM_SLOT_REQUEST',
	GET_CONFIRM_SLOT_SUCCESS: 'GET_CONFIRM_SLOT_SUCCESS',
	GET_CONFIRM_SLOT_FAILURE: 'GET_CONFIRM_SLOT_FAILURE',
    
}