const initalState={
status:false,
message: ''
}
export function registerPopup(state =initalState, action) {
	switch (action.type) {
		case 'OPEN_REGISTER':
			return {
				status: action.status,
				message: action.message,
				token: action.token,


			};
		default:
			return state;
	}
}
