export const walkinConstants = {

	GET_WALKIN_REQUEST: 'GET_WALKIN_REQUEST',
	GET_WALKIN_SUCCESS: 'GET_WALKIN_SUCCESS',
	GET_WALKIN_FAILURE: 'GET_WALKIN_FAILURE',

	POST_WALKIN_REQUEST: 'POST_WALKIN_REQUEST',
	POST_WALKIN_SUCCESS: 'POST_WALKIN_SUCCESS',
	POST_WALKIN_FAILURE: 'POST_WALKIN_FAILURE',

	GET_MASTER_WALKIN_REQUEST: 'GET_MASTER_WALKIN_REQUEST',
	GET_MASTER_WALKIN_SUCCESS: 'GET_MASTER_WALKIN_SUCCESS',
	GET_MASTER_WALKIN_FAILURE: 'GET_MASTER_WALKIN_FAILURE'


}