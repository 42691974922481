import { userConstants } from '../constants';

export function updateuserprofile(state = {}, action) {
	switch (action.type) {
		case userConstants.UPDATE_PROFILE_REQUEST:
			return { updateProfileData: false };
		case userConstants.UPDATE_PROFILE_SUCCESS:
			return { updateProfileData: true };
		case userConstants.UPDATE_PROFILE_FAILURE:
			return { updateProfileData: false };
		default:
			return state;
	}
}
export function switchMusuem(state = {}, action) {
	switch (action.type) {
		case userConstants.SWITCH_MUSEUM_REQUEST:
			return { changeMuseum: 'loading' };
		case userConstants.SWITCH_MUSEUM_SUCCESS:
			return { changeMuseum: action.data };
		case userConstants.SWITCH_MUSEUM_FAILURE:
			return { changeMuseum: false };
		default:
			return state;
	}
}
