export const favoriteConstants = {

    GET_FAVORITE_REQUEST:'GET_FAVORITE_REQUEST',
    GET_FAVORITE_SUCCESS:'GET_FAVORITE_SUCCESS',
    GET_FAVORITE_FAILURE:'GET_FAVORITE_FAILURE',

    POST_FAVORITE_REQUEST:'POST_FAVORITE_REQUEST',
    POST_FAVORITE_SUCCESS:'POST_FAVORITE_SUCCESS',
    POST_FAVORITE_FAILURE:'POST_FAVORITE_FAILURE',

    DELETE_FAVORITE_REQUEST:'DELETE_FAVORITE_REQUEST',
    DELETE_FAVORITE_SUCCESS:'DELETE_FAVORITE_SUCCESS',
    DELETE_FAVORITE_FAILURE:'DELETE_FAVORITE_FAILURE',
}