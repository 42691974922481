export const reportsSAConstants ={

    GET_MASTER_REPORTSSA_REQUEST: 'GET_MASTER_REPORTSSA_REQUEST',
    GET_MASTER_REPORTSSA_SUCCESS: 'GET_MASTER_REPORTSSA_SUCCESS',
    GET_MASTER_REPORTSSA_FAILURE: 'GET_MASTER_REPORTSSA_FAILURE',

    POST_MASTER_REPORTSSA_REQUEST: 'POST_MASTER_REPORTSSA_REQUEST',
    POST_MASTER_REPORTSSA_SUCCESS: 'POST_MASTER_REPORTSSA_SUCCESS',
    POST_MASTER_REPORTSSA_FAILURE: 'POST_MASTER_REPORTSSA_FAILURE',

    PUT_TRACKER_RESOURCE_REQUEST: 'PUT_TRACKER_RESOURCE_REQUEST',
    PUT_TRACKER_RESOURCE_SUCCESS: 'PUT_TRACKER_RESOURCE_SUCCESS',
    PUT_TRACKER_RESOURCE_FAILURE: 'PUT_TRACKER_RESOURCE_FAILURE'
}