import { reportsSAConstants } from '../constants';


export function getReportsSA(state = {}, action) {
	switch (action.type) {
		case reportsSAConstants.GET_MASTER_REPORTSSA_REQUEST:
			return { getData: false };
		case reportsSAConstants.GET_MASTER_REPORTSSA_SUCCESS:
			return { getData: action.data };
		case reportsSAConstants.GET_MASTER_REPORTSSA_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function postReportsSA(state = {}, action) {
	switch (action.type) {
		case reportsSAConstants.POST_MASTER_REPORTSSA_REQUEST:
			return { isFailed:false,getData: false };
		case reportsSAConstants.POST_MASTER_REPORTSSA_SUCCESS:
			return { isFailed:false,getData: action.data };
		case reportsSAConstants.POST_MASTER_REPORTSSA_FAILURE:
			return { isFailed:true,getData: action.data };

		default:
			return state;
	}
}

export function putResourceTracker(state = {}, action) {
	switch (action.type) {
		case reportsSAConstants.PUT_TRACKER_RESOURCE_REQUEST:
			return { getData: false };
		case reportsSAConstants.PUT_TRACKER_RESOURCE_SUCCESS:
			return { getData: action.data };
		case reportsSAConstants.PUT_TRACKER_RESOURCE_FAILURE:
			return {getData: action.data };

		default:
			return state;
	}
}