import { newBookingConstants } from '../constants';

export function getnewbooking(state = {}, action) {
	switch (action.type) {
		case newBookingConstants.GET_NEW_BOOKING_REQUEST:
			return { getData: false };
		case newBookingConstants.GET_NEW_BOOKING_SUCCESS:
			return {getData: action.data };
		case newBookingConstants.GET_NEW_BOOKING_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function postnewbooking(state = {}, action) {
	switch (action.type) {
		case newBookingConstants.POST_NEW_BOOKING_REQUEST:
			return { getData: false ,loading:true };
		case newBookingConstants.POST_NEW_BOOKING_SUCCESS:
			return {getData: action.data };
		case newBookingConstants.POST_NEW_BOOKING_FAILURE:
			return { getData: 'error',error:action.data };
		default:
			return state;
	}
}

export function getprivatebookedslot(state = {}, action) {
	switch (action.type) {
		case newBookingConstants.GET_PRIVATE_BOOKED_SLOT_REQUEST:
			return { getData: false };
		case newBookingConstants.GET_PRIVATE_BOOKED_SLOT_SUCCESS:
			return {getData: action.data };
		case newBookingConstants.GET_PRIVATE_BOOKED_SLOT_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function putprivatebookedslot(state = {}, action) {
	switch (action.type) {
		case newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_REQUEST:
			return { getData: false };
		case newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_SUCCESS:
			return {getData: action.data };
		case newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}