import { manageConstants } from '../constants';

export function getRoleMuseum(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_ROLE_MUSEUM_REQUEST:
			return { museumRole: false };
		case manageConstants.GET_ROLE_MUSEUM_SUCCESS:
			return { museumRole: action.data };
		case manageConstants.GET_ROLE_MUSEUM_FAILURE:
			return { museumRole: false };
		default:
			return state;
	}
}

export function getMuseumAccount(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_ACCOUNT_REQUEST:
			return { museumsAccounts: false };
		case manageConstants.GET_MUSEUM_ACCOUNT_SUCCESS:
			return { museumsAccounts: action.data };
		case manageConstants.GET_MUSEUM_ACCOUNT_FAILURE:
			return { museumsAccounts: 'error' };
		default:
			return state;
	}
}

export function postMuseumList(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_MUSEUM_LIST_REQUEST:
			return { addedList: false };
		case manageConstants.POST_MUSEUM_LIST_SUCCESS:
			return { addedList: true };
		case manageConstants.POST_MUSEUM_LIST_FAILURE:
			return { addedList: false };
		default:
			return state;
	}
}

export function putMuseumList(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_MUSEUM_LIST_REQUEST:
			return { updatedslot: false };
		case manageConstants.PUT_MUSEUM_LIST_SUCCESS:
			return { updatedslot: true };
		case manageConstants.PUT_MUSEUM_LIST_FAILURE:
			return { updatedslot: false };
		default:
			return state;
	}
}

export function getMuseumLists(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_LIST_REQUEST:
			return { museumsLists: false };
		case manageConstants.GET_MUSEUM_LIST_SUCCESS:
			return { museumsLists: action.data };
		case manageConstants.GET_MUSEUM_LIST_FAILURE:
			return { museumsLists: false };
		default:
			return state;
	}
}

export function deleteMuseum(state = {}, action) {
	switch (action.type) {
		case manageConstants.DELETE_MUSEUM_LIST_REQUEST:
			return { deleteMuseums: false };
		case manageConstants.DELETE_MUSEUM_LIST_SUCCESS:
			return { deleteMuseums: true };
		case manageConstants.DELETE_MUSEUM_LIST_FAILURE:
			return { deleteMuseums: false };
		default:
			return state;
	}
}

export function createMuseumAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.CREATE_MUSEUM_ACCOUNT_REQUEST:
			return { createdMuseumsAccounts: false };
		case manageConstants.CREATE_MUSEUM_ACCOUNT_SUCCESS:
			return { createdMuseumsAccounts: true };
		case manageConstants.CREATE_MUSEUM_ACCOUNT_FAILURE:
			return { createdMuseumsAccounts: false };
		default:
			return state;
	}
}

export function resetAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.RESET_ACCOUNT_REQUEST:
			return { resetsAccounts: false };
		case manageConstants.RESET_ACCOUNT_SUCCESS:
			return { resetsAccounts: true };
		case manageConstants.RESET_ACCOUNT_FAILURE:
			return { resetsAccounts: false };
		default:
			return state;
	}
}

export function putLockAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_LOCK_ACCOUNT_REQUEST:
			return { putLocksAccounts: false };
		case manageConstants.PUT_LOCK_ACCOUNT_SUCCESS:
			return { putLocksAccounts: true };
		case manageConstants.PUT_LOCK_ACCOUNT_FAILURE:
			return { putLocksAccounts: false };
		default:
			return state;
	}
}

export function editMuseumAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.EDIT_MUSEUM_ACCOUNT_REQUEST:
			return { editedMuseumsAccounts: false };
		case manageConstants.EDIT_MUSEUM_ACCOUNT_SUCCESS:
			return { editedMuseumsAccounts: true };
		case manageConstants.EDIT_MUSEUM_ACCOUNT_FAILURE:
			return { editedMuseumsAccounts: false };
		default:
			return state;
	}
}

export function deleteMuseumAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.DELETE_MUSEUM_ACCOUNT_REQUEST:
			return { deleteMuseumsAccounts: false };
		case manageConstants.DELETE_MUSEUM_ACCOUNT_SUCCESS:
			return { deleteMuseumsAccounts: true };
		case manageConstants.DELETE_MUSEUM_ACCOUNT_FAILURE:
			return { deleteMuseumsAccounts: false };
		default:
			return state;
	}
}

export function getBannerImages(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_BANNER_IMAGES_REQUEST:
			return { bannerImages: false };
		case manageConstants.GET_BANNER_IMAGES_SUCCESS:
			return { bannerImages: action.data };
		case manageConstants.GET_BANNER_IMAGES_FAILURE:
			return { bannerImages: 'error' };
		default:
			return state;
	}
}

export function postBannerImages(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_BANNER_IMAGES_REQUEST:
			return { postBanners: false };
		case manageConstants.POST_BANNER_IMAGES_SUCCESS:
			return { postBanners: action.data };
		case manageConstants.POST_BANNER_IMAGES_FAILURE:
			return { postBanners: 'error' };
		default:
			return state;
	}
}

export function postResource(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_RESOURCE_REQUEST:
			return { postResourceData: false,type:action.Filetype };
		case manageConstants.POST_RESOURCE_SUCCESS:
			return { postResourceData: action.data,response:'sucess',type:action.Filetype };
		case manageConstants.POST_RESOURCE_FAILURE:
			return { postResourceData: false,type:null };
		default:
			return state;
	}
}
export function getMuseumDropdownDetails(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_DROPDOWN_REQUEST:
			return { museumDropdownDetails: false};
		case manageConstants.GET_MUSEUM_DROPDOWN_SUCCESS:
			return { museumDropdownDetails: action.data};
		case manageConstants.GET_MUSEUM_DROPDOWN_FAILURE:
			return { museumDropdownDetails: false};
		default:
			return state;
	}
}
export function getHolidaysList(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_HOLIDAYS_REQUEST:
			return { holidaysList: 'loading' };
		case manageConstants.GET_HOLIDAYS_SUCCESS:
			return { holidaysList: action.data };
		case manageConstants.GET_HOLIDAYS_FAILURE:
			return { holidaysList: false };
		default:
			return state;
	}
}

export function putHolidaysList(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_HOLIDAYS_REQUEST:
			return { putHolidays: 'loading'  };
		case manageConstants.PUT_HOLIDAYS_SUCCESS:
			return { putHolidays: action.data };
		case manageConstants.PUT_HOLIDAYS_FAILURE:
			return { putHolidays: false };
		default:
			return state;
	}
}

