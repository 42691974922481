import { userConstants } from '../constants';
import { userService } from '../services';
import { history } from '../helpers';
import { alertActions } from './';

export const userActions = {
	loginviasingpass,
	resendEmail,
	confirmemail,
	logout,
	getschools,
	getUserProfile,
	getNotification,
	putNotification,
	updateUserProfile,
	deleteUserProfile,
	registerviasingpass,
	accountMyInfo,
	switchMusuem,
	checkValidRole
};


function loginviasingpass(user,token) {

	return dispatch => {
		dispatch(request({ user }));
		userService.loginviasingpass(user,token).then(
			user => {
				dispatch(success(user));
				//dispatch(alertActions.success("You have successfully login ",true));
				let localUser = JSON.parse(localStorage.getItem('user'));
				let userType = localStorage.getItem('UserRoleType');
				if(localUser&&localUser.SessionInformation){
			     localStorage.setItem('SessionInformation',true);
				}
/* 			   if(userType!=='TE'&&user.FirstTimeLogin&&user.MyInfoUrl){
				window.location.href = user.MyInfoUrl
			   }
			   else{ */
				localUser&&localUser.Roles&&localUser.Roles.forEach((item, i) => {
					if (item.RoleName === 'Teacher') {
						history.push('');
						window.location.reload(true);
					} else if (item.RoleName === 'System Administrator') {

                            history.push('/SA');
                            window.location.reload(true);

                    } else if (item.RoleName === 'Museum Administrator') {

							history.push('/'+userType);
							window.location.reload(true);

					} else if (item.RoleName === 'Museum Coordinator') {

							history.push('/'+userType);
							window.location.reload(true);

					} else if (item.RoleName === 'Museum Finance') {

							history.push('/'+userType);
							window.location.reload(true);

					} else if (item.RoleName === 'Museum FrontDesk') {

							history.push('/'+userType);
							window.location.reload(true);

					} else {
						history.push('/');
						window.location.reload(true);
					}
				});
			//    }
			},
			error => {
				dispatch(failure(error.data&&error.data.Message));
				if(error.status===404){
					dispatch(alertActions.openRegister(true, 'singpass',token))
					// window.location.href = error.data.MyInfoUrl
				}
				else{
				dispatch(alertActions.error(error.data&&error.data.Message?error.data.Message:"Something went wrong",true,error.status,token));
				}

			}
		);
	};

	function request(user) {
		return { type: userConstants.LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error };
	}
}
function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem('user');
	localStorage.removeItem('UserRoleType');
}

function registerviasingpass(user,token) {
	return dispatch => {
		dispatch(request({ user }));
		userService.registerviasingpass(user,token).then(
			user => {
				dispatch(success());
				history.push('/');
				let SuccessMessage='';
					if(user)
					SuccessMessage = user.Message;
				dispatch(alertActions.success(SuccessMessage,true,'logout','Account Registration Submitted'));
				dispatch(alertActions.openRegister(false,''));

			},
			error => {
				const ErrorSumm = error.errors&&Object.keys(error.errors).reduce((total, record) => {
					return total?total +', ' + error.errors[record].join(', '):error.errors[record].join(', ')
				  }, '');
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:ErrorSumm?ErrorSumm:'Something went wrong',true));
			}
		);
	};

	function request(user) {
		return { type: userConstants.REGISTER_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.REGISTER_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.REGISTER_FAILURE, error };
	}
}

function accountMyInfo(data,token) {
	return dispatch => {
		dispatch(request({ data }));
		let userType = localStorage.getItem('UserRoleType');
		if(!userType){
			dispatch(alertActions.openRegister(true, 'singpass',token))
			history.push('/');
			}
		userService.accountMyInfo(data).then(
			userInfo => {
				dispatch(success(userInfo));
				if(userType&&userType!=='TE'){
					history.push({ pathname: 'myprofile', state: { updated:true } })
					window.location.reload(true);
				}
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: userConstants.MYINFO_REQUEST, data };
	}
	function success(data) {
		return { type: userConstants.MYINFO_SUCCESS, data };
	}
	function failure(error) {
		return { type: userConstants.MYINFO_FAILURE, error };
	}
}
function resendEmail(token) {
	return dispatch => {
		dispatch(request({token}));
		userService.resendEmail(token).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data&&data.Message,true,'logout'));
			},
			error => {
				dispatch(failure(error));
				dispatch(alertActions.error(error&&error.Message?error.Message:"Something went wrong",true,'logout'));
			}
		);
	};

	function request(data) {
		return { type: userConstants.RESEND_EMAIL_REQUEST, data };
	}
	function success(data) {
		return { type: userConstants.RESEND_EMAIL_SUCCESS, data };
	}
	function failure(data) {
		return { type: userConstants.RESEND_EMAIL_FAILURE, data };
	}
}


function confirmemail(info,singpassToken) {
	return dispatch => {
		dispatch(request({ info }));
		userService.confirmemail(info,singpassToken).then(
			info => {
				dispatch(success());
				// history.push('/');
				dispatch(alertActions.success(info.Message,true,'logout'));
				// window.location.reload(true);
			},
			error => {
				dispatch(failure(error.Message));
				// history.push('/');
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true,'logout'));
			}
		);
	};

	function request(info) {
		return { type: userConstants.CONFIRM_EMAIL_REQUEST, info };
	}
	function success(info) {
		return { type: userConstants.CONFIRM_EMAIL_SUCCESS, info };
	}
	function failure(info) {
		return { type: userConstants.CONFIRM_EMAIL_FAILURE, info };
	}
}



function getschools(data) {
	return dispatch => {
		dispatch(request({ data }));
		userService.getSchools(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
			}
		);
	};

	function request(data) {
		return { type: userConstants.GETSCHOOLS_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.GETSCHOOLS_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.GETSCHOOLS_FAILURE, data };
	}
}

function getUserProfile(type) {
	return dispatch => {
		dispatch(request({}));
		userService.getUserProfile().then(
			data => {
				dispatch(success(data));
				if(type==='setLocalStorage'){
				let localUser = JSON.parse(localStorage.getItem('user'));
				let changedUser={...localUser,
					FirstName:data.FirstName,
					Lastname:data.LastName,
				}
				localStorage.setItem('user', JSON.stringify(changedUser));
				}
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: userConstants.GET_PROFILE_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.GET_PROFILE_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.GET_PROFILE_FAILURE, data };
	}
}

function getNotification(data) {
	return dispatch => {
		dispatch(request({ data }));
		userService.getNotification(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
			}
		);
	};

	function request(data) {
		return { type: userConstants.GET_NOTIFICATION_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.GET_NOTIFICATION_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.GET_NOTIFICATION_FAILURE, data };
	}
}


function putNotification(req,type,redirect) {
	return dispatch => {
		dispatch(request({ req }));
		userService.putNotification(req,type).then(
			data => {
				dispatch(success(data));
				if(redirect){
				history.push(redirect)
		        window.location.reload(true);
				}else{
				dispatch(getNotification(req));
				}

			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong'));
				history.push(redirect)
		        window.location.reload(true);
			}
		);
	};

	function request(data) {
		return { type: userConstants.PUT_NOTIFICATION_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.PUT_NOTIFICATION_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.PUT_NOTIFICATION_FAILURE, data };
	}
}

function updateUserProfile(reqData) {
	return dispatch => {
		dispatch(request({ reqData }));
		userService.updateUserProfile(reqData).then(
			data => {
				dispatch(success(data));
				dispatch(getUserProfile('setLocalStorage'))
				dispatch(alertActions.success(data.Message?data.Message:"Profile updated successfully",true,'reload'));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: userConstants.UPDATE_PROFILE_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.UPDATE_PROFILE_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.UPDATE_PROFILE_FAILURE, data };
	}
}

function deleteUserProfile(data) {

	return dispatch => {
		dispatch(request({ data }));
		userService.deleteUserProfile(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message?data.Message:'Account has been disabled',true));
				logout();
				history.push('/');
				window.location.reload(true);
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: userConstants.DELETE_PROFILE_REQUEST, data };
	}

	function success(data) {
		return { type: userConstants.DELETE_PROFILE_SUCCESS, data };
	}

	function failure(data) {
		return { type: userConstants.DELETE_PROFILE_FAILURE, data };
	}
}

function switchMusuem(reqData) {
	return dispatch => {
		dispatch(request({ reqData }));
		userService.switchMusuem(reqData).then(
			data => {
				dispatch(success(data));
				let localUser = JSON.parse(localStorage.getItem('user'));
				let changedUser={...localUser,OrganizationId:reqData.ActiveOrganizationId}
			    if(changedUser.Organization)
				changedUser.MuseumType=changedUser.Organization.find(ele=>changedUser.OrganizationId===ele.Id).Type
				localStorage.setItem('user', JSON.stringify(changedUser));
				let userType = localStorage.getItem('UserRoleType');
				history.push('/'+userType);
				window.location.reload(true);
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};
	function request(data) {
		return { type: userConstants.SWITCH_MUSEUM_REQUEST, data };
	}
	function success(data) {
		return { type: userConstants.SWITCH_MUSEUM_SUCCESS, data };
	}
	function failure(data) {
		return { type: userConstants.SWITCH_MUSEUM_FAILURE, data };
	}
}
function checkValidRole(newRole,oldRole) {
	return dispatch => {
		userService.checkValidRole(newRole).then(
			response => {
				if(!response){
					localStorage.setItem('UserRoleType',oldRole);
				}
			},
			error => {
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

}