import { masterConstants } from '../constants';

export function getProgramFilter(state = {}, action) {
	switch (action.type) {
		case masterConstants.GET_MASTER_FILTER_REQUEST:
			return { filterprogramme: false };
		case masterConstants.GET_MASTER_FILTER_SUCCESS:
			return { filterprogramme: action.data };
		case masterConstants.GET_MASTER_FILTER_FAILURE:
			return { filterprogramme: false };
		default:
			return state;
	}
}
export function getAllProgram(state = {}, action) {
	switch (action.type) {
		case masterConstants.GET_MASTER_BOOKING_REQUEST:
			return { allprogramme: false };
		case masterConstants.GET_MASTER_BOOKING_SUCCESS:
			return { allprogramme: action.data };
		case masterConstants.GET_MASTER_BOOKING_FAILURE:
			return { allprogramme: false };
		default:
			return state;
	}
}
export function postSearchProgramme(state = {}, action) {
	switch (action.type) {
		case masterConstants.POST_SEARCH_CALENDAR_REQUEST:
			return { searchcalendardata: false };
		case masterConstants.POST_SEARCH_CALENDAR_SUCCESS:
			return { searchcalendardata: action.data };
		case masterConstants.POST_SEARCH_CALENDAR_FAILURE:
			return { searchcalendardata: {Result:'error',AdvanceSearch:action.data} };
		default:
			return state;
	}
}