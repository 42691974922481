import React from 'react';
import { Col, Container, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './ConfirmModal.scss';

function ConfirmModal(props) {

	return(
		<Modal className="confirm-modal" isOpen={props.isOpen} size="lg">
			<ModalHeader className={props.className}>{props.title}</ModalHeader>
				<ModalBody>
					<Container>
						<Row>
							<Col className={props.className}><p>{props.content}</p></Col>
							{props.children}				
						</Row>
					</Container>
				</ModalBody>
		</Modal>
	);
}

export default ConfirmModal;
