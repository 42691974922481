import { manageBookingConstants } from "../constants";
import { manageBookingService } from "../services";
import { alertActions } from "./";

export const manageBooking = {
  getBookedSlotDetails,
  saveBookedSlotDetails,
  getInvoice,
  postFeesCalculation,
  postRemainder,
  postPrivateFeesCalculation
};

function getBookedSlotDetails(data,clear) {
  return (dispatch) => {
    dispatch(request({ data }));
    if(!clear){
      manageBookingService.getBookedSlotDetails(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.Message));
		  dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
    }
    );
    }
  };
  function request(data) {
    return { type: manageBookingConstants.GET_BOOKED_SLOT_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.GET_BOOKED_SLOT_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.GET_BOOKED_SLOT_FAILURE, data };
  }
}

function saveBookedSlotDetails(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    manageBookingService.saveBookedSlotDetails(data).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success(data.Message, true));
      },
      (error) => {
        dispatch(failure(error.Message));
        dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
      }
    );
  };
  function request(data) {
    return { type: manageBookingConstants.SAVE_BOOKED_SLOT_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.SAVE_BOOKED_SLOT_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.SAVE_BOOKED_SLOT_FAILURE, data };
  }
}

function getInvoice(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    manageBookingService.getInvoice(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.Message));
		  dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
    }
    );
  };
  function request(data) {
    return { type: manageBookingConstants.GET_INVOICE_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.GET_INVOICE_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.GET_INVOICE_FAILURE, data };
  }
}

function postFeesCalculation(data,i) {
  return (dispatch) => {
    dispatch(request({ data }));
    if(!i){manageBookingService.postFeesCalculation(data).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success(data.Message, false));
      },
      (error) => {
        dispatch(failure(error.Message));
        dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', false));
      }
    );}
  };
  function request(data) {
    return { type: manageBookingConstants.POST_FEES_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.POST_FEES_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.POST_FEES_FAILURE, data };
  }
}
function postPrivateFeesCalculation(data,i) {
  return (dispatch) => {
    dispatch(request({ data }));
    if(!i){manageBookingService.postPrivateFeesCalculation(data).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success(data.Message, false));
      },
      (error) => {
        dispatch(failure(error.Message));
        dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', false));
      }
    );}
  };
  function request(data) {
    return { type: manageBookingConstants.POST_PRIVATE_FEES_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.POST_PRIVATE_FEES_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.POST_PRIVATE_FEES_FAILURE, data };
  }
}
function postRemainder(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    manageBookingService.postRemainder(data).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success(data.Message, false));
      },
      (error) => {
        dispatch(failure(error.Message));
        dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', false));
      }
    );
  };
  function request(data) {
    return { type: manageBookingConstants.POST_REMINDER_REQUEST, data };
  }
  function success(data) {
    return { type: manageBookingConstants.POST_REMINDER_SUCCESS, data };
  }
  function failure(data) {
    return { type: manageBookingConstants.POST_REMINDER_FAILURE, data };
  }
}