import { manageConstants } from '../constants';
import { manageService } from '../services';
import { alertActions } from './';

export const manageActions = {
	postDashboard,
	putTeacherRequests,
	getTeacherAccounts,
	putTeacherAccounts,
	getMuseumRequests,
	getMuseumAccounts,
	listOfMuseum,
	postMuseumList,
	putMuseumList,
	deleteMuseum,
	createMuseumAccounts,
	resetAccounts,
	putLockAccounts,
	editMuseumAccounts,
	deleteMuseumAccounts,
	deleteTeacherAccounts,
	getRoles,
	postRoles,
	getBannerImages,
	postBannerImages,
	postResource,
	getMuseumDropdownDetails,
	getHolidaysList,
	putHolidaysList
};

function postDashboard(req, checkedData,i) {
	return dispatch => {
		if(!i){
		dispatch(request(req, checkedData));
		manageService.postDashboard(req).then(
			data => {
				dispatch(success(data, checkedData,req));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);}
		else{
			dispatch(request(req, checkedData));
		}
	};

	function request(data,checkedData) {
		return { type: manageConstants.POST_DASHBOARD_REQUEST, data, checkedData: checkedData };
	}

	function success(data, checkedData) {
		return { type:  manageConstants.POST_DASHBOARD_SUCCESS, data, checkedData: checkedData ,request:req };
	}

	function failure(data) {
		return { type: manageConstants.POST_DASHBOARD_FAILURE, data };
	}
}


function getTeacherAccounts(data) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.getTeacherAccounts(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_TEACHER_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_TEACHER_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_TEACHER_ACCOUNT_FAILURE, data };
	}
}


function putTeacherRequests(data,filterSection) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.putTeacherRequests(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				dispatch(getTeacherAccounts(filterSection))
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_TEACHER_APPROVAL_REQUEST, data };
	}
	function success(data) {
		return { type: manageConstants.PUT_TEACHER_APPROVAL_SUCCESS, data };
	}
	function failure(data) {
		return { type: manageConstants.PUT_TEACHER_APPROVAL_REQUEST, data };
	}
}
function putTeacherAccounts(data,pageValue) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.putTeacherAccounts(data).then(
			data => {
				dispatch(getTeacherAccounts(pageValue))
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_TEACHER_ACCOUNT_REQUEST, data };
	}
	function success(data) {
		return { type: manageConstants.PUT_TEACHER_ACCOUNT_SUCCESS, data };
	}
	function failure(data) {
		return { type: manageConstants.PUT_TEACHER_ACCOUNT_REQUEST, data };
	}
}
function getMuseumRequests(data) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.getMuseums(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_ROLE_MUSEUM_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_ROLE_MUSEUM_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_ROLE_MUSEUM_FAILURE, data };
	}
}

function getMuseumAccounts(data) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.getMuseumAccounts(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_ACCOUNT_FAILURE, data };
	}
}
function listOfMuseum(data) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.listOfMuseum(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_LIST_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_LIST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_LIST_FAILURE, data };
	}
}

function postMuseumList(data){
    return dispatch => {
		dispatch(request({ data }));
		manageService.postMuseumList(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.POST_MUSEUM_LIST_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.POST_MUSEUM_LIST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.POST_MUSEUM_LIST_FAILURE, data };
	}
}

function putMuseumList(data){
    return dispatch => {
		dispatch(request({ data }));
		manageService.putMuseumList(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_MUSEUM_LIST_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_MUSEUM_LIST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_MUSEUM_LIST_FAILURE, data };
	}
}

function deleteMuseum(data) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.deleteMuseumListItem(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message, true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.DELETE_MUSEUM_LIST_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.DELETE_MUSEUM_LIST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.DELETE_MUSEUM_LIST_FAILURE, data };
	}
}

function createMuseumAccounts(data,filterSection) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.createMuseumAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				dispatch(getMuseumAccounts(filterSection))
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.CREATE_MUSEUM_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.CREATE_MUSEUM_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.CREATE_MUSEUM_ACCOUNT_FAILURE, data };
	}
}

function resetAccounts(data,filterSection,type) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.resetAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				if(type==='Teachers'){
					dispatch(getTeacherAccounts(filterSection))
				}else{
					dispatch(getMuseumAccounts(filterSection))
				}
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.RESET_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.RESET_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.RESET_ACCOUNT_FAILURE, data };
	}
}


function putLockAccounts(data,filterSection,type) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.putLockAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				if(type==='Teachers'){
					dispatch(getTeacherAccounts(filterSection))
				}else{
					dispatch(getMuseumAccounts(filterSection))
				}
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_LOCK_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_LOCK_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_LOCK_ACCOUNT_FAILURE, data };
	}
}


function editMuseumAccounts(data,filterSection) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.editMuseumAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				dispatch(getMuseumAccounts(filterSection))
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.EDIT_MUSEUM_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.EDIT_MUSEUM_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.EDIT_MUSEUM_ACCOUNT_FAILURE, data };
	}
}

function deleteMuseumAccounts(data,filterSection) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.deleteMuseumAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				dispatch(getMuseumAccounts(filterSection))
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.DELETE_MUSEUM_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.DELETE_MUSEUM_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.DELETE_MUSEUM_ACCOUNT_FAILURE, data };
	}
}



function deleteTeacherAccounts(data,filterSection) {
	return dispatch => {
		dispatch(request({ data }));
		manageService.deleteTeacherAccounts(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
				dispatch(getTeacherAccounts(filterSection))
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.DELETE_TEACHER_ACCOUNT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.DELETE_TEACHER_ACCOUNT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.DELETE_TEACHER_ACCOUNT_FAILURE, data };
	}
}

function getRoles(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.getRoles(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_ROLES_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_ROLES_REQUEST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_ROLES_REQUEST_FAILURE, data };
	}
}

function postRoles(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.postRoles(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message?data.Message:"User's role updated successfully",true));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.POST_ROLES_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.POST_ROLES_REQUEST_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.POST_ROLES_REQUEST_FAILURE, data };
	}
}

function getBannerImages(data, home) {
	return dispatch => {
		dispatch(request(data));
		manageService.getBannerImages(home).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_BANNER_IMAGES_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_BANNER_IMAGES_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_BANNER_IMAGES_FAILURE, data };
	}
}
function postBannerImages(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.postBannerImages(data).then(
			data => {

				dispatch(success(data));
				dispatch(getBannerImages());

			},
			error => {
				dispatch(failure(data));
				dispatch(getBannerImages());
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));



			}
		);
	};

	function request(data) {
		return { type: manageConstants.POST_BANNER_IMAGES_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.POST_BANNER_IMAGES_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.POST_BANNER_IMAGES_FAILURE, data };
	}
}
function postResource(data,Filetype) {
	return dispatch => {
		dispatch(request(data,Filetype));
		manageService.postResource(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.POST_RESOURCE_REQUEST, data,Filetype };
	}

	function success(data) {
		return { type: manageConstants.POST_RESOURCE_SUCCESS, data ,Filetype};
	}

	function failure(data) {
		return { type: manageConstants.POST_RESOURCE_FAILURE, data ,Filetype};
	}
}
function getMuseumDropdownDetails(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.getMuseumDropdownDetails(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_DROPDOWN_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_DROPDOWN_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_DROPDOWN_FAILURE, data };
	}
}
function getHolidaysList(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.getHolidaysList(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_HOLIDAYS_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_HOLIDAYS_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_HOLIDAYS_FAILURE, data };
	}
}
function putHolidaysList(data) {
	return dispatch => {
		dispatch(request(data));
		manageService.putHolidaysList(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(data));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_HOLIDAYS_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_HOLIDAYS_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_HOLIDAYS_FAILURE, data };
	}
}