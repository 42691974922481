export const masterConstants = {
    GET_MASTER_FILTER_REQUEST: 'GET_MASTER_FILTER_REQUEST',
    GET_MASTER_FILTER_SUCCESS: 'GET_MASTER_FILTER_SUCCESS',
    GET_MASTER_FILTER_FAILURE: 'GET_MASTER_FILTER_FAILURE',

    GET_MASTER_BOOKING_REQUEST: 'GET_MASTER_BOOKING_REQUEST',
    GET_MASTER_BOOKING_SUCCESS: 'GET_MASTER_BOOKING_SUCCESS',
    GET_MASTER_BOOKING_FAILURE: 'GET_MASTER_BOOKING_FAILURE',

    POST_SEARCH_CALENDAR_REQUEST: 'POST_SEARCH_CALENDAR_REQUEST',
    POST_SEARCH_CALENDAR_SUCCESS: 'POST_SEARCH_CALENDAR_SUCCESS',
    POST_SEARCH_CALENDAR_FAILURE: 'POST_SEARCH_CALENDAR_FAILURE',

};