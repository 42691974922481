import  { museumStaffDashboardService } from '../services'
import { museumStaffDashboardConstants } from '../constants'
import { alertActions } from './';


export const museumStaffDashboardActions = {
	getPreviewDashboard
}
function getPreviewDashboard(data) {
	return dispatch => {
		dispatch(request({ data }));
		museumStaffDashboardService.getPreviewDashboard(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
    };
    
	function request(data) {
		return { type: museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_REQUEST, data };
	}

	function success(data) {
		return { type: museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_FAILURE, data };
	}
}