import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { userActions,SingPassAuth } from '../actions/index';
import queryString from 'query-string';
import  { loadUserFromStorage } from '../services/userService'

function callback(props) {
  const history = useHistory()
  useEffect(() => {

    let user = loadUserFromStorage()
    user.then(data => {
      if (data ) {
        props.storeUser(data)
        const queryParms = queryString.parse(props.location.search);
        if(queryParms&&queryParms.code&&queryParms.state){
         props.accountMyInfo({code:queryParms.code,state:queryParms.state},{Authorization: 'Bearer ' + data.id_token})
        }
     history.push(`/`)
      }
    })
   

        
      

  }, [history])

  return (
    <div>
    Redirecting...
  </div>
  )
}
function mapState(state) {
  return {
   loggedIn : state.authentication,
    status  : state.verifyEmail.status,
     value  : state.verifyEmail.value,
	
	};
}

const actionCreators = {
  accountMyInfo: userActions.accountMyInfo,
  loginviasingpass: userActions.loginviasingpass,
  storeUser:SingPassAuth.storeUser,
	confirmemail: userActions.confirmemail,




};
export default connect(mapState, actionCreators)(callback);

