import { baseUrl } from "../helpers/env.config";
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'
let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});

export const globalSearchService = {
    getGlobalSearch,
    postFeedback
}

function getGlobalSearch(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
    let queryString = 'keyword='+data.keyword+'&pageSize='+  data.pageSize+'&pageNumber='+ data.pageNumber+'&orderBy='+ data.orderBy;
  return fetch(baseUrl + '/search/?' + queryString,requestOptions ).then(handleResponse);
}

function postFeedback(data){
	let jsonData = {
    FeedbackType: data.FeedbackType,
    MuseumId: data.MuseumId?data.MuseumId:"",
    Rating: data.Rating,
    Comment: data.Comment,
    Email: data.Email,
    BookingId: data.BookingId,
    BookingNumber: data.BookingNumber,
    Id: data.Id
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/museum/feedback/', requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        	var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
      }
      if(response.status === 500){
        let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
        window.location.reload(true);

      }
      return Promise.reject(data);
    }
    return data;
  });
}
