import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './NotifyModal.scss';
import { alertActions, userActions } from '../../actions';
import { connect } from 'react-redux';
import { signoutRedirect } from '../../services/userService'
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from "dompurify";
//images
import RedTick from '../../assets/img/icons/red-tick.svg'
//images
function NotifyModal(props) {
    const history = useHistory();
    let header = "Request Failed";
    if (props.type === "alert-success")
        header = "Request Success";
    const closeModal = () => {
        if (props.code === 401 || props.code === 'logout') {
            localStorage.clear()
            signoutRedirect()
        }
        if (props.code === 406) {
            props.resendEmail(props.token)

        }
        if (props.code === 'gotohome') {
            history.push("/");
        }
        if (props.code === 'reload') {
            window.location.reload(true);
        }
        props.parentClose();
    }
    const sanitizedFullDescription = (data) => {
        const sanitized = DOMPurify.sanitize(data);
        return sanitized
    }
    return (
        <>{props.isOpen &&
            <Modal className="notify-modal" isOpen={props.isOpen} size="lg">
                <ModalHeader>
                    {props.code === 406 ? 'Email Verification Required' : <>
                        {header === "Request Success" && <span><img src={RedTick} alt="success" /></span>}
                        {props.title ? props.title : header}
                    </>}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col>{ReactHtmlParser(sanitizedFullDescription(props.message))}</Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className={props.code === 406 ? "button-style9 pull-right cursor-pointer" : "button-style6 pull-right cursor-pointer"} onClick={closeModal}>{props.code === 406 ? 'Resend Verification Link' : 'Done'}</Button>
                </ModalFooter>
            </Modal>
        }</>
    );
}

function mapState(state) {
    return {
        user: state.singPassUser.user,
    };
}

const actionCreators = {
    openRegister: alertActions.openRegister,
    resendEmail: userActions.resendEmail,
};

export default connect(mapState, actionCreators)(NotifyModal);
