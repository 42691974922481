import { reportsSAConstants } from '../constants';
import { reportsSAService } from '../services';
import { alertActions } from './';

export const reportsSAActions = {
	getReportsSA,
	postReportsSA,
	putResourceTracker
};

function getReportsSA(data) {
	return dispatch => {
		dispatch(request({ data }));
		reportsSAService.getReportsSA(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
    };
    
	function request(data) {
		return { type: reportsSAConstants.GET_MASTER_REPORTSSA_REQUEST, data };
	}

	function success(data) {
		return { type: reportsSAConstants.GET_MASTER_REPORTSSA_SUCCESS, data };
	}

	function failure(data) {
		return { type: reportsSAConstants.GET_MASTER_REPORTSSA_FAILURE, data };
	}
}
function postReportsSA(data) {
	return dispatch => {
		dispatch(request({ data }));
		reportsSAService.postReportsSA(data).then(
			data => {
				dispatch(success(data));
				const debugBase64 = (base64URL) => {
					var win = window.open('about:blank', '_blank');

					if(win)
					win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" sandbox="allow-downloads"  allowfullscreen></iframe>');
				  }
				debugBase64(data[0].FileContent);
			},
			error => {
				dispatch(failure(error.Message));
			}
		);
    };
    
	function request(data) {
		return { type: reportsSAConstants.POST_MASTER_REPORTSSA_REQUEST, data };
	}

	function success(data) {
		return { type: reportsSAConstants.POST_MASTER_REPORTSSA_SUCCESS, data };
	}

	function failure(data) {
		return { type: reportsSAConstants.POST_MASTER_REPORTSSA_FAILURE, data };
	}
}
function putResourceTracker(data) {
	return dispatch => {
		dispatch(request(data));
		reportsSAService.putResourceTracker(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
			}
		);
    };
    
	function request(data) {
		return { type: reportsSAConstants.PUT_TRACKER_RESOURCE_REQUEST, data };
	}

	function success(data) {
		return { type: reportsSAConstants.PUT_TRACKER_RESOURCE_SUCCESS, data };
	}

	function failure(data) {
		return { type: reportsSAConstants.PUT_TRACKER_RESOURCE_FAILURE, data };
	}
}