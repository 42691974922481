import { masterConstants } from '../constants';
import { masterService } from '../services';
import { alertActions } from './';

export const masterActions = {
	getFilterRequest,
	getAllProgram,
	postSearchProgramme
};
function getFilterRequest(data) {
	return dispatch => {
		dispatch(request({ data }));
		masterService.getFilterRequest(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: masterConstants.GET_MASTER_FILTER_REQUEST, data };
	}

	function success(data) {
		return { type: masterConstants.GET_MASTER_FILTER_SUCCESS, data };
	}

	function failure(data) {
		return { type: masterConstants.GET_MASTER_FILTER_FAILURE, data };
	}
}

function getAllProgram(data) {
	return dispatch => {
		dispatch(request({ data }));
		masterService.getAllProgram(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_FAILURE, data };
	}
}
function postSearchProgramme(data,startDate,endDate) {
	return dispatch => {
		dispatch(request({ data }));
		masterService.postSearchProgramme(data,startDate,endDate).then(
			res => {
				res.AdvanceSearch=data.AdvanceSearch
				dispatch(success(res));
			},
			error => {
				dispatch(failure(data.AdvanceSearch));
			}
		);
	};

	function request(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_REQUEST, data };
	}

	function success(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_SUCCESS, data };
	}

	function failure(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_FAILURE, data };
	}
}