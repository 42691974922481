// import { authHeader } from '../helpers';
import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const museumSettingsService = {
	getMuseumLeadTime,
	putMuseumLeadTime,
    getMuseumNotifications,
	getmuseuMcapacity,
	getMuseumBlockedSlot,
	postMuseumBlockedSlot,
	putMuseumBlockedSlot,
	deleteMuseumBlockedSlot,
	putMuseumNotifications,
	putMuseumCapacity,
	getBlockedSlotDropDown
}
function getMuseumLeadTime(Id){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
    return fetch(baseUrl + '/museum/leadtime', requestOptions).then(handleResponse);
}

function putMuseumLeadTime(data) {

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify({
				museumLeadTimeSettings: {
				  BookingConfigurations: {
					SelfGuided: data.SelfGuidedDaysClose,
					Guided: data.GuidedDaysClose,
					Workshops: data.WorkshopDaysClose
				  },
				  ReminderConfiguration: {
					SelfGuided: data.SelfGuidedDaysFar,
					Guided: data.GuidedDaysFar,
					Workshops: data.WorkshopDaysFar
				  }
				}
		}),
	};
	return fetch(baseUrl + '/museum/leadtime', requestOptions).then(handleResponse);
}

function getMuseumNotifications(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
    return fetch(baseUrl + '/museum/massbookingandnotificationsetting', requestOptions).then(handleResponse);
}

function putMuseumNotifications(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify({
			Settings: {
				MassBookingLimit: data.MassBookingLimit,
				MuseumCapacity: data.MuseumCapacity,
				Notification: {
				  NotifyUpdateBooking: data.NotifyUpdateBooking,
				  NotifyUpdateAccount: data.NotifyUpdateAccount,
				  NotifyCancelBooking: data.NotifyCancelBooking,
				  NotifyMassBooking: data.NotifyMassBooking
				}
			  }
		}),
	};
	return fetch(baseUrl + '/museum/massbookingandnotificationsetting', requestOptions).then(handleResponse);
}

function getmuseuMcapacity(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
    return fetch(baseUrl + '/museum/capacity', requestOptions).then(handleResponse);
}

function getMuseumBlockedSlot(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
    return fetch(baseUrl + '/museum/blockslots', requestOptions).then(handleResponse);
}
function getBlockedSlotDropDown(){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
    return fetch(baseUrl + '/Master/blockslotdropdowndetails', requestOptions).then(handleResponse);
}
function postMuseumBlockedSlot(data) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/museum/blockslot', requestOptions).then(handleResponse);
}

function putMuseumBlockedSlot(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/museum/blockslot', requestOptions).then(handleResponse);
}

function deleteMuseumBlockedSlot(data) {
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/museum/blockslot', requestOptions).then(handleResponse);
}

function putMuseumCapacity(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify({
			  MuseumCapacity: data.museumCapacity
		}),
	};
	return fetch(baseUrl + '/museum/capacity', requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }

			//const error = (data && data.message) || response.statusText;
			return Promise.reject(data);
		}

		return data;
	});
}