import { teacherbookingsConstants } from '../constants';
import { teacherBookingService } from '../services';
import { alertActions } from './';

export const teacherBookingAction = {
	getBookingDetails,
	PostMyBookings,
	PutMyBooking,
	DeleteMyBooking,
	GetMyBooking,
	getFeedbackQuestions,
	postFeedbackQuestions
}
function getBookingDetails(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.getBookedDetails(data).then(
			data => {
				 dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};
	
	function request(data) {
		return { type: teacherbookingsConstants.GET_BOOKED_TEACHER_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.GET_BOOKED_TEACHER_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.GET_BOOKED_TEACHER_FAILURE, data };
	}
}
function GetMyBooking(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.GetMyBooking(data).then(
			data => {
				 dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));

			}
		);
	};
	
	function request(data) {
		return { type: teacherbookingsConstants.GET_MY_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.GET_MY_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.GET_MY_BOOKING_FAILURE, data };
	}
}

function PostMyBookings(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.PostMyBookings(data).then(
			data => {
				 dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};
	
	function request(data) {
		return { type: teacherbookingsConstants.POST_MY_BOOKINGS_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.POST_MY_BOOKINGS_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.POST_MY_BOOKINGS_FAILURE, data };
	}
}

function PutMyBooking(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.PutMyBooking(data).then(
			data => {
				 dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};
	
	function request(data) {
		return { type: teacherbookingsConstants.PUT_MY_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.PUT_MY_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.PUT_MY_BOOKING_FAILURE, data };
	}
}

function DeleteMyBooking(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.DeleteMyBooking(data).then(
			data => {
				 dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};
	
	function request(data) {
		return { type: teacherbookingsConstants.DELETE_MY_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.DELETE_MY_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.DELETE_MY_BOOKING_FAILURE, data };
	}
}
function getFeedbackQuestions(data,i) {
	return dispatch => {
		dispatch(request({ data }));
		if(!i){
			teacherBookingService.getFeedbackQuestions(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);  
			};
		}
    
	function request(data) {
		return { type: teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.GET_FEEDBACK_QUESTIONS_FAILURE, data };
	}
}
function postFeedbackQuestions(data) {
	return dispatch => {
		dispatch(request({ data }));
		teacherBookingService.postFeedbackQuestions(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success("Thank you for your Feedback !",true));

			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
    };
    
	function request(data) {
		return { type: teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_REQUEST, data };
	}

	function success(data) {
		return { type: teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_SUCCESS, data };
	}

	function failure(data) {
		return { type: teacherbookingsConstants.POST_FEEDBACK_QUESTIONS_FAILURE, data };
	}
}