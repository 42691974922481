import { authHeader } from '../helpers';
import { baseUrl } from '../helpers/env.config';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token=''
JWT.then(function(data) {
	token= data
});

export const userService = {
	resendEmail,
	confirmemail,
	getSchools,
	getUserProfile,
	getNotification,
	putNotification,
	updateUserProfile,
	deleteUserProfile,
	loginviasingpass,
	registerviasingpass,
	accountMyInfo,
	switchMusuem,
	checkValidRole
};

function accountMyInfo(data) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/account/myinfo?code='+data.code+'&state='+data.state,requestOptions).then(handleResponse);
}

function registerviasingpass(user,jwToken) {


	const requestOptions = {
		method: 'POST',
		headers: new Headers({ accept: 'application/json', 'content-type': 'application/json',...jwToken }),
		body: JSON.stringify({
			Email: user.Email,
			FirstName: user.FirstName,
			LastName: user.LastName,
			SchoolType: user.SchoolType,
			SchoolId: user.SchoolId.value,
			Others: user.Others,
			OfficeContactNumber: user.OfficeContactNumber,
			HandPhoneNumber: user.HandPhoneNumber,
			Department: user.Department,
			TermsAndConditionAgreed: user.TermsAndConditionAgreed,
		}),
	};

	return fetch(baseUrl + `/account/registerteacheraccount`, requestOptions).then(handleResponseSingpassregister);
}
function loginviasingpass(user,tokenOidc) {

	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...tokenOidc},
	};

	return fetch(baseUrl + `/account/loginviasingpass`, requestOptions)
		.then(response => handleLoginResponse(response,tokenOidc))
		.then(user => {
			let arrayStructure = ["TE","MF","MFD","MC","MA","SA"];
			let sortedArray = [];
			if(user.Organization&&user.Roles&&user.Roles.findIndex((ele)=>ele.RoleId==='TE')===-1){
			user.MuseumType=user.Organization.find(ele=>user.OrganizationId===ele.Id).Type
			}
			localStorage.setItem('user', JSON.stringify(user));
			if(user && user.Roles){
				function  checkIsexist(data){
					user.Roles.forEach(val=>{
						if(data===val.RoleId){
							sortedArray.push(val)
						}
					})
				}
				arrayStructure.forEach(val=>{
					checkIsexist(val);
				})
				let userType = localStorage.getItem('UserRoleType')
				if(sortedArray&&(!userType||(userType&&sortedArray.findIndex(e => e.RoleId === userType)===-1))){
			       localStorage.setItem('UserRoleType',sortedArray[0].RoleId);
				}
			}



			return user;
		});
}
function resendEmail(tokenOidc) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...tokenOidc },
	};

	return fetch(baseUrl + `/account/sendemailverificationlink`, requestOptions).then(handleResponse);
}

function confirmemail(info,singpassToken) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token,...singpassToken },
		body: JSON.stringify({
			userid: info.userid,
			token: info.token,
		}),
	};

	return fetch(baseUrl + `/account/confirmemail`, requestOptions).then(handleResponse);
}



function getSchools(data) {
	let requestOptions
	if(data&&data.type==='register'){
	 requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...data.token }
	}
}
	else{
		requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json',...token }
		}
	}
	return fetch(baseUrl + '/master/schools',requestOptions).then(handleResponse);
}

function getUserProfile() {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/profile/user',requestOptions).then(handleResponse);
}

function getNotification(roleId) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/notification?roleId='+roleId,requestOptions).then(handleResponse);
}

function putNotification(roleId,type) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token }
	};
	return fetch(baseUrl + '/notification?roleId='+roleId+'&type='+type, requestOptions).then(handleResponse);
}

function updateUserProfile(info) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(info),
	};
	return fetch(baseUrl + '/profile/user', requestOptions).then(handleResponse);
}

function deleteUserProfile(userID) {
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json',...token }
	};
	return fetch(baseUrl + '/profile/user', requestOptions).then(handleResponse);
}
function switchMusuem(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	}
	return fetch(baseUrl + '/Profile/switchorganization',requestOptions).then(handleResponse);
}
function handleLoginResponse(response,token) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		const status=response.status
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				//logout();
				//location.reload(true);
			}
			if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }
			//const error = (data && data.message) || response.statusText;
			return Promise.reject({data,status,token});
		}

		return data;
	});
}
function checkValidRole(data) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/account/iscorrectrole?role='+data,requestOptions).then(handleResponse);
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }

			//const error = (data && data.message) || response.statusText;
			return Promise.reject(data);
		}

		return data;
	});
}
function handleResponseSingpassregister(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }

			return Promise.reject(data);
		}

		return data;
	});
}

