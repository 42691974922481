import { tickerConstants } from '../constants'

export function postTicker(state = {}, action) {
	switch (action.type) {
		case tickerConstants.POST_TICKER_BLOCK_SLOT_REQUEST:
			return { getData: 'loading' };
		case tickerConstants.POST_TICKER_BLOCK_SLOT_SUCCESS:
			return { getData: action.data };
		case tickerConstants.POST_TICKER_BLOCK_SLOT_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function putTicker(state = {}, action) {
	switch (action.type) {
		case tickerConstants.PUT_TICKER_BLOCK_SLOT_REQUEST:
			return { getData:  'loading' };
		case tickerConstants.PUT_TICKER_BLOCK_SLOT_SUCCESS:
			return { getData: action.data };
		case tickerConstants.PUT_TICKER_BLOCK_SLOT_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function getTickerList(state = {}, action){
	switch(action.type){
		case tickerConstants.GET_MASTER_TICKERLIST_REQUEST:
			return{getData: false};
		case tickerConstants.GET_MASTER_TICKERLIST_SUCCESS:
			return{getData: action.data};
		case tickerConstants.GET_MASTER_TICKERLIST_FAILURE:
			return{getData: false};
		default:
			return state;
	}
}

export function deleteTicker(state = {}, action){
	switch(action.type){
		case tickerConstants.DELETE_TICKER_BLOCK_SLOT_REQUEST:
			return{getData:  'loading'};
		case tickerConstants.DELETE_TICKER_BLOCK_SLOT_SUCCESS:
			return{getData: action.data};
		case tickerConstants.DELETE_TICKER_BLOCK_SLOT_FAILURE:
			return{getData: false};
		default:
			return state;
	}
}