import React, { Component } from 'react';
import {
	AppHeader,
} from '@coreui/react';
import { Button, Container } from 'reactstrap';
//Images
import NhbLogoColour from '../../assets/img/Error404Images/nhb_logo_colour.svg'
import AcmLogoColour from '../../assets/img/Error404Images/acm_logo_colour.svg'
import BbbLogoColour from '../../assets/img/Error404Images/bbb_logo_colour.svg'
import CcmLogoColour from '../../assets/img/Error404Images/ccm_logo_colour.svg'
import CmsgLogoColour from '../../assets/img/Error404Images/cmsg_logo_colour.svg'
import IhcLogoColour from '../../assets/img/Error404Images/ihc_logo_colour.svg'
import MhcLogoColour from '../../assets/img/Error404Images/mhc_logo_colour.svg'
import NmsLogoColour from '../../assets/img/Error404Images/nms_logo_colour.svg'
import TpmLogoColour from '../../assets/img/Error404Images/tpm_logo_colour.svg'
import RootsLogoColour from '../../assets/img/Error404Images/roots_logo_colour.svg'
import RbcLogoColour from '../../assets/img/Error404Images/rbc_logo_colour.svg'
import SysLogoColour from '../../assets/img/Error404Images/sys_logo_colour.svg'


//Images
// const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));

export const errorBody = () => {
	return (
		<Container className='text-center'>
			<div className="main404box">
				<p className="nhbtitle m-0 p-0">NATIONAL HERITAGE BOARD</p>
				<h1>Oops!</h1>
				<p className="desc my-4">We can't seem to find the page you're looking for.</p>
				<p><a href="/" className="mainformbtn mainformbtnstyle">Back to BOOKMUSEUMS@SG</a></p>
				<p className='LinksHeader'>Here are some helpful links:</p>
				<div className="main404boxlogobox">
					<a href="https://www.nhb.gov.sg/" rel="nofollow"><img src={NhbLogoColour} alt="National Heritage Board" className="logodesktopnhb" /></a>
					<a href="https://www.nhb.gov.sg/acm/" rel="nofollow"><img src={AcmLogoColour} alt="Asian Civilisations Museum" /></a>
					<a href="https://www.nhb.gov.sg/brasbasahbugis/" rel="nofollow"><img src={BbbLogoColour} alt="Bras Basah.Bugis" /></a>
					<a href="https://www.nhb.gov.sg/changichapelmuseum" rel="nofollow"><img src={CcmLogoColour} alt="Changi Chapel and Museum" /></a>
					<a href="https://www.nhb.gov.sg/childrensmuseum" rel="nofollow"><img src={CmsgLogoColour} alt="Children's Museum Singapore" /></a>
					<a href="https://www.indianheritage.gov.sg/en" rel="nofollow"><img src={IhcLogoColour} alt="Indian Heritage Centre" /></a>
					<a href="https://www.malayheritage.gov.sg/en" rel="nofollow"><img src={MhcLogoColour} alt="Malay Heritage Centre" /></a>
					<a href="https://www.nhb.gov.sg/nationalmuseum/" rel="nofollow"><img src={NmsLogoColour} alt="National Museum of Singapore" /></a>
					<a href="https://www.nhb.gov.sg/peranakanmuseum/" rel="nofollow"><img src={TpmLogoColour} alt="Peranakan Museum" className="logoscaledown" /></a>
					<a href="https://www.roots.gov.sg/" rel="nofollow"><img src={RootsLogoColour} alt="Roots" className="logoscaledownx2" /></a>
					<a href="https://www.nhb.gov.sg/bukitchandu" rel="nofollow"><img src={RbcLogoColour} alt="Reflections at Bukit Chandu" className="logoscaledown" /></a>
					<a href="https://www.sysnmh.org.sg/en" rel="nofollow"><img src={SysLogoColour} alt="Sun Yat Sen Nanyang Memorial Hall" /></a>
				</div>
			</div>
		</Container>
	)
}

class ErrorPage404 extends Component {
	loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;
	signOut(e) {
		e.preventDefault();
		this.props.history.push('/');
	}
	changeUserRole = () => {

		if (localStorage.getItem('UserRoleType')) {
			if (localStorage.getItem('UserRoleType') === 'TE') {
				this.props.history.push('/');
			}
			else {
				this.props.history.push('/' + localStorage.getItem('UserRoleType'));
			}
		}
		else {
			this.props.history.push('/');
		}
		window.location.reload(true);
	}
	render() {
		return (
			<div className="app">
				<AppHeader fixed>
					<Header onLogout={e => this.signOut(e)} />
				</AppHeader>
				<div className="app-body">

					{errorBody()}
				</div>
				<div className="app-footer1">
					<Footer />
				</div>
			</div>
		);
	}
}

export default ErrorPage404;
