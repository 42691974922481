import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../services/userService'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { userActions,SingPassAuth } from '../actions/index';

function SigninOidc(props) {
  const history = useHistory()
  useEffect(() => {
    async function signinAsync() {
      let SignInfo=await signinRedirectCallback()
      if(SignInfo){
        let verifyUser = JSON.parse(localStorage.getItem('verifyAcc'));

        if(verifyUser){
          let tempvar=verifyUser
          localStorage.removeItem('verifyAcc');
          history.push(`/confirmemail?userid=${tempvar.userid}&token=${tempvar.token}`)
        }
        else{
          props.loginviasingpass({},{Authorization: 'Bearer ' + SignInfo.id_token})
          history.push(`/`)
    }
        props.storeUser(SignInfo)
      }

    }
    signinAsync()
    // props.login({})
  }, [history])

  return (
    <div>
    Redirecting...
  </div>
  )
}
function mapState(state) {
  return {
   loggedIn : state.authentication,
    status  : state.verifyEmail.status,
     value  : state.verifyEmail.value,

	};
}

const actionCreators = {
  loginviasingpass: userActions.loginviasingpass,
  loginviasingpass: userActions.loginviasingpass,
  storeUser:SingPassAuth.storeUser,
	confirmemail: userActions.confirmemail,




};
export default connect(mapState, actionCreators)(SigninOidc);

