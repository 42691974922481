// import { authHeader } from '../helpers';
import { baseUrl } from "../helpers/env.config";
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'
let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const manageService = {
  postDashboard,
  putTeacherRequests,
  getTeacherAccounts,
  putTeacherAccounts,
  getMuseums,
  getMuseumAccounts,
  listOfMuseum,
  postMuseumList,
  putMuseumList,
  deleteMuseumListItem,
  createMuseumAccounts,
  resetAccounts,
  putLockAccounts,
  editMuseumAccounts,
  deleteMuseumAccounts,
  deleteTeacherAccounts,
  getRoles,
  postRoles,
  getBannerImages,
  postBannerImages,
  postResource,
  getMuseumDropdownDetails,
  getHolidaysList,
  putHolidaysList,
};


function getTeacherAccounts(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/teacheraccountlist", requestOptions).then(
    handleResponse
  );
}

function postDashboard(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      ManagedBy: data.ManagedBy,
      TourDate: data.TourDate,
      FilteredSlots: data.FilteredSlots,
      AdvanceSearch: data.AdvanceSearch,
      Pagination: data.Pagination
    }),
  };
  return fetch(baseUrl + "/managebooking/dashboard", requestOptions).then(
    handleResponse
  );
}

/** NH */
function getRoles(roleID) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  return fetch(baseUrl + "/account/ManageRoles?roleID=" + roleID,requestOptions).then(
    handleResponse
  );
}

function postRoles(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/account/ManageRoles", requestOptions).then(
    handleResponse
  );
}

function putTeacherRequests(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/teacherrequests", requestOptions).then(
    handleResponse
  );
}

function putTeacherAccounts(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/teacheraccounts", requestOptions).then(
    handleResponse
  );
}

function getMuseums() {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  return fetch(baseUrl + "/admin/museumrolesandmuseums",requestOptions).then(handleResponse);
}

function getMuseumAccounts(data) {
  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
    body:JSON.stringify(data),
	};

  return fetch(baseUrl + "/admin/museumaccounts" ,requestOptions).then(
    handleResponse
  );
}
function listOfMuseum() {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};

  return fetch(baseUrl + "/admin/museum" ,requestOptions).then(handleResponse);
}

function postMuseumList(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/museum", requestOptions).then(handleResponse);
}

function putMuseumList(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/museum", requestOptions).then(handleResponse);
}

function deleteMuseumListItem(data) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      Id: data,
    }),
  };
  return fetch(baseUrl + "/admin/museum", requestOptions).then(handleResponse);
}

function createMuseumAccounts(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };

  return fetch(baseUrl + "/admin/museumaccount", requestOptions).then(
    handleResponse
  );
}

function resetAccounts(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      UserId: data.UserId,
    }),
  };
  return fetch(baseUrl + "/admin/reset", requestOptions).then(handleResponse);
}

function putLockAccounts(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      UserId: data.UserId,
    }),
  };
  return fetch(baseUrl + "/admin/lockaccount", requestOptions).then(
    handleResponse
  );
}

function editMuseumAccounts(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + "/admin/museumaccount", requestOptions).then(
    handleResponse
  );
}

function deleteMuseumAccounts(data) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      UserId: data,
    }),
  };
  return fetch(baseUrl + "/admin/museumaccount", requestOptions).then(
    handleResponse
  );
}

function deleteTeacherAccounts(data) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      UserId: data,
    }),
  };
  return fetch(baseUrl + "/admin/teacherrequests", requestOptions).then(
    handleResponse
  );
}
function getBannerImages(home) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  let pageNo=1
  let PageSize=5
  let IsFileContentRequired	=true
  if (home) {
     return fetch(baseUrl + "/resource/home/bannerimages?pageNo=" + pageNo + '&PageSize=' + PageSize + '&IsFileContentRequired=' + IsFileContentRequired, requestOptions).then(handleResponse);
  }
  return fetch(baseUrl + "/resource/bannerimages?pageNo=" + pageNo+'&PageSize='+PageSize+'&IsFileContentRequired='+IsFileContentRequired,requestOptions).then(handleResponse);
}

function postBannerImages(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify({
      BannerImages:data,
    })

  };
  return fetch(baseUrl + "/resource/bannerimages", requestOptions).then(
    handleResponse
  );
}
function postResource(data) {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data)

  };
  return fetch(baseUrl + "/resource", requestOptions).then(
    handleResponse
  );
}
function getMuseumDropdownDetails() {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  return fetch(baseUrl + "/Master/museumdropdowndetails",requestOptions).then(handleResponse);
}
function putHolidaysList(data) {

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data)

  };
  return fetch(baseUrl + "/Admin/holidays", requestOptions).then(handleResponse);
}
function getHolidaysList() {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  return fetch(baseUrl + "/Admin/holidays",requestOptions).then(handleResponse);
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
          sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
      }
      if(response.status === 500){
        let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
        window.location.reload(true);

      }

      return Promise.reject(data);
    }

    return data;
  });
}
