import { manageConstants } from '../constants';

export function postDashboard(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_DASHBOARD_REQUEST:
			return { pendingDashboard: false , checkData: action.checkedData};
		case manageConstants.POST_DASHBOARD_SUCCESS:
			return { pendingDashboard: action.data, checkData: action.checkedData,request:action.request };
		case manageConstants.POST_DASHBOARD_FAILURE:
			return { pendingDashboard: 'error' };
		default:
			return state;
	}
}


