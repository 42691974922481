import {globalSearchConstants } from '../constants';
import {globalSearchService} from '../services';
import { alertActions } from './';

export const globalSearchActions = {
	getGlobalSearch,
	postFeedback
}


function getGlobalSearch(data) {
	return dispatch => {
		dispatch(request({ data }));
		globalSearchService.getGlobalSearch(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: globalSearchConstants.GET_GLOBAL_SEARCH_REQUEST, data };
	}

	function success(data) {
		return { type: globalSearchConstants.GET_GLOBAL_SEARCH_SUCCESS, data };
	}

	function failure(data) {
		return { type: globalSearchConstants.GET_GLOBAL_SEARCH_FAILURE, data };
	}
}

function postFeedback(data) {
	return dispatch => {
		dispatch(request({ data }));
		globalSearchService.postFeedback(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: globalSearchConstants.POST_FEEDBACK_REQUEST, data };
	}

	function success(data) {
		return { type: globalSearchConstants.POST_FEEDBACK_SUCCESS, data };
	}

	function failure(data) {
		return { type: globalSearchConstants.POST_FEEDBACK_FAILURE, data };
	}
}
