import { tourbookingslotConstants } from '../constants';

export const tourbookingslotActions = {
	success,
	error,
	clear,
	programmewiseslot,
	programmewiseslotclear,
	tourBookingSlot
};

function clear() {
	return { type: tourbookingslotConstants.BOOKING_SLOT_CLEAR};
}

function success(data) {
	return { type: tourbookingslotConstants.BOOKING_SLOT_SUCCESS, data };
}
function tourBookingSlot(data) {
	return { type: tourbookingslotConstants.BOOKING_SLOT_SUCCESS, data };
}
function error(data) {
	return { type: tourbookingslotConstants.BOOKING_SLOT_FAILURE,data };
}
function programmewiseslot(data) {
	return { type: tourbookingslotConstants.PROGRAMME_WISE_SLOT_SUCCESS, data };
}

function programmewiseslotclear(data) {
	return { type: tourbookingslotConstants.PROGRAMME_WISE_SLOT_CLEAR, data };
}

