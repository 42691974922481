import { manageConstants } from '../constants';

export function getTeacherAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_TEACHER_ACCOUNT_REQUEST:
			return { pendingTeacherAccount: false };
		case manageConstants.GET_TEACHER_ACCOUNT_SUCCESS:
			return { pendingTeacherAccount: action.data };
		case manageConstants.GET_TEACHER_ACCOUNT_FAILURE:
			return { pendingTeacherAccount: 'error' };
		default:
			return state;
	}
}

export function putTeacherAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_TEACHER_ACCOUNT_REQUEST:
			return { teacherAccount: false };
		case manageConstants.PUT_TEACHER_ACCOUNT_SUCCESS:
			return { teacherAccount: true };
		case manageConstants.PUT_TEACHER_ACCOUNT_FAILURE:
			return { teacherAccount: false };
		default:
			return state;
	}
}

export function deleteTeacherAccounts(state = {}, action) {
	switch (action.type) {
		case manageConstants.DELETE_TEACHER_ACCOUNT_REQUEST:
			return { deleteTeachersAccounts: false };
		case manageConstants.DELETE_TEACHER_ACCOUNT_SUCCESS:
			return { deleteTeachersAccounts: true };
		case manageConstants.DELETE_TEACHER_ACCOUNT_FAILURE:
			return { deleteTeachersAccounts: false };
		default:
			return state;
	}
}
