export const teacherbookingsConstants = {
	GET_BOOKED_TEACHER_REQUEST: 'GET_BOOKED_TEACHER_REQUEST',
	GET_BOOKED_TEACHER_SUCCESS: 'GET_BOOKED_TEACHER_SUCCESS',
	GET_BOOKED_TEACHER_FAILURE: 'GET_BOOKED_TEACHER_FAILURE',
	CONFIRMED_STATUS: 'Thank you for booking with us. A copy of the booking confirmation will be emailed to you shortly at tanliting@moe.edu.sg and to all accompanying adults.',
	PENDING_SELF_GUIDED: 'Thank you for booking with us. You will receive a confirmation within 3-5 days. A copy of your booking details will be emailed to you shortly at tanliting@moe.edu.sg and to all accompanying adults.',
	PENDING_GUIDED: 'Thank you for booking with us. You will receive a confirmation within 4 weeks. A copy of your booking details will be emailed to you shortly at tanliting@moe.edu.sg and to all accompanying adults.',
	BOOKING_CONFIRMED: "Booking Confirmed",
	BOOKING_PENDING: 'Booking Pending',
	POST_MY_BOOKINGS_REQUEST: 'POST_MY_BOOKINGS_REQUEST',
	POST_MY_BOOKINGS_SUCCESS: 'POST_MY_BOOKINGS_SUCCESS',
	POST_MY_BOOKINGS_FAILURE: 'POST_MY_BOOKINGS_FAILURE',
	PUT_MY_BOOKING_REQUEST:'PUT_MY_BOOKING_REQUEST',
	PUT_MY_BOOKING_SUCCESS:'PUT_MY_BOOKING_SUCCESS',
	PUT_MY_BOOKING_FAILURE:'PUT_MY_BOOKING_FAILURE',
	DELETE_MY_BOOKING_REQUEST:'DELETE_MY_BOOKING_REQUEST',
	DELETE_MY_BOOKING_SUCCESS:'DELETE_MY_BOOKING_SUCCESS',
	DELETE_MY_BOOKING_FAILURE:'DELETE_MY_BOOKING_FAILURE',
	GET_MY_BOOKING_REQUEST:'GET_MY_BOOKING_REQUEST',
	GET_MY_BOOKING_SUCCESS:'GET_MY_BOOKING_SUCCESS',
	GET_MY_BOOKING_FAILURE:'GET_MY_BOOKING_FAILURE',
	GET_FEEDBACK_QUESTIONS_REQUEST: 'GET_FEEDBACK_QUESTIONS_REQUEST',
    GET_FEEDBACK_QUESTIONS_SUCCESS: 'GET_FEEDBACK_QUESTIONS_SUCCESS',
    GET_FEEDBACK_QUESTIONS_FAILURE: 'GET_FEEDBACK_QUESTIONS_FAILURE',
	POST_FEEDBACK_QUESTIONS_REQUEST: 'POST_FEEDBACK_QUESTIONS_REQUEST',
    POST_FEEDBACK_QUESTIONS_SUCCESS: 'POST_FEEDBACK_QUESTIONS_SUCCESS',
    POST_FEEDBACK_QUESTIONS_FAILURE: 'POST_FEEDBACK_QUESTIONS_FAILURE'
	
};
