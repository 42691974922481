import { manageBookingConstants } from "../constants";

export function getBookedSlotDetails(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.GET_BOOKED_SLOT_REQUEST:
      return { bookingDetails: false };
    case manageBookingConstants.GET_BOOKED_SLOT_SUCCESS:
      return { bookingDetails: action.data };
    case manageBookingConstants.GET_BOOKED_SLOT_FAILURE:
      return { bookingDetails: false };
    default:
      return state;
  }
}

export function saveBookedSlotDetails(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.SAVE_BOOKED_SLOT_REQUEST:
      return { saveSlotDetails: false };
    case manageBookingConstants.SAVE_BOOKED_SLOT_SUCCESS:
      return { saveSlotDetails: true };
    case manageBookingConstants.SAVE_BOOKED_SLOT_FAILURE:
      return { saveSlotDetails: false };
    default:
      return state;
  }
}

export function getInvoice(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.GET_INVOICE_REQUEST:
      return { bookingDetails: false };
    case manageBookingConstants.GET_INVOICE_SUCCESS:
      return { bookingDetails: action.data };
    case manageBookingConstants.GET_INVOICE_FAILURE:
      return { bookingDetails: false };
    default:
      return state;
  }
}

export function postFeesCalculation(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.POST_FEES_REQUEST:
      return { feesDetails: false };
    case manageBookingConstants.POST_FEES_SUCCESS:
      return { feesDetails: action.data };
    case manageBookingConstants.POST_FEES_FAILURE:
      return { feesDetails: false };
    default:
      return state;
  }
}
export function postPrivateFeesCalculation(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.POST_PRIVATE_FEES_REQUEST:
      return { privateFeesDetails: false };
    case manageBookingConstants.POST_PRIVATE_FEES_SUCCESS:
      return { privateFeesDetails: action.data };
    case manageBookingConstants.POST_PRIVATE_FEES_FAILURE:
      return { privateFeesDetails: false };
    default:
      return state;
  }
}
export function postRemainder(state = {}, action) {
  switch (action.type) {
    case manageBookingConstants.POST_REMINDER_REQUEST:
      return { remainderDetails: false };
    case manageBookingConstants.POST_REMINDER_SUCCESS:
      return { remainderDetails: action.data };
    case manageBookingConstants.POST_REMINDER_FAILURE:
      return { remainderDetails: false };
    default:
      return state;
  }
}
