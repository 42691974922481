/** NH */
import { manageConstants } from '../constants';


/** NH */
var initialState  = {
  roleRecords : [],
  tableData : [],
}

/** NH recommended to defined reducers which use object assign to replace
 * entire state to new state, instead of mutating.
 */
export function getRoles(state = initialState, action) {
  switch(action.type) {
    case manageConstants.GET_ROLES_REQUEST:
      return Object.assign({}, state, {
        roleRecords : false
      })
    case manageConstants.GET_ROLES_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        roleRecords : action.data,
        tableData : formatTableData(action.data)
      })
    case manageConstants.GET_ROLES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        roleRecords : false
      })
    /** NH use this case for updating the table record data state */
    case 'UPDATE_TABLE_DATA' : {
      return Object.assign({}, state, {
        tableData : action.data
      })
    }
    default:
      return state;
  }
}


export function updateRoleMuseum(state = {}, action) {
	switch (action.type) {
		case manageConstants.POST_ROLES_REQUEST:
			return { gotmuseumRole: false };
		case manageConstants.POST_ROLES_REQUEST_SUCCESS:
			return { gotmuseumRole: action.data };
		case manageConstants.POST_ROLES_REQUES_FAILURE:
			return { gotmuseumRole: false };
		default:
			return state;
	}
}


/** NH - the action creator (Temporary to be place here) */
export function updateTableData (data) {
  return dispatch => {
    dispatch({type :  "UPDATE_TABLE_DATA", data : data})
  }
}

/** NH */
export function formatTableData(data) {
  let formattedData = [];

  if(Array.isArray(data)){
    for(let item of data) {
    let list = item.PrivilegeList;
    let obj = {}
    obj['UserRoles'] = item.RoleName;
    for(let p of list){
      obj[p.PrivilegeName] = {
        isDisabled : p.IsDisabled,
        isChecked : p.IsChecked
      }
    }
    formattedData.push(obj)
  }}

  return formattedData
}

/** NH old defined reducer */
// export function getRoles(state = {}, action) {
//   switch(action.type) {
//     case manageConstants.GET_ROLES_REQUEST:
//       return { pendingRoles: false};
//     case manageConstants.GET_ROLES_REQUEST_SUCCESS:
//       return { pendingRoles: action.data};
//     case manageConstants.GET_ROLES_REQUEST_FAILURE:
//       return { pendingRoles: false};
//     default:
//       return state;
//   }
// }

// var obj = {
//   'MC' : {
//     "UserRoles" : "Musuem Customer",
//     'ManageAccount' : false,
//     "ManageBooking" : true,
//     "GenerateBookingReport" : true,
//     "GenerateInvoice" : false,
//     "ManageMuseumSettings" : true
//   },
//   'MA' : {
//     "UserRoles" : "Musuem Customer",
//     'ManageAccount' : false,
//     "ManageBooking" : true,
//     "GenerateBookingReport" : true,
//     "GenerateInvoice" : false,
//     "ManageMuseumSettings" : true
//   }
// }