export const museumProgrammeConstants = {
	GET_PROGRAMME_REQUEST: 'GET_PROGRAMME_REQUEST',
	GET_PROGRAMME_SUCCESS: 'GET_PROGRAMME_SUCCESS',
	GET_PROGRAMME_FAILURE: 'GET_PROGRAMME_FAILURE',
	
	GET_PROGRAMME_MASTER_DATA_REQUEST: 'GET_PROGRAMME_MASTER_DATA_REQUEST',
	GET_PROGRAMME_MASTER_DATA_SUCCESS: 'GET_PROGRAMME_MASTER_DATA_SUCCESS',
	GET_PROGRAMME_MASTER_DATA_FAILURE: 'GET_PROGRAMME_MASTER_DATA_FAILURE',
	
	POST_PROGRAMME_DETAILS_REQUEST: 'POST_PROGRAMME_DETAILS_REQUEST',
	POST_PROGRAMME_DETAILS_SUCCESS: 'POST_PROGRAMME_DETAILS_SUCCESS',
	POST_PROGRAMME_DETAILS_FAILURE: 'POST_PROGRAMME_DETAILS_FAILURE',

	GET_PROGRAMME_PREVIEW_REQUEST: 'GET_PROGRAMME_PREVIEW_REQUEST',
	GET_PROGRAMME_PREVIEW_SUCCESS: 'GET_PROGRAMME_PREVIEW_SUCCESS',
	GET_PROGRAMME_PREVIEW_FAILURE: 'GET_PROGRAMME_PREVIEW_FAILURE',

	PUT_PROGRAMME_DETAILS_SUBMITED_REQUEST: 'PUT_PROGRAMME_DETAILS_SUBMITED_REQUEST',
	PUT_PROGRAMME_DETAILS_SUBMITED_SUCCESS: 'PUT_PROGRAMME_DETAILS_SUBMITED_SUCCESS',
    PUT_PROGRAMME_DETAILS_SUBMITED_FAILURE: 'PUT_PROGRAMME_DETAILS_SUBMITED_FAILURE',
	
	PUT_PROGRAMME_APPROVE_REJECT_REQUEST: 'PUT_PROGRAMME_APPROVE_REJECT_REQUEST',
	PUT_PROGRAMME_APPROVE_REJECT_SUCCESS: 'PUT_PROGRAMME_APPROVE_REJECT_SUCCESS',
	PUT_PROGRAMME_APPROVE_REJECT_FAILURE: 'PUT_PROGRAMME_APPROVE_REJECT_FAILURE',

	DELETE_PROGRAMME_REQUEST: 'DELETE_PROGRAMME_REQUEST',
	DELETE_PROGRAMME_SUCCESS: 'DELETE_PROGRAMME_SUCCESS',
	DELETE_PROGRAMME_FAILURE: 'DELETE_PROGRAMME_FAILURE',

	SUBMIT_PROGRAMME_DETAILS_REQUEST: 'SUBMIT_PROGRAMME_DETAILS_REQUEST',
	SUBMIT_PROGRAMME_DETAILS_SUCCESS: 'SUBMIT_PROGRAMME_DETAILS_SUCCESS',
	SUBMIT_PROGRAMME_DETAILS_FAILURE: 'SUBMIT_PROGRAMME_DETAILS_FAILURE',

	GET_PROGRAMME_DETAILS_REQUEST: 'GET_PROGRAMME_DETAILS_REQUEST',
	GET_PROGRAMME_DETAILS_SUCCESS: 'GET_PROGRAMME_DETAILS_SUCCESS',
	GET_PROGRAMME_DETAILS_FAILURE: 'GET_PROGRAMME_DETAILS_FAILURE',

	GET_SEARCH_PROGRAMME_MASTER_DATA_REQUEST: 'GET_SEARCH_PROGRAMME_MASTER_DATA_REQUEST',
	GET_SEARCH_PROGRAMME_MASTER_DATA_SUCCESS: 'GET_SEARCH_PROGRAMME_MASTER_DATA_SUCCESS',
	GET_SEARCH_PROGRAMME_MASTER_DATA_FAILURE: 'GET_SEARCH_PROGRAMME_MASTER_DATA_FAILURE',

	POST_FIND_PROGRAMME_REQUEST: 'POST_FIND_PROGRAMME_REQUEST',
	POST_FIND_PROGRAMME_SUCCESS: 'POST_FIND_PROGRAMME_SUCCESS',
	POST_FIND_PROGRAMME_FAILURE: 'POST_FIND_PROGRAMME_FAILURE',

	GET_CARD_PROGRAMME_DATA_REQUEST: 'GET_CARD_PROGRAMME_DATA_REQUEST',
	GET_CARD_PROGRAMME_DATA_SUCCESS: 'GET_CARD_PROGRAMME_DATA_SUCCESS',
	GET_CARD_PROGRAMME_DATA_FAILURE: 'GET_CARD_PROGRAMME_DATA_FAILURE',

	GET_PROGRAMME_RECOMMENDATIONS_REQUEST: 'GET_PROGRAMME_RECOMMENDATIONS_REQUEST',
	GET_PROGRAMME_RECOMMENDATIONS_SUCCESS: 'GET_PROGRAMME_RECOMMENDATIONS_SUCCESS',
	GET_PROGRAMME_RECOMMENDATIONS_FAILURE: 'GET_PROGRAMME_RECOMMENDATIONS_FAILURE',
	
	POST_EXISTING_PROGRAMME_REQUEST: 'POST_EXISTING_PROGRAMME_REQUEST',
	POST_EXISTING_PROGRAMME_SUCCESS: 'POST_EXISTING_PROGRAMME_SUCCESS',
	POST_EXISTING_PROGRAMME_FAILURE: 'POST_EXISTING_PROGRAMME_FAILURE',
}