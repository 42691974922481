import { userConstants } from '../constants';

export function getuserprofile(state = {}, action) {
	switch (action.type) {
		case userConstants.GET_PROFILE_REQUEST:
			return { gotuserdetails: false };
		case userConstants.GET_PROFILE_SUCCESS:
			return { gotuserdetails: action.data };
		case userConstants.GET_PROFILE_FAILURE:
			return { gotuserdetails: false };
		default:
			return state;
	}
}
export function accountMyInfo(state = {}, action) {
	switch (action.type) {
		case userConstants.MYINFO_REQUEST:
			return { myinfo: 'loading' };
		case userConstants.MYINFO_SUCCESS:
			return { myinfo: action.data };
		case userConstants.MYINFO_FAILURE:
			return { myinfo: false };
		default:
			return state;
	}
}
