import { userConstants } from '../constants';

export function getnotification(state = {}, action) {
	switch (action.type) {
		case userConstants.GET_NOTIFICATION_REQUEST:
			return { gotnotificationdetails: false };
		case userConstants.GET_NOTIFICATION_SUCCESS:
			return { gotnotificationdetails: action.data };
		case userConstants.GET_NOTIFICATION_FAILURE:
			return { gotnotificationdetails: false };
		default:
			return state;
	}
}

export function putNotification(state = {}, action) {
	switch (action.type) {
		case userConstants.PUT_NOTIFICATION_REQUEST:
			return { putNotifications: false };
		case userConstants.PUT_NOTIFICATION_SUCCESS:
			return { putNotifications: true };
		case userConstants.PUT_NOTIFICATION_FAILURE:
			return { putNotifications: false };
		default:
			return state;
	}
}
