import { userConstants } from '../constants';

export function registration(state = {}, action) {
	switch (action.type) {
		case userConstants.REGISTER_REQUEST:
			return { registration: 'In progress' };
		case userConstants.REGISTER_SUCCESS:
			return { registration: 'Success' };
		case userConstants.REGISTER_FAILURE:
			return { registration: 'Failure' };
		default:
			return state;
	}
}
