import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const newBookingService = {
	getNewBooking,
	postNewBooking,
	getPrivateBookedSlot,
	putPrivateBookedSlot
}

function getNewBooking(bookingData){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	if(bookingData.calenderId){
		return fetch(baseUrl + '/booking/private?calenderId='+bookingData.calenderId,requestOptions).then(handleResponse);
	} else{
		return fetch(baseUrl + '/booking/private?bookingNumber='+bookingData.bookingNumber+'&Tab='+bookingData.Tab,requestOptions).then(handleResponse);
	}
}

function postNewBooking(data){
	let bookingDt = {};
	let contactDt = {};
	let confirmationDt = {};


	bookingDt = {
		BookingNumber: data.BookingNumber,
		SlotDetails: {
			CalenderId: data.CalenderId,
			ProgrammeId: data.ProgrammeId,
			SlotData: {
				Language:data.Language,
				Purpose: data.Purpose,
				Attendees: {
					Adults: {
						Citizen: data.CitizenAdult,
						NonCitizen: data.NonCitizenAdult
					},
					Children: {
						Citizen: data.CitizenChildren,
						NonCitizen: data.NonCitizenChildren
					},
					Seniors: {
						Citizen: data.CitizenSenior,
						NonCitizen: data.NonCitizenSenior
					},
					CMIO:data.CMIO?
					{	Chinese: data.Chinese,
						Malay: data.Malay,
						Indian: data.Indian,
						Others: data.Others}:null,
				},
				ProgramChargedBy:data.ProgramChargedBy,
				MCRemarks: data.MCRemarks,
			},
			NoOfPax: data.NoOfPax
		},
		Tab: data.Tab
	}

	if(data.TourType === 'Self-Guided' || ((data.TourType === 'Guided' || data.TourType === 'Programme&Workshop' || data.TourType === 'Museum Based Learning') && data.CitizenAdultCost !== ''&&data.ProgramChargedBy&&data.ProgramChargedBy.PerPax)){
		bookingDt.SlotDetails.SlotData.PerPaxCost = {
			Adults: {
				Citizen: data.CitizenAdultCost,
				NonCitizen: data.NonCitizenAdultCost
			},
			Children: {
				Citizen: data.CitizenChildrenCost,
				NonCitizen: data.NonCitizenChildrenCost
			},
			Seniors: {
				Citizen: data.CitizenSeniorCost,
				NonCitizen: data.NonCitizenSeniorCost
			}
		}
	}
	if(data.DocentNo && data.CostPerDocent &&data.ProgramChargedBy&&data.ProgramChargedBy.PerGroupWithDocent){
		bookingDt.SlotDetails.SlotData.GroupCost = {
			RequiredDocents: data.DocentNo,
			CostPerDocent: data.CostPerDocent
		}
	}

	contactDt = {
		BookingNumber: data.BookingNumber,
		SlotDetails: {
			CalenderId: data.CalenderId,
			ProgrammeId: data.ProgrammeId,
			SlotData: {
				CoOrdinator: {
					Name: data.FullName,
					Email: data.Email,
					MobileNumber: data.MobileNumber,
					School: {
					SchoolId: "Orgaination ID",
					SchoolName: data.Organisation
					}
				}
			}
		},

		  Tab: data.Tab
	}

	confirmationDt = {
		BookingNumber: data.BookingNumber,
		Tab: data.Tab
	}


	let requestOptions = "";
		requestOptions = {
			method: data.BookingNumber === "" ? "POST" : "PUT",
			headers: { 'Content-Type': 'application/json',...token },
			body: data.Tab === 1 ? JSON.stringify(bookingDt): ( data.Tab === 2 ? JSON.stringify(contactDt): JSON.stringify(confirmationDt)),
		};
	return fetch(baseUrl + '/booking/private', requestOptions).then(handleResponse);

}

function getPrivateBookedSlot(data) {
	const bookingNumber = data;
	const requestOptions = {
		method: "GET",
		headers: { "Content-Type": 'application/json',...token },
	  };
	return fetch(
	  `${baseUrl}/managebooking/private/bookedslot?bookingNumber=${bookingNumber}`,requestOptions).then(handleResponse);
}

function putPrivateBookedSlot(data) {
	let roleId = localStorage.getItem('UserRoleType');
	let bookingDt = {};
	bookingDt = {
		BookingNumber: data.BookingNumber,
		TourTypeKey: data.TourType,
		SlotData: {
			Language:data.Language,
			Attendees: {
				Adults: {
					Citizen: data.CitizenAdult,
					NonCitizen: data.NonCitizenAdult
				},
				Children: {
					Citizen: data.CitizenChildren,
					NonCitizen: data.NonCitizenChildren
				},
				Seniors: {
					Citizen: data.CitizenSenior,
					NonCitizen: data.NonCitizenSenior
				},
				CMIO:data.CMIO? {
					Chinese: data.Chinese,
					Malay: data.Malay,
					Indian: data.Indian,
					Others: data.Others
				}:null,
			},

			MCRemarks: data.MCRemarks,
		},
		Resource: data.Resource,
		StatusKey: data.StatusKey,
		NoOfPax: data.NoOfPax
	}

	if(roleId === "MC" ){
		if(data.TourType === 'Self-Guided' || ((data.TourType === 'Guided' || data.TourType === 'Programme&Workshop' || data.TourType === 'Museum Based Learning') && data.ProgramChargedBy&&data.ProgramChargedBy.PerPax)){
			bookingDt.SlotData.PerPaxCost = {
				Adults: {
					Citizen: data.CitizenAdultCost,
					NonCitizen: data.NonCitizenAdultCost
				},
				Children: {
					Citizen: data.CitizenChildrenCost,
					NonCitizen: data.NonCitizenChildrenCost
				},
				Seniors: {
					Citizen: data.CitizenSeniorCost,
					NonCitizen: data.NonCitizenSeniorCost
				}
			}
		}
		bookingDt.SlotData.ProgramChargedBy=data.ProgramChargedBy
	}

	if(roleId === "MFD"){
		bookingDt.SlotData.BookingPaymentDetails = data.SlotData.BookingPaymentDetails;
	}

	if(roleId === "MF"){
		bookingDt.SlotData.BookingPaymentDetails = data.SlotData.BookingPaymentDetails;
	}

	if(data.DocentNo && data.RequiredDocents ){
		bookingDt.SlotData.GroupCost = {
			RequiredDocents: data.DocentNo,
			CostPerDocent: data.CostPerDocent
		}
	}
	if(roleId === "MC" ){
		if(data.DocentNo && data.CostPerDocent && data.ProgramChargedBy&&data.ProgramChargedBy.PerGroupWithDocent){
			bookingDt.SlotData.GroupCost = {
				RequiredDocents: data.DocentNo,
				CostPerDocent: data.CostPerDocent
			}
		}
		if(data.TourType === 'Self-Guided'){
			bookingDt.SlotData.GroupCost =null
		}
	}

	const requestOptions = {
	  method: "PUT",
	  headers: { "Content-Type": 'application/json',...token },
	  body: JSON.stringify(bookingDt),
	};
	if(roleId === "MC"){
	return fetch(baseUrl + '/managebooking/private/bookedslot?managedBy=1', requestOptions).then(handleResponse);
	} else if(roleId === "MFD"){
		return fetch(baseUrl + '/managebooking/private/bookedslot?managedBy=2', requestOptions).then(handleResponse);
	  }

	  else if(roleId === "MF"){
		return fetch(baseUrl + '/managebooking/private/bookedslot?managedBy=3', requestOptions).then(handleResponse);
	  }
  }

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }
			return Promise.reject(data);
		}

		return data;
	});
}