import { UserManager } from 'oidc-client';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'

const config = {
  authority:window.env.Identity_URL,
  client_id: "clientapp",
  redirect_uri:window.location.origin+'/signin-oidc',
  response_type: "id_token token",
  scope: "openid profile NhbApi",
  post_logout_redirect_uri:window.location.origin+'/signout-oidc',
  automaticSilentRenew: true,
  loadUserInfo: true,
  checkSessionInterval: 600,
  monitorSession: false,
  useRefreshToken: true

};

const userManager = new UserManager(config)

export async function loadUserFromStorage() {
  try {
    let user = await userManager.getUser()
    if (!user) { return null }
    else{
      return user
    }
    // store.dispatch(storeUser(user))
  } catch (e) {
    console.error(`User not found: ${e}`)
    // store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  store.dispatch(alertActions.mainLoader(true));
  return userManager.signinRedirect()
}
export function renewToken() {
  return userManager.signinSilent()
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
  store.dispatch(alertActions.mainLoader(true));
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  localStorage.clear()
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager