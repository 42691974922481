import { manageConstants } from '../constants';

export function putTeacherRequests(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_TEACHER_APPROVAL_REQUEST:
			return { teacherApproval: false };
		case manageConstants.PUT_TEACHER_APPROVAL_SUCCESS:
			return { teacherApproval: true };
		case manageConstants.PUT_TEACHER_APPROVAL_FAILURE:
			return { teacherApproval: false };
		default:
			return state;
	}
}
