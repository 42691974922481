import { baseUrl } from "../helpers/env.config";
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const reportsSAService = {
    getReportsSA,
    postReportsSA,
    putResourceTracker
}

function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          var localUser = JSON.parse(localStorage.getItem('user'));
				  if (localUser) {
					localStorage.clear()
          sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
        }
        if(response.status === 500){
          let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
          window.location.reload(true);

        }
        return Promise.reject(data);
      }
      return data;
    });
}

function getReportsSA(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
  return fetch(baseUrl + '/report?roleId='+ data.roleId, requestOptions).then(handleResponse);
}

function postReportsSA (data) {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',...token },
      body: JSON.stringify(
          {
            StartDate: data.StartDate,
            EndDate: data.EndDate,
            ReportStructure: [
              {
                ReportType: data.ReportType,
                ReportAttributes:
                  data.ReportAttributes,
              }
            ],
          }
      ),
  };

  return fetch(baseUrl + '/report', requestOptions).then(handleResponse);
}

function putResourceTracker (data) {
  const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token }
	}

  return fetch(baseUrl + '/report/trackresourcedownloads?programmeId='+ data.programmeId+'&source='+data.source,requestOptions).then(handleResponse);
}