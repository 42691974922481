import { alertConstants } from '../constants';

export const alertActions = {
	success,
	error,
	clear,
	openRegister,
	verifyEmail,
	serverError,
	mainLoader
};

function success(message,status,code,title) {
	return { type: alertConstants.SUCCESS, message, status: status,code:code,title:title };
}

function error(message,status,code,token) {
	return { type: alertConstants.ERROR, message, status: status,code:code,token:token };
}

function clear() {
	return { type: alertConstants.CLEAR };
}
function openRegister(status,message,token) {
	return { type: 'OPEN_REGISTER',status:status,message:message,token:token };
}
function verifyEmail(status,value) {
	return { type: 'VERIFY_EMAIL',status:status,value:value};
}
function serverError(status,value) {
	return { type: 'SERVER_ERROR',status:status,value:value};
}
function mainLoader(value) {
	return { type: 'MAIN_LOADER',loading:value};
}
