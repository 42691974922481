import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const teacherBookingService = {
	getBookedDetails,
	PostMyBookings,
	PutMyBooking,
	DeleteMyBooking,
	GetMyBooking,
	getFeedbackQuestions,
	postFeedbackQuestions
}

function getBookedDetails(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	 return fetch(baseUrl + '/booking?bookingId='+data.bookingId + '&tab=' +data.tab,requestOptions).then(handleResponse);

}
function PostMyBookings(data){
	let jsonData = {
			Tab: data.Tab,
			AdvanceSearch: data.AdvanceSearch,
			Pagination:data.Pagination
	}


	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/mybooking/list', requestOptions).then(handleResponse);
}

function PutMyBooking(data){

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/mybooking', requestOptions).then(handleResponse);
}

function DeleteMyBooking(data){

	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/mybooking?bookingNumber='+data, requestOptions).then(handleResponse);
}

function GetMyBooking(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl+'/mybooking?bookingId='+data.bookingId+'&programmeId='+data.programmeId,requestOptions).then(handleResponse);
   // return fetch(baseUrl + '/booking?bookingId=NHB2020082015500846&tab=1').then(handleResponse);
}
function getFeedbackQuestions(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/mybooking/feedback?tab='+data.tab + '&bookingNumber=' +data.bookingNumber,requestOptions).then(handleResponse);
}

function postFeedbackQuestions(data){
	var localUser = JSON.parse(localStorage.getItem('user'));
	let jsonData = {
			Email:localUser?.Email||'',
			BookingId:data.BookingId,
			BookingNumber: data.BookingNumber,
			FeedbackAnswers: []

}
data&&data.dataArray&&data.dataArray.length>0&&data.dataArray.forEach((Subdata)=>{
	Subdata.forEach((obj)=>{
		jsonData.FeedbackAnswers.push({Key:obj.Id, Value:obj.Answer})
	})
})



const requestOptions = {
	method: 'POST',
	headers: { 'Content-Type': 'application/json',...token },
	body: JSON.stringify(jsonData),
};
return fetch(baseUrl + '/mybooking/feedback', requestOptions).then(handleResponse);


}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }
			return Promise.reject(data);
		}
		return data;
	});
}