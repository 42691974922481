import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import { alertActions } from '../actions/alert.actions'
import store from '../helpers/store.js'
import { history } from '../helpers';
import { ErrorResponse } from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT = authHeader()
let token = {}
JWT.then(function (data) {
    token = data
});
export const tourBookingService = {
    putProgrammeSlot,
    bookProgramme,
    getBookedProgramme,
    updateSlotSelection,
    confirmSlotSelection
}

function putProgrammeSlot(data) {
    let jsonData = {
        StartDate: data.StartDate,
        EndDate: data.EndDate,
        ProgrammeIds: [],
        NoOfPax: data.NoOfPax
    }
    if (data.ProgrammeIds) {
        jsonData.ProgrammeIds = data.ProgrammeIds
    }
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...token },
        body: JSON.stringify(jsonData),
    };
    return fetch(baseUrl + '/booking/calender', requestOptions).then(handleResponse);

}

function updateSlotSelection(data) {
    var jsonData;
    jsonData = data
    const Method = data.BookingId ? "PUT" : "POST";
    const requestOptions = {
        method: Method,
        headers: { 'Content-Type': 'application/json', ...token },
        body: JSON.stringify(jsonData),
    };
    return fetch(baseUrl + '/booking', requestOptions).then(handleResponse);

}

function bookProgramme(data, BookingId) {
    var localUser = JSON.parse(localStorage.getItem('user'));
    var jsonData;
    jsonData = stepOneForm(data, BookingId, localUser);;
    const requestOptions = {
        method: BookingId ? "PUT" : "POST",
        headers: { 'Content-Type': 'application/json', ...token },
        body: JSON.stringify(jsonData),
    };
    return fetch(baseUrl + '/booking', requestOptions).then(handleResponse);

}

function getBookedProgramme(bookingData) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...token }
    }
    return fetch(baseUrl + '/booking?bookingId=' + bookingData.bookingId + '&tab=' + bookingData.tab, requestOptions).then(handleResponse);


}
function stepOneForm(data, BookingId, localUser) {
    let jsonData
    if (data) {

        let BookingDetails;
        let BookingDetailsArray = [];
        let BookingNumberArray = [];
        let totalNoOfPag = 0;

        data.forEach((value) => {
            let BookingSlotDetails;
            let BookingSlotDetailsArray = [];
            let ProgrammeId;
            if (value.ProgramDetails && value.ProgramDetails.ProgrammeId)
                ProgrammeId = value.ProgramDetails.ProgrammeId;
            else ProgrammeId = value.ProgrammeId

            value.Slots.forEach((slotValue) => {
                if (slotValue.BookingNumber)
                    BookingNumberArray.push(slotValue.BookingNumber)
                if (slotValue.NoOfPax)
                    totalNoOfPag = totalNoOfPag + Number.parseInt(slotValue.NoOfPax)
                BookingSlotDetails = {
                    BookingNumber: slotValue.BookingNumber ? slotValue.BookingNumber : "",
                    CalenderId: slotValue.CalendarId,
                    Date: slotValue.Date,
                    StartTime: slotValue.StartTime,
                    EndTime: slotValue.EndTime,
                    ArrivalTime: "",
                    NoOfPax: slotValue.NoOfPax ? slotValue.NoOfPax : 0,
                    TotalCost: 0,
                    NoOfAdults: 0,
                    SlotData: {
                        EducationLevelBasedPaxData: [
                            {
                                EducationLevel: slotValue.EducationLevel,
                                IsSelected: true,
                            }
                        ]
                    }
                }
                BookingSlotDetailsArray.push(BookingSlotDetails)
            })

            BookingDetails = {
                ProgrammeId: ProgrammeId,

                Slots: BookingSlotDetailsArray,
                BookingProgramData: {
                    BookingNumbers: BookingNumberArray,
                    TotalNoOfPax: totalNoOfPag
                }
            }
            BookingDetailsArray.push(BookingDetails)
        })
        jsonData = {
            BookingId: BookingId,
            ProgramsToBook: BookingDetailsArray,
            Tab: 1
        }
    }

    return jsonData;
}

function confirmSlotSelection(data) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...token }
    }
    let bookingId = data.BookingId;
    return fetch(baseUrl + "/booking?bookingId=" + bookingId + "&tab=3", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 400 && data && data.Status === 'OverBooked') {
                history.push('/ProgrammeList');
            }
            if (response.status === 401) {
                var localUser = JSON.parse(localStorage.getItem('user'));
                if (localUser) {
                    localStorage.clear()
                    sessionStorage.setItem('sessionTimeout', data && data.Message)
                    signoutRedirect()
                }
                return null
            }
            if (response.status === 500) {
                let correlationId = data.Id
                if (correlationId) {
                    history.push(`/error/500?Id=${correlationId}`)
                }
                else {
                    history.push('/error/500')
                }
                window.location.reload(true);

            }
            return Promise.reject(data);
        }

        return data;
    });
}
function handleBlockSlotResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        const status = response.status
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            if (response.status === 500) {
                let correlationId = data.Id
                if (correlationId) {
                    history.push(`/error/500?Id=${correlationId}`)
                }
                else {
                    history.push('/error/500')
                }
                window.location.reload(true);

            }
            //const error = (data && data.message) || response.statusText;
            return Promise.reject({ data, status });
        }

        return data;
    });
}