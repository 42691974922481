import { qrCodeConstants,manageBookingConstants,newBookingConstants } from '../constants';
import { qrCodeService } from '../services';
import { alertActions } from './';

export const qrCodeActions = {
	postQrCodeDecrpt,
};

function postQrCodeDecrpt(data,clear) {
	return dispatch => {
		dispatch(request({ data }));
		if(!clear){
			qrCodeService.postQrCodeDecrpt(data).then(
			data => {
				dispatch(success(data));
				if(data.BookingType==="SchoolGroup"){
				dispatch(setSchoolManageBooking(data));
				}else if(data.BookingType==="PrivateGroup"){
				dispatch(setPrivateManageBooking(data));
				}
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
		}
	};

	function request(data) {
		return { type: qrCodeConstants.POST_DECRYPT_DATA_REQUEST, data };
	}

	function success(data) {
		return { type: qrCodeConstants.POST_DECRYPT_DATA_SUCCESS, data };

	}
	function setSchoolManageBooking(data) {
		return { type: manageBookingConstants.GET_BOOKED_SLOT_SUCCESS, data };
	}
	function setPrivateManageBooking(data) {
		return { type: newBookingConstants.GET_PRIVATE_BOOKED_SLOT_SUCCESS, data };

	}

	function failure(data) {
		return { type: qrCodeConstants.POST_DECRYPT_DATA_FAILURE, data };
	}
}