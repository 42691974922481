import {
  STORE_USER,
  USER_SIGNED_OUT,
  USER_EXPIRED,
  STORE_USER_ERROR,
  LOADING_USER
} from './types'
// import { setAuthHeader } from '../utils/axiosHeaders'
export const SingPassAuth={
  storeUser,
  loadingUser,
  storeUserError,
  userExpired,
  userSignedOut
}
 function storeUser(user) {
  // setAuthHeader(user.access_token)
  return {
    type: STORE_USER,
    payload: user
  }
}

 function loadingUser() {
  return {
    type: LOADING_USER
  }
}

 function storeUserError() {
  return {
    type: STORE_USER_ERROR
  }
}

 function userExpired() {
  return {
    type: USER_EXPIRED
  }
}

 function userSignedOut() {
  return {
    type: USER_SIGNED_OUT
  }
}

