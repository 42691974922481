export const tickerConstants= {

    POST_TICKER_BLOCK_SLOT_REQUEST: 'POST_TICKER_BLOCK_SLOT_REQUEST',
    POST_TICKER_BLOCK_SLOT_SUCCESS: 'POST_TICKER_BLOCK_SLOT_SUCCESS',
    POST_TICKER_BLOCK_SLOT_FAILURE: 'POST_TICKER_BLOCK_SLOT_FAILURE',

    GET_MASTER_TICKERLIST_REQUEST: 'GET_MASTER_TICKERLIST_REQUEST',
    GET_MASTER_TICKERLIST_SUCCESS: 'GET_MASTER_TICKERLIST_SUCCESS',
    GET_MASTER_TICKERLIST_FAILURE: 'GET_MASTER_TICKERLIST_FAILURE',

    GET_MASTER_TICKERTIMERANGE_REQUEST: 'GET_MASTER_TICKERTIMERANGE_REQUEST',
    GET_MASTER_TICKERTIMERANGE_SUCCESS: 'GET_MASTER_TICKERTIMERANGE_SUCCESS',
    GET_MASTER_TICKERTIMERANGE_FAILURE: 'GET_MASTER_TICKERTIMERANGE_FAILURE',


    PUT_TICKER_BLOCK_SLOT_REQUEST: 'PUT_TICKER_BLOCK_SLOT_REQUEST',
    PUT_TICKER_BLOCK_SLOT_SUCCESS: 'PUT_TICKER_BLOCK_SLOT_SUCCESS',
    PUT_TICKER_BLOCK_SLOT_FAILURE: 'PUT_TICKER_BLOCK_SLOT_FAILURE',

    DELETE_TICKER_BLOCK_SLOT_REQUEST:'DELETE_TICKER_BLOCK_SLOT_REQUEST',
    DELETE_TICKER_BLOCK_SLOT_SUCCESS:'DELETE_TICKER_BLOCK_SLOT_SUCCESS',
    DELETE_TICKER_BLOCK_SLOT_FAILURE:'DELETE_TICKER_BLOCK_SLOT_FAILURE',

}