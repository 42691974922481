import { manageConstants } from '../constants';
import { museumSettingsService } from '../services';
import { alertActions } from './';
export const museumSetingsAction = {
	getMuseumLeadTime,
	putMuseumLeadTime,
    getMuseumNotifications,
	getmuseuMcapacity,
	getMuseumBlockedSlot,
	postMuseumBlockedSlot,
	putMuseumBlockedSlot,
	deleteMuseumBlockedSlot,
	putMuseumNotifications,
	putMuseumCapacity,
	getBlockedSlotDropDown
}
function getMuseumLeadTime(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.getMuseumLeadTime(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_LEAD_TIME_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_LEAD_TIME_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_LEAD_TIME_FAILURE, data };
	}
}

function putMuseumLeadTime(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.putMuseumLeadTime(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_MUSEUM_LEAD_TIME_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_MUSEUM_LEAD_TIME_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_MUSEUM_LEAD_TIME_FAILURE, data };
	}
}

function getMuseumNotifications(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.getMuseumNotifications(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_NOTIFICATIONS_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_NOTIFICATIONS_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_NOTIFICATIONS_FAILURE, data };
	}
}

function getmuseuMcapacity(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.getmuseuMcapacity(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_CAPACITY_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_CAPACITY_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_CAPACITY_FAILURE, data };
	}
}

function putMuseumCapacity(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.putMuseumCapacity(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_MUSEUM_CAPACITY_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_MUSEUM_CAPACITY_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_MUSEUM_CAPACITY_FAILURE, data };
	}
}

function getMuseumBlockedSlot(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.getMuseumBlockedSlot(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.GET_MUSEUM_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.GET_MUSEUM_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.GET_MUSEUM_BLOCK_SLOT_FAILURE, data };
	}
}
function getBlockedSlotDropDown(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.getBlockedSlotDropDown(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};
	function request(data) {
		return { type: manageConstants.GET_DROPDOWN_BLOCK_SLOT_REQUEST, data };
	}
	function success(data) {
		return { type: manageConstants.GET_DROPDOWN_BLOCK_SLOT_SUCCESS, data };
	}
	function failure(data) {
		return { type: manageConstants.GET_DROPDOWN_BLOCK_SLOT_FAILURE, data };
	}
}
function postMuseumBlockedSlot(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.postMuseumBlockedSlot(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.POST_MUSEUM_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.POST_MUSEUM_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.POST_MUSEUM_BLOCK_SLOT_FAILURE, data };
	}
}


function putMuseumBlockedSlot(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.putMuseumBlockedSlot(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_MUSEUM_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_MUSEUM_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_MUSEUM_BLOCK_SLOT_FAILURE, data };
	}
}

function deleteMuseumBlockedSlot(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.deleteMuseumBlockedSlot(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: manageConstants.DELETE_MUSEUM_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.DELETE_MUSEUM_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.DELETE_MUSEUM_BLOCK_SLOT_FAILURE, data };
	}
}

function putMuseumNotifications(data){
    return dispatch => {
		dispatch(request({ data }));
		museumSettingsService.putMuseumNotifications(data).then(
			data => {
				dispatch(success(data));
				if(data.Status==="Success")
					dispatch(alertActions.success(data.Message,true));
				else
				dispatch(alertActions.success(data.Message));
			},
			error => {
				dispatch(failure(error.Message));
					dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: manageConstants.PUT_MUSEUM_NOTIFICATIONS_REQUEST, data };
	}

	function success(data) {
		return { type: manageConstants.PUT_MUSEUM_NOTIFICATIONS_SUCCESS, data };
	}

	function failure(data) {
		return { type: manageConstants.PUT_MUSEUM_NOTIFICATIONS_FAILURE, data };
	}
}