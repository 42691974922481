import { qrCodeConstants } from "../constants";

export function postQrCodeDecrpt(state = {}, action) {
  switch (action.type) {
    case qrCodeConstants.POST_DECRYPT_DATA_REQUEST:
      return { bookingDetails: false };
    case qrCodeConstants.POST_DECRYPT_DATA_SUCCESS:
      return { bookingDetails: action.data };
    case qrCodeConstants.POST_DECRYPT_DATA_FAILURE:
      return { bookingDetails: false };
    default:
      return state;
  }
}