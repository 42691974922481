// From CoreUI template
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
// import { Router, Route } from 'react-router';
// import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './helpers/store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import { history } from './helpers/history';


// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('App');

ReactDOM.hydrate(
	<Provider store={store}>
		<App />
	</Provider>,
	rootElement
);

registerServiceWorker();
