import { museumProgrammeConstants } from '../constants';

export function programmemasterdata(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_REQUEST:
			return { gotmasterdata: false };
		case museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_SUCCESS:
			return { gotmasterdata: action.data };
		case museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_FAILURE:
			return { gotmasterdata: false };
		default:
			return state;
	}
}

export function programmeListing(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_PROGRAMME_REQUEST:
			return { gotprogrammedata: false };
		case museumProgrammeConstants.GET_PROGRAMME_SUCCESS:
			return { gotprogrammedata: action.data };
		case museumProgrammeConstants.GET_PROGRAMME_FAILURE:
			return { gotprogrammedata: false };
		default:
			return state;
	}
}

export function postprogrammeDetails(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.POST_PROGRAMME_DETAILS_REQUEST:
			return { programmeSubmited: false };
		case museumProgrammeConstants.POST_PROGRAMME_DETAILS_SUCCESS:
			return { programmeSubmited: action.data };
		case museumProgrammeConstants.POST_PROGRAMME_DETAILS_FAILURE:
			return { programmeSubmited: false };
		default:
			return state;
	}
}

export function getprogrammePreview(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_PROGRAMME_PREVIEW_REQUEST:
			return { gotpreviewdetails: false };
		case museumProgrammeConstants.GET_PROGRAMME_PREVIEW_SUCCESS:
			return { gotpreviewdetails: action.data };
		case museumProgrammeConstants.GET_PROGRAMME_PREVIEW_FAILURE:
			return { gotpreviewdetails: 'error',errorMsg: action.data};
		default:
			return state;
	}
}


export function puttprogrammeDetailsSubmited(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_REQUEST:
			return { programmsubmited: false };
		case museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_SUCCESS:
			return { programmsubmited: action.data };
		case museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_FAILURE:
			return { programmsubmited: false };
		default:
			return state;
	}
}

export function putprogrammeapprovereject(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_REQUEST:
			return { programestatus: false };
		case museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_SUCCESS:
			return { programestatus: action.data };
		case museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_FAILURE:
			return { programestatus: false };
		default:
			return state;
	}
}

export function deleteprogramme(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.DELETE_PROGRAMME_REQUEST:
			return { programedeleted: false };
		case museumProgrammeConstants.DELETE_PROGRAMME_SUCCESS:
			return { programedeleted: true };
		case museumProgrammeConstants.DELETE_PROGRAMME_FAILURE:
			return { programedeleted: true };
		default:
			return state;
	}
}

export function submitprogrammedetails(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_REQUEST:
			return { programesubmitedData: true };
		case museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_SUCCESS:
			return { programesubmitedData: action.data };
		case museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_FAILURE:
			return { programesubmitedData: 'error' };
		default:
			return state;
	}
}

export function getprogrammedetails(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_PROGRAMME_DETAILS_REQUEST:
			return { programedetails: true };
		case museumProgrammeConstants.GET_PROGRAMME_DETAILS_SUCCESS:
			return { programedetails: action.data };
		case museumProgrammeConstants.GET_PROGRAMME_DETAILS_FAILURE:
			return { programedeleted: false };
		default:
			return state;
	}
}

export function getsearchProgramme(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_REQUEST:
			return { searchprogramme: false };
		case museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_SUCCESS:
			return { searchprogramme: action.data };
		case museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_FAILURE:
			return { searchprogramme: false };
		default:
			return state;
	}
}

export function postFindProgrammeDetails(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.POST_FIND_PROGRAMME_REQUEST:
			return { findprogrammedetails: false };
		case museumProgrammeConstants.POST_FIND_PROGRAMME_SUCCESS:
			return { findprogrammedetails: action.data,
					selectedData: action.selectedData };
		case museumProgrammeConstants.POST_FIND_PROGRAMME_FAILURE:
			return { findprogrammedetails: false };
		default:
			return state;
	}
}

export function getcardProgramme(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_REQUEST:
			return { cardprogramme: false };
		case museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_SUCCESS:
			return { cardprogramme: action.data };
		case museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_FAILURE:
			return { cardprogramme: false,APIerror:true };
		default:
			return state;
	}
}
export function getProgrammeRecommendations(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_REQUEST:
			return { programmeRecommendations: false };
		case museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_SUCCESS:
			return { programmeRecommendations: action.data };
		case museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_FAILURE:
			return { programmeRecommendations: 'error' };
		default:
			return state;
	}
}
export function postExistingProgramme(state = {}, action) {
	switch (action.type) {
		case museumProgrammeConstants.POST_EXISTING_PROGRAMME_REQUEST:
			return { mirrorProgrammeDetails: 'Loading' };
		case museumProgrammeConstants.POST_EXISTING_PROGRAMME_SUCCESS:
			return { mirrorProgrammeDetails: action.data};
		case museumProgrammeConstants.POST_EXISTING_PROGRAMME_FAILURE:
			return { mirrorProgrammeDetails: false };
		default:
			return state;
	}
}

