import { manageConstants } from '../constants';

export function museumnotification(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_NOTIFICATIONS_REQUEST:
			return { gotnotifications: false };
		case manageConstants.GET_MUSEUM_NOTIFICATIONS_SUCCESS:
			return { gotnotifications: action.data };
		case manageConstants.GET_MUSEUM_NOTIFICATIONS_FAILURE:
			return { gotnotifications: false };

		default:
			return state;
	}
}

export function putmuseumnotification(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_MUSEUM_NOTIFICATIONS_REQUEST:
			return { updatednotifications: false };
		case manageConstants.PUT_MUSEUM_NOTIFICATIONS_SUCCESS:
			return { updatednotifications: action.data };
		case manageConstants.PUT_MUSEUM_NOTIFICATIONS_FAILURE:
			return { updatednotifications: 'reset' };
		default:
			return state;
	}
}
 