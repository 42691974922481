import  { tickerService } from '../services'
import { tickerConstants } from '../constants'
import { alertActions } from './';

export const tickerActions = {
	postTicker,
	putTicker,
	getTickerList,
	deleteTicker
}


function postTicker (data){
    return dispatch => {
		dispatch(request({ data }));
		tickerService.postTicker(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: tickerConstants.POST_TICKER_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: tickerConstants.POST_TICKER_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: tickerConstants.POST_TICKER_BLOCK_SLOT_FAILURE, data };
	}
}

function putTicker (data){
    return dispatch => {
		dispatch(request({ data }));
		tickerService.putTicker(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: tickerConstants.PUT_TICKER_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: tickerConstants.PUT_TICKER_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: tickerConstants.PUT_TICKER_BLOCK_SLOT_FAILURE, data };
	}
}


function getTickerList(data, homepage) {
	return dispatch => {
		dispatch(request({ data }));
		tickerService.getTickerList(data, homepage).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
    };
    
	function request(data) {
		return { type: tickerConstants.GET_MASTER_TICKERLIST_REQUEST, data };
	}

	function success(data) {
		return { type: tickerConstants.GET_MASTER_TICKERLIST_SUCCESS, data };
	}

	function failure(data) {
		return { type: tickerConstants.GET_MASTER_TICKERLIST_FAILURE, data };
	}
}

function deleteTicker (data){
    return dispatch => {
		dispatch(request({ data }));
		tickerService.deleteTicker(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: tickerConstants.DELETE_TICKER_BLOCK_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: tickerConstants.DELETE_TICKER_BLOCK_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: tickerConstants.DELETE_TICKER_BLOCK_SLOT_FAILURE, data };
	}
}