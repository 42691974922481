import { walkinConstants } from '../constants';
import {walkinService } from '../services';
import { masterConstants } from '../constants';
import { alertActions } from './';
export const walkinActions = {
	getWalkin,
	postWalkin,
	getMasterWalkinData,
	getAllProgram,
	postSearchProgramme

};


function getWalkin(data,type) {
	return dispatch => {
		dispatch(request({ data }));
		if(!type){
			walkinService.getWalkin(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
		}
	};

	function request(data) {
		return { type: walkinConstants.GET_WALKIN_REQUEST, data };
	}

	function success(data) {
		return { type: walkinConstants.GET_WALKIN_SUCCESS, data };
	}

	function failure(data) {
		return { type: walkinConstants.GET_WALKIN_FAILURE, data };
	}
}

function postWalkin(data) {
	return dispatch => {
		dispatch(request({ data }));
		walkinService.postWalkin(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: walkinConstants.POST_WALKIN_REQUEST, data };
	}

	function success(data) {
		return { type: walkinConstants.POST_WALKIN_SUCCESS, data };
	}

	function failure(data) {
		return { type: walkinConstants.POST_WALKIN_FAILURE, data };
	}
}

function getMasterWalkinData(data) {
	return dispatch => {
		dispatch(request({ data }));
		walkinService.getMasterWalkinData(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: walkinConstants.GET_MASTER_WALKIN_REQUEST, data };
	}

	function success(data) {
		return { type: walkinConstants.GET_MASTER_WALKIN_SUCCESS, data };
	}

	function failure(data) {
		return { type: walkinConstants.GET_MASTER_WALKIN_FAILURE, data };
	}
}

function getAllProgram(data) {
	return dispatch => {
		dispatch(request({ data }));
		walkinService.getAllProgram(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: masterConstants.GET_MASTER_BOOKING_FAILURE, data };
	}
}
function postSearchProgramme(data) {
	return dispatch => {
		dispatch(request({ data }));
		walkinService.postSearchProgramme(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
			}
		);
	};

	function request(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_REQUEST, data };
	}

	function success(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_SUCCESS, data };
	}

	function failure(data) {
		return { type: masterConstants.POST_SEARCH_CALENDAR_FAILURE, data };
	}
}


