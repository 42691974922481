export const tourbookingslotConstants = {
	BOOKING_SLOT_CLEAR: 'BOOKING_SLOT_CLEAR',
	BOOKING_SLOT_SUCCESS: 'BOOKING_SLOT_SUCCESS',
	BOOKING_SLOT_FAILURE: 'BOOKING_SLOT_FAILURE',

	PROGRAMME_WISE_SLOT_SUCCESS: 'PROGRAMME_WISE_SLOT_SUCCESS',
	PROGRAMME_WISE_SLOT_CLEAR: 'PROGRAMME_WISE_SLOT_CLEAR',

	
};
