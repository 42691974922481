export const userConstants = {
	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
	RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
	RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

	RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

	CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
	CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
	CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',

	CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
	CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

	LOGOUT: 'USERS_LOGOUT',

	GETSCHOOLS_REQUEST: 'GETSCHOOLS_REQUEST',
	GETSCHOOLS_SUCCESS: 'GETSCHOOLS_SUCCESS',
	GETSCHOOLS_FAILURE: 'GETSCHOOLS_FAILURE',

	GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

	GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
	GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
	GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',

	PUT_NOTIFICATION_REQUEST: 'PUT_NOTIFICATION_REQUEST',
	PUT_NOTIFICATION_SUCCESS: 'PUT_NOTIFICATION_SUCCESS',
	PUT_NOTIFICATION_FAILURE: 'PUT_NOTIFICATION_FAILURE',

	UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
	UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
	UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

	DELETE_PROFILE_REQUEST: 'DELETE_PROFILE_REQUEST',
	DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
	DELETE_PROFILE_FAILURE: 'DELETE_PROFILE_FAILURE',

	MYINFO_REQUEST: 'MYINFO_REQUEST',
	MYINFO_SUCCESS: 'MYINFO_SUCCESS',
	MYINFO_FAILURE: 'MYINFO_FAILURE',

	SWITCH_MUSEUM_REQUEST:'SWITCH_MUSEUM_REQUEST',
	SWITCH_MUSEUM_SUCCESS:'SWITCH_MUSEUM_SUCCESS',
	SWITCH_MUSEUM_FAILURE:'SWITCH_MUSEUM_FAILURE'
};
