import { loadUserFromStorage } from '../services/userService';

export async function authHeader() {
		let user=await loadUserFromStorage()
		if (user && user.id_token) {
			return { Authorization: 'Bearer ' + user.id_token };
		} else {
			return {};
		}	
}
