export const manageConstants = {
	GET_TEACHER_APPROVAL_REQUEST: 'GET_TEACHER_APPROVAL_REQUEST',
	GET_TEACHER_APPROVAL_SUCCESS: 'GET_TEACHER_APPROVAL_SUCCESS',
	GET_TEACHER_APPROVAL_FAILURE: 'GET_TEACHER_APPROVAL_FAILURE',

	PUT_TEACHER_APPROVAL_REQUEST: 'PUT_TEACHER_APPROVAL_REQUEST',
	PUT_TEACHER_APPROVAL_SUCCESS: 'PUT_TEACHER_APPROVAL_SUCCESS',
	PUT_TEACHER_APPROVAL_FAILURE: 'PUT_TEACHER_APPROVAL_FAILURE',

	GET_TEACHER_ACCOUNT_REQUEST: 'GET_TEACHER_ACCOUNT_REQUEST',
	GET_TEACHER_ACCOUNT_SUCCESS: 'GET_TEACHER_ACCOUNT_SUCCESS',
	GET_TEACHER_ACCOUNT_FAILURE: 'GET_TEACHER_ACCOUNT_FAILURE',


	POST_DASHBOARD_REQUEST: 'POST_DASHBOARD_REQUEST',
	POST_DASHBOARD_SUCCESS: 'POST_DASHBOARD_SUCCESS',
	POST_DASHBOARD_FAILURE: 'POST_DASHBOARD_FAILURE',


	PUT_TEACHER_ACCOUNT_REQUEST: 'PUT_TEACHER_ACCOUNT_REQUEST',
	PUT_TEACHER_ACCOUNT_SUCCESS: 'PUT_TEACHER_ACCOUNT_SUCCESS',
	PUT_TEACHER_ACCOUNT_FAILURE: 'PUT_TEACHER_ACCOUNT_FAILURE',

	GET_ROLE_MUSEUM_REQUEST: 'GET_ROLE_MUSEUM_REQUEST',
	GET_ROLE_MUSEUM_SUCCESS: 'GET_ROLE_MUSEUM_SUCCESS',
	GET_ROLE_MUSEUM_FAILURE: 'GET_ROLE_MUSEUM_FAILURE',

	GET_MUSEUM_ACCOUNT_REQUEST: 'GET_MUSEUM_ACCOUNT_REQUEST',
	GET_MUSEUM_ACCOUNT_SUCCESS: 'GET_MUSEUM_ACCOUNT_SUCCESS',
	GET_MUSEUM_ACCOUNT_FAILURE: 'GET_MUSEUM_ACCOUNT_FAILURE',

	GET_MUSEUM_LIST_REQUEST: 'GET_MUSEUM_LIST_REQUEST',
	GET_MUSEUM_LIST_SUCCESS: 'GET_MUSEUM_LIST_SUCCESS',
	GET_MUSEUM_LIST_FAILURE: 'GET_MUSEUM_LIST_FAILURE',

	POST_MUSEUM_LIST_REQUEST: 'POST_MUSEUM_LIST_REQUEST',
	POST_MUSEUM_LIST_SUCCESS: 'POST_MUSEUM_LIST_SUCCESS',
	POST_MUSEUM_LIST_FAILURE: 'POST_MUSEUM_LIST_FAILURE',

	PUT_MUSEUM_LIST_REQUEST: 'PUT_MUSEUM_LIST_REQUEST',
	PUT_MUSEUM_LIST_SUCCESS: 'PUT_MUSEUM_LIST_SUCCESS',
	PUT_MUSEUM_LIST_FAILURE: 'PUT_MUSEUM_LIST_FAILURE',

	DELETE_MUSEUM_LIST_REQUEST: 'DELETE_MUSEUM_LIST_REQUEST',
	DELETE_MUSEUM_LIST_SUCCESS: 'DELETE_MUSEUM_LIST_SUCCESS',
	DELETE_MUSEUM_LIST_FAILURE: 'DELETE_MUSEUM_LIST_FAILURE',

	CREATE_MUSEUM_ACCOUNT_REQUEST: 'CREATE_MUSEUM_ACCOUNT_REQUEST',
	CREATE_MUSEUM_ACCOUNT_SUCCESS: 'CREATE_MUSEUM_ACCOUNT_SUCCESS',
	CREATE_MUSEUM_ACCOUNT_FAILURE: 'CREATE_MUSEUM_ACCOUNT_FAILURE',

	RESET_ACCOUNT_REQUEST: 'RESET_ACCOUNT_REQUEST',
	RESET_ACCOUNT_SUCCESS: 'RESET_ACCOUNT_SUCCESS',
	RESET_ACCOUNT_FAILURE: 'RESET_ACCOUNT_FAILURE',

	PUT_LOCK_ACCOUNT_REQUEST: 'PUT_LOCK_ACCOUNT_REQUEST',
	PUT_LOCK_ACCOUNT_SUCCESS: 'PUT_LOCK_ACCOUNT_SUCCESS',
	PUT_LOCK_ACCOUNT_FAILURE: 'PUT_LOCK_ACCOUNT_FAILURE',

	EDIT_MUSEUM_ACCOUNT_REQUEST: 'EDIT_MUSEUM_ACCOUNT_REQUEST',
	EDIT_MUSEUM_ACCOUNT_SUCCESS: 'EDIT_MUSEUM_ACCOUNT_SUCCESS',
	EDIT_MUSEUM_ACCOUNT_FAILURE: 'EDIT_MUSEUM_ACCOUNT_FAILURE',

	DELETE_MUSEUM_ACCOUNT_REQUEST: 'DELETE_MUSEUM_ACCOUNT_REQUEST',
	DELETE_MUSEUM_ACCOUNT_SUCCESS: 'DELETE_MUSEUM_ACCOUNT_SUCCESS',
	DELETE_MUSEUM_ACCOUNT_FAILURE: 'DELETE_MUSEUM_ACCOUNT_FAILURE',


	DELETE_TEACHER_ACCOUNT_REQUEST: 'DELETE_TEACHER_ACCOUNT_REQUEST',
	DELETE_TEACHER_ACCOUNT_SUCCESS: 'DELETE_TEACHER_ACCOUNT_SUCCESS',
	DELETE_TEACHER_ACCOUNT_FAILURE: 'DELETE_TEACHER_ACCOUNT_FAILURE',

	/** NH */
	GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
	GET_ROLES_REQUEST_SUCCESS: 'GET_ROLES_REQUEST_SUCCESS',
	GET_ROLES_REQUEST_FAILURE: 'GET_ROLES_REQUES_FAILURE',

	POST_ROLES_REQUEST: 'POST_ROLES_REQUEST',
	POST_ROLES_REQUEST_SUCCESS: 'POST_ROLES_REQUEST_SUCCESS',
    POST_ROLES_REQUEST_FAILURE: 'POST_ROLES_REQUEST_FAILURE',

	///////////////////////////////MUSEM SETTING/////////////////////////////////////
	GET_MUSEUM_LEAD_TIME_REQUEST: 'GET_MUSEUM_LEAD_TIME_REQUEST',
	GET_MUSEUM_LEAD_TIME_SUCCESS: 'GET_MUSEUM_LEAD_TIME_SUCCESS',
	GET_MUSEUM_LEAD_TIME_FAILURE: 'GET_MUSEUM_LEAD_TIME_FAILURE',

	PUT_MUSEUM_LEAD_TIME_REQUEST: 'PUT_MUSEUM_LEAD_TIME_REQUEST',
	PUT_MUSEUM_LEAD_TIME_SUCCESS: 'PUT_MUSEUM_LEAD_TIME_SUCCESS',
	PUT_MUSEUM_LEAD_TIME_FAILURE: 'PUT_MUSEUM_LEAD_TIME_FAILURE',

	GET_MUSEUM_NOTIFICATIONS_REQUEST: 'GET_MUSEUM_NOTIFICATIONS_REQUEST',
	GET_MUSEUM_NOTIFICATIONS_SUCCESS: 'GET_MUSEUM_NOTIFICATIONS_SUCCESS',
	GET_MUSEUM_NOTIFICATIONS_FAILURE: 'GET_MUSEUM_NOTIFICATIONS_FAILURE',


	PUT_MUSEUM_NOTIFICATIONS_REQUEST: 'PUT_MUSEUM_NOTIFICATIONS_REQUEST',
	PUT_MUSEUM_NOTIFICATIONS_SUCCESS: 'PUT_MUSEUM_NOTIFICATIONS_SUCCESS',
	PUT_MUSEUM_NOTIFICATIONS_FAILURE: 'PUT_MUSEUM_NOTIFICATIONS_FAILURE',

	GET_MUSEUM_BLOCK_SLOT_REQUEST: 'GET_MUSEUM_BLOCK_SLOT_REQUEST',
	GET_MUSEUM_BLOCK_SLOT_SUCCESS: 'GET_MUSEUM_BLOCK_SLOT_SUCCESS',
	GET_MUSEUM_BLOCK_SLOT_FAILURE: 'GET_MUSEUM_BLOCK_SLOT_FAILURE',

	GET_DROPDOWN_BLOCK_SLOT_REQUEST: 'GET_DROPDOWN_BLOCK_SLOT_REQUEST',
	GET_DROPDOWN_BLOCK_SLOT_SUCCESS: 'GET_DROPDOWN_BLOCK_SLOT_SUCCESS',
	GET_DROPDOWN_BLOCK_SLOT_FAILURE: 'GET_DROPDOWN_BLOCK_SLOT_FAILURE',

	POST_MUSEUM_BLOCK_SLOT_REQUEST: 'POST_MUSEUM_BLOCK_SLOT_REQUEST',
	POST_MUSEUM_BLOCK_SLOT_SUCCESS: 'POST_MUSEUM_BLOCK_SLOT_SUCCESS',
	POST_MUSEUM_BLOCK_SLOT_FAILURE: 'POST_MUSEUM_BLOCK_SLOT_FAILURE',

	PUT_MUSEUM_BLOCK_SLOT_REQUEST: 'PUT_MUSEUM_BLOCK_SLOT_REQUEST',
	PUT_MUSEUM_BLOCK_SLOT_SUCCESS: 'PUT_MUSEUM_BLOCK_SLOT_SUCCESS',
	PUT_MUSEUM_BLOCK_SLOT_FAILURE: 'PUT_MUSEUM_BLOCK_SLOT_FAILURE',


	DELETE_MUSEUM_BLOCK_SLOT_REQUEST: 'DELETE_MUSEUM_BLOCK_SLOT_REQUEST',
	DELETE_MUSEUM_BLOCK_SLOT_SUCCESS: 'DELETE_MUSEUM_BLOCK_SLOT_SUCCESS',
	DELETE_MUSEUM_BLOCK_SLOT_FAILURE: 'DELETE_MUSEUM_BLOCK_SLOT_FAILURE',

	GET_MUSEUM_CAPACITY_REQUEST: 'GET_MUSEUM_CAPACITY_REQUEST',
	GET_MUSEUM_CAPACITY_SUCCESS: 'GET_MUSEUM_CAPACITY_SUCCESS',
	GET_MUSEUM_CAPACITY_FAILURE: 'GET_MUSEUM_CAPACITY_FAILURE',

	PUT_MUSEUM_CAPACITY_REQUEST: 'PUT_MUSEUM_CAPACITY_REQUEST',
	PUT_MUSEUM_CAPACITY_SUCCESS: 'PUT_MUSEUM_CAPACITY_SUCCESS',
	PUT_MUSEUM_CAPACITY_FAILURE: 'PUT_MUSEUM_CAPACITY_FAILURE',

	GET_BANNER_IMAGES_REQUEST: 'GET_BANNER_IMAGES_REQUEST',
	GET_BANNER_IMAGES_SUCCESS: 'GET_BANNER_IMAGES_SUCCESS',
	GET_BANNER_IMAGES_FAILURE: 'GET_BANNER_IMAGES_FAILURE',

	POST_BANNER_IMAGES_REQUEST: 'POST_BANNER_IMAGES_REQUEST',
	POST_BANNER_IMAGES_SUCCESS: 'POST_BANNER_IMAGES_SUCCESS',
	POST_BANNER_IMAGES_FAILURE: 'POST_BANNER_IMAGES_FAILURE',

	POST_RESOURCE_REQUEST: 'POST_RESOURCE_REQUEST',
	POST_RESOURCE_SUCCESS: 'POST_RESOURCE_SUCCESS',
	POST_RESOURCE_FAILURE: 'POST_RESOURCE_FAILURE',

	GET_MUSEUM_DROPDOWN_REQUEST:'GET_MUSEUM_DROPDOWN_REQUEST',
	GET_MUSEUM_DROPDOWN_SUCCESS:'GET_MUSEUM_DROPDOWN_SUCCESS',
	GET_MUSEUM_DROPDOWN_FAILURE:'GET_MUSEUM_DROPDOWN_FAILURE',

	GET_HOLIDAYS_REQUEST: 'GET_HOLIDAYS_REQUEST',
	GET_HOLIDAYS_SUCCESS: 'GET_HOLIDAYS_SUCCESS',
	GET_HOLIDAYS_FAILURE: 'GET_HOLIDAYS_FAILURE',

	PUT_HOLIDAYS_REQUEST: 'PUT_HOLIDAYS_REQUEST',
	PUT_HOLIDAYS_SUCCESS: 'PUT_HOLIDAYS_SUCCESS',
	PUT_HOLIDAYS_FAILURE: 'PUT_HOLIDAYS_FAILURE',
};
