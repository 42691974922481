import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import moment from 'moment'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const masterService = {
    getFilterRequest,
    getAllProgram,
    postSearchProgramme

};

function getFilterRequest(data) {
    const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  return fetch(baseUrl + '/master/managebookingdropdowndata',requestOptions).then(handleResponse);
}

function getAllProgram(data) {
    const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
    let queryString = 'userId=' + data.userId + '&TourDate=08/07/2020';
    return fetch(baseUrl + '/api/ManageBooking?' + queryString,requestOptions).then(handleResponse);
}

function postSearchProgramme(data,startDate,endDate) {
    let roleId = localStorage.getItem('UserRoleType');
    let newDate=moment(data.TourDate,'YYYY-MM-DD').format('DD/MM/YYYY')
    let apiData={
        ProgrammeId: data.ProgrammeList,
        TourTypeKey: data.TourType,
        StatusKey: data.BookingStatus,
        StartDate: newDate,
        EndDate: newDate,
        PaymentStatus:data.PaymentStatus,
        AdvanceSearch: data.AdvanceSearch,
        Pagination: data.Pagination,
    }
    let ReqData={}
    if (data.view === 'week') {
        let endOf = moment(
          data.TourDate,'YYYY-MM-DD'
        )
          .endOf('week')
          .format('DD/MM/YYYY');
        let startOf = moment(
          data.TourDate,'YYYY-MM-DD'
        )
          .startOf('week')
          .format('DD/MM/YYYY');
          apiData.StartDate= startOf;
          apiData.EndDate= endOf;
      }
      if (data.view === 'day') {
        let endOf = moment(
          data.TourDate ,'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        let startOf = moment(
          data.TourDate , 'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        apiData.StartDate= startOf;
        apiData.EndDate= endOf;


      }
      if (data.view === 'month') {
        let endOf = moment(
          data.TourDate,'YYYY-MM-DD'
        )
          .endOf('month')
          .format('DD/MM/YYYY');
        let startOf = moment(
          data.TourDate,'YYYY-MM-DD'
        )
          .startOf('month')
          .format('DD/MM/YYYY');
          apiData.StartDate= startOf;
          apiData.EndDate= endOf;
      }
      if(startDate&&endDate){
        let endOf = moment(
          endDate,'YYYY-MM-DD'
        )
          .format('DD/MM/YYYY');
        let startOf = moment(
          startDate ,'YYYY-MM-DD'
        )
          .format('DD/MM/YYYY');
          apiData.StartDate= startOf;
          apiData.EndDate= endOf;
      }

      if(apiData.AdvanceSearch){
        ReqData={
          AdvanceSearch: data.AdvanceSearch,
          Pagination: data.Pagination
        }
      }else{
        ReqData=apiData
      }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',...token },
        body: JSON.stringify(ReqData),
    };

    if(roleId === "MC"){
        return fetch(baseUrl + '/managebooking/calendarsearch?managedBy=1'+'&IsListView=' + data.IsListView, requestOptions).then(handleResponse);
    } else if(roleId === "MFD") {
        return fetch(baseUrl + '/managebooking/calendarsearch?managedBy=2'+'&IsListView=' + data.IsListView, requestOptions).then(handleResponse);
    }
    else if(roleId === "MF") {
        return fetch(baseUrl + '/managebooking/calendarsearch?managedBy=3'+'&IsListView=' + data.IsListView, requestOptions).then(handleResponse);
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
            var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
          sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
              }
              if(response.status === 500){
                let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
                window.location.reload(true);

              }
            //const error = (data && data.message) || response.statusText;
            return Promise.reject(data);
        }
        return data;
    });
}