import { combineReducers } from 'redux';

import {singPassUser} from './authReducer';
import { registration } from './registration.reducer';
import { alert,verifyEmail,serverError,mainLoader,TeacherBookingStaff,PaxPlaceholder } from './alert.reducer';
import { registerPopup } from './register.reducer';
import { authentication } from './authentication.reducer';
import { resendEmail } from './resendEmail.reducer';
import { getschools } from './schools.reducer';
import { postDashboard } from './dashboard.reducer';
import { postQrCodeDecrpt } from './qrCode.reducer';


import {putTeacherRequests } from './teacherrequests.reducer';
import { getTeacherAccounts, putTeacherAccounts ,deleteTeacherAccounts} from './teacheraccount.reducer';
import { getRoleMuseum } from './museum.reducer';
import { getMuseumAccount, getMuseumLists, postMuseumList, putMuseumList, deleteMuseum } from './museum.reducer';
import { createMuseumAccounts, putLockAccounts, resetAccounts, deleteMuseumAccounts } from './museum.reducer';
import { editMuseumAccounts,getBannerImages,postBannerImages,postResource,getMuseumDropdownDetails } from './museum.reducer';
import { getHolidaysList,putHolidaysList} from './museum.reducer';
import { getRoles } from './manageroles.reducer'; //NH
import { getuserprofile,accountMyInfo } from './getuserprofile.reducer';
import { getnotification, putNotification } from './getnotification.reducer';
import { updateuserprofile,switchMusuem } from './updateuserprofile.reducer';
import { deleteuserprofile } from './deleteuserprofile.reducer';
import { museumleadtime, putMuseumleadtime } from './museumleadtime.reducer';
import { museumnotification, putmuseumnotification } from './museumnotification.reducer';
import { museumcapacity, putmuseumcapacity } from './museumcapacity.reducer';
import { museumblockslot, postMuseumblockslot, putMuseumblockslot, deleteMuseumblockslot,getBlockedSlotDropDown } from './museumblockslot.reducer';
import { getcardProgramme,deleteprogramme, programmemasterdata, postprogrammeDetails, getprogrammePreview, puttprogrammeDetailsSubmited, programmeListing, putprogrammeapprovereject, submitprogrammedetails, getprogrammedetails, getsearchProgramme, postFindProgrammeDetails,getProgrammeRecommendations,postExistingProgramme } from './museumprogramme.reducer';
import { getProgrammeSlot, getbookedtour,booktour, storedtourbookedid } from './tourbooking.reducer';
import { getProgramFilter,getAllProgram, postSearchProgramme } from './mc.reducer';
import { getBookingDetails,PostMyBookings,PutMyBooking,DeleteMyBooking,GetMyBooking,getFeedbackQuestions,postFeedbackQuestions } from './teacherbooking.reducer';
import { getBookedSlotDetails, saveBookedSlotDetails, getInvoice, postFeesCalculation,postRemainder,postPrivateFeesCalculation } from './manageBooking.reducer';
import { getnewbooking, postnewbooking, getprivatebookedslot, putprivatebookedslot } from './newbooking.reducer';
import { getWalkin, getMasterWalkinData,postWalkin } from './walkin.reducer';
import { getReportsSA, postReportsSA,putResourceTracker } from './reportsSA.reducer';
import {postTicker , putTicker ,getTickerList ,deleteTicker } from './ticker.reducer';
import {getGlobalSearch, postFeedback } from './globalSearch.reducer';
import {getPreviewDashboard} from './museumStaffDashboard.reducer';
import {getFavorite, postFavorite, deleteFavorite} from './favorite.reducer';


const rootReducer = combineReducers({
	registration,
	alert,
	authentication,
	resendEmail,
	postDashboard,
	getschools,
	putTeacherRequests,
	getTeacherAccounts,
	putTeacherAccounts,
	getRoleMuseum,
	getMuseumAccount,
	getMuseumLists,
	postMuseumList,
	putMuseumList,
	deleteMuseum,
	createMuseumAccounts,
	putLockAccounts,
	resetAccounts,
	deleteMuseumAccounts,
	editMuseumAccounts,
	getRoles,
	getuserprofile,
	getnotification,
	putNotification,
	updateuserprofile,
	deleteuserprofile,
	museumleadtime,
	putMuseumleadtime,
	museumnotification,
	museumcapacity,
	putmuseumcapacity,
	museumblockslot,
	postMuseumblockslot,
	putMuseumblockslot,
	deleteMuseumblockslot,
	putmuseumnotification,
	programmemasterdata,
	postprogrammeDetails,
	getprogrammePreview,
	puttprogrammeDetailsSubmited,
	programmeListing,
	putprogrammeapprovereject,
	deleteprogramme,
	submitprogrammedetails,
	getprogrammedetails,
	getsearchProgramme,
	postFindProgrammeDetails,
	getProgrammeSlot,
	getcardProgramme,
	getbookedtour,
	booktour,
	storedtourbookedid,
	getProgramFilter,
	getAllProgram,
	postSearchProgramme,
	getBookingDetails,
	getBookedSlotDetails,
	saveBookedSlotDetails,
	getInvoice,
	postFeesCalculation,
	getnewbooking,
	postnewbooking,
	getprivatebookedslot,
	putprivatebookedslot,
	getWalkin,
	getMasterWalkinData,
	postWalkin,
	getReportsSA,
	postReportsSA,
	deleteTeacherAccounts,
	postTicker,
	getTickerList,
	putTicker,
	deleteTicker,
	PostMyBookings,
	PutMyBooking,
	DeleteMyBooking,
	GetMyBooking,
	getGlobalSearch,
	postFeedback,
	getPreviewDashboard,
	postRemainder,
	getProgrammeRecommendations,
	getFavorite,
	postFavorite,
	deleteFavorite,
	getBannerImages,
	postBannerImages,
	putResourceTracker,
	getFeedbackQuestions,
	postFeedbackQuestions,
	singPassUser,
	registerPopup,
	verifyEmail,
	postResource,
	serverError,
	mainLoader,
	accountMyInfo,
	postQrCodeDecrpt,
	postExistingProgramme,
	TeacherBookingStaff,
	PaxPlaceholder,
	switchMusuem,
	getMuseumDropdownDetails,
	getHolidaysList,
	putHolidaysList,
	getBlockedSlotDropDown,
	postPrivateFeesCalculation
});

export default rootReducer;
