import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { connect } from 'react-redux';
import { alertActions, SingPassAuth,userActions } from './actions';
import { Spinner,Col,Button,Modal } from 'reactstrap';
import NotifyModal from './components/modal/NotifyModal';
import SigninOidc from './pages/signin-oidc'
import SignoutOidc from './pages/signout-oidc'
import callback from './pages/callback'
import ErrorPage404 from './containers/DefaultLayout/ErrorPage404'
import userManager, { loadUserFromStorage } from './services/userService'
import AuthProvider from './utils/authProvider'
import { signinRedirect, signoutRedirect } from './services/userService'
import { useIdleTimer } from 'react-idle-timer'
import ConfirmModal from './components/modal/ConfirmModal';
import {ErrorResponse} from './services/ErrorResponseConstants'


const loading = () => <Spinner className="loading" />;
// Containers
const HomeLayout = React.lazy(() => import('./containers/DefaultLayout/HomeLayout'));
const InnerLayout = React.lazy(() => import('./containers/DefaultLayout/InnerLayout'));
let authorization = {
	"SA": ["/SA", "/manageroles", "/manageaccounts", "/listofmuseums", "/programmes", "/accountrequests", "/teacheraccounts", "/banners", "/reports", "/favourites", "/myprofile","/publicHolidays"],
	"MF": ["/MF", "/managebookingmf", "/reports", "/favourites", "/myprofile"],
	"MFD": ["/MFD", "/managebookingmfd", "/favourites", "/myprofile"],
	"MC": ["/MC", "/programmes", "/programmepreview", "/museumsettings", "/scheduleticker", "/managebookingmc", "/reports", "/favourites", "/myprofile","/publicHolidays"],
	"MA": ["/MA", "/manageroles", "/manageaccounts", "/programmes", "/museumsettings", "/scheduleticker", "/reports", "/favourites", "/myprofile","/publicHolidays"],
	"TE": ["/ProgrammeList", "/bookingform", "/mybookings", "/favourites", "/myprofile", "/globalsearch", "/programmepreview"]
}
let subArray=["/ProgrammeList", "/bookingform", "/mybookings"]
const RoleType = localStorage.getItem('UserRoleType');

function App(props) {
	const { type, message, status, clearAlerts, code,token,title,StaffsTE,checkValidRole } = { ...props };
	let [isOpen, setisOpen] = useState(false);
	let [timeout, setTimeout] = useState(60*60*1000);
	let [confirmModal, setconfirmModal] = useState(false);

	var localUser = JSON.parse(localStorage.getItem('user'));
	StaffsTE.forEach(obj=>authorization[obj].push(...subArray))

	const closeModal = () => {
		setisOpen(!isOpen);
		clearAlerts();
	}
	useEffect(() => {
		function storageEventHandler(event) {
			if(event.key==='UserRoleType'){
				if(event.newValue!==event.oldValue){
					checkValidRole(event.newValue,event.oldValue)
				}
			}
		}
		// Hook up the event handler
		window.addEventListener("storage", storageEventHandler);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener("storage", storageEventHandler);
		};
	}, []);
	useEffect(() => {
		if(localUser&&localUser.InActiveSessionExpiryInMins){
			setTimeout(localUser.InActiveSessionExpiryInMins*60*1000)
		}
		else{
			if (RoleType === 'TE') {
			setTimeout(15*60*1000)
		}
		else {
			setTimeout(60*60*1000)
		}
	}
	}, [RoleType])
	useEffect(()=>{
		var sessionMsg = JSON.parse(localStorage.getItem('SessionInformation'));
		var sessionTimeout =  sessionStorage.getItem('sessionTimeout');
		if(sessionMsg){
			setconfirmModal(true)
			localStorage.removeItem('SessionInformation');
		}
		if(!localUser&&sessionTimeout){
			props.errorPopup(sessionTimeout||ErrorResponse.ERROR_401_MESSAGE,true)
			sessionStorage.removeItem('sessionTimeout')
		}
	},[])

	const handleOnIdle = event => {
	 if(localUser){
		localStorage.setItem('SessionExpired', true)
		signoutRedirect()
}
  }

  const handleOnActive = event => {
    /*  console.log('user is active', event)
    console.log('time remaining', getRemainingTime()) */
  }

  const handleOnAction = event => {
	var time=new Date().toLocaleTimeString()
    // console.log('user did something', event,time)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
	crossTab:true
  })
	useEffect(() => {
		//No local storage but token exist (new Registration)----------------------

		let localUser = JSON.parse(localStorage.getItem('user'));
		let sessionKeys = Object.keys(sessionStorage)
		let tokenExist=false
		sessionKeys.map((key)=>{
			 if(key.startsWith("oidc.user"))
			 tokenExist=true
		})
		if (tokenExist && !localUser&&window && window.location &&
			!window.location.pathname.startsWith('/programmepreview')&&
			window.location.pathname !== "/confirmemail"&&
			window.location.pathname !== "/error/500"&&
			window.location.pathname !== "/error/401"&&
			window.location.pathname !== "/signin-oidc"&&
			window.location.pathname !== "/signout-oidc"&&
			window.location.pathname !== "/sessionTimeout"&&
			window.location.pathname !== "/callback"&&
			window.location.pathname.split('/')[1]!=='resources') {
			signoutRedirect()
		}
		//No local storage but token exist (new Registration)----------------------



		//New tab opened signin if localuser exist----------------------

		if (window && window.location &&
			!window.location.pathname.startsWith('/programmepreview')&&
			window.location.pathname !== "/confirmemail"&&
			window.location.pathname !== "/error/500"&&
			window.location.pathname !== "/error/401"&&
			window.location.pathname !== "/signin-oidc"&&
			window.location.pathname !== "/signout-oidc"&&
			window.location.pathname !== "/sessionTimeout"&&
			window.location.pathname !== "/callback"&&
			window.location.pathname.split('/')[1]!=='resources') {
			let user = loadUserFromStorage()
			user.then(data => {
				if (data === null && localUser) {
					signinRedirect()
				}
				else {
					props.storeUser(data)
				}
			})
		}
		//New tab opened signin----------------------

	}, [sessionStorage])
	useEffect(() => {
		if (status)
			setisOpen(!isOpen);
	}, [status])
	return (
			<AuthProvider userManager={userManager}>

			<React.Suspense fallback={loading()}>
				<BrowserRouter>
					<Switch>
						<Route exact path="/accountrequests" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/accountrequests") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/manageroles" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/manageroles") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/manageaccounts" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/manageaccounts") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/myprofile" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/myprofile") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/museumsettings" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/museumsettings") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/programmes" name="Dashboard" render={props => authorization[RoleType] && authorization[RoleType].includes("/programmes") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/MFD" name="Museum Front Desk" render={props => <InnerLayout {...props} /> } />
						<Route exact path="/MC" name="Museum Coordinator" render={props => <InnerLayout {...props} /> } />
						<Route exact path="/MA" name="Museum Administrator" render={props => <InnerLayout {...props} /> } />
						<Route exact path="/MF" name="Museum Finance" render={props =>  <InnerLayout {...props} /> } />
						<Route exact path="/SA" name="System Administrator" render={props => <InnerLayout {...props} /> } />
						<Route exact path="/TE" name="Teachers" render={props => authorization[RoleType] && authorization[RoleType].includes("/TE") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/listofmuseums" name="List of Museums" render={props => authorization[RoleType] && authorization[RoleType].includes("/listofmuseums") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/teacheraccounts" name="Teacher Accounts" render={props => authorization[RoleType] && authorization[RoleType].includes("/teacheraccounts") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/reports" name="Generate Reports" render={props => authorization[RoleType] && authorization[RoleType].includes("/reports") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/managebookingmc" name="Manage Booking" render={props => authorization[RoleType] && authorization[RoleType].includes("/managebookingmc") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/managebookingmfd" name="Manage Booking" render={props => authorization[RoleType] && authorization[RoleType].includes("/managebookingmfd") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/managebookingmf" name="Manage Booking" render={props => authorization[RoleType] && authorization[RoleType].includes("/managebookingmf") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/scheduleticker" name="Schedule Ticker" render={props => authorization[RoleType] && authorization[RoleType].includes("/scheduleticker") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						<Route exact path="/banners" name="Banners" render={props => authorization[RoleType] && authorization[RoleType].includes("/banners") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} />
						{/* <Route exact path="/publicHolidays" name="Holidays Upload" render={props => authorization[RoleType] && authorization[RoleType].includes("/publicHolidays") ? <InnerLayout {...props} /> : <Redirect from="/" to="/error/401" />} /> */}
						<Route exact path="/confirmemail" name="Confirm Email" render={props =><InnerLayout {...props} />}/>
						<Route
							exact
							path="/inprogress"
							name="Work in Progress"
							render={props => <InnerLayout {...props} />}
						/>
						<Route exact path="/" name="Home" render={props => <HomeLayout {...props} />} />
						<Route exact path="/index.html" name="Home" render={props => <HomeLayout {...props} />} />

						<Route  path="/programmepreview/:id" name="Programme Preview" render={props => <HomeLayout {...props} />} />
						<Route exact path="/globalsearch" name="Global Search" render={props => <HomeLayout {...props} />} />

						<Route exact path="/bookingform" name="Booking Form" render={props => authorization[RoleType] && authorization[RoleType].includes("/bookingform")?<HomeLayout {...props} />: <Redirect from="/" to="/error/401" />} />
						<Route exact path="/ProgrammeList" name="Programme List" render={props => authorization[RoleType] && authorization[RoleType].includes("/ProgrammeList")?<HomeLayout {...props} />: <Redirect from="/" to="/error/401" />} />
						<Route exact path="/mybookings" name="My Bookings" render={props => authorization[RoleType] && authorization[RoleType].includes("/mybookings")?<HomeLayout {...props} />: <Redirect from="/" to="/error/401" />} />
						<Route exact path="/favourites" name="Favorite" render={props => authorization[RoleType] && authorization[RoleType].includes("/favourites")?<HomeLayout {...props} />: <Redirect from="/" to="/error/401" />} />

						<Route exact path="/error/401" name="Unauthorized" render={props => <HomeLayout {...props} />} />
                        <Route exact path="/error/500" name="Server Error" render={props => <HomeLayout {...props} />} />
						<Route exact path="/sessionTimeout" name="Session Timeout" render={props => <HomeLayout {...props} />} />

						<Route exact path="/signout-oidc" component={SignoutOidc} />
						<Route exact path="/signin-oidc" component={SigninOidc} />
						<Route exact path="/callback" component={callback} />


						{window.location.pathname.split('/')[1]!=='resources'?<Route exact path="" component={ErrorPage404} />
						: <Redirect
						to={{
						  pathname: window.location.pathname,
						  search: window.location.search
						}}
					/>}

					</Switch>
					<NotifyModal parentClose={closeModal} isOpen={isOpen} type={type} message={message} code={code} token={token} title={title} />
				</BrowserRouter>
				{/* App-Login/Logout Loader */}
					  <Modal isOpen={props.loading} size="lg" className='loadingMainpage'>
						<div className=""><Spinner className="appLoader"/></div>
					{/* <div className='text-center fw-6 appLoader'>Loading...</div> */}
			         </Modal>
				{/* App-Login/Logout Loader */}

					<ConfirmModal isOpen={confirmModal} title={'Information'} content={localUser&&localUser.SessionInformation}>
					<Col md={{ size: 7, offset: 5 }} sm={{ size: 12, offset: 0 }}>
						<Button className="button-style6 pull-right" onClick={() => setconfirmModal(!confirmModal)} >Done</Button>
					</Col>
				    </ConfirmModal>

			</React.Suspense>
		</AuthProvider>

	);
}

function mapState(state) {

	return{
		type:state.alert.type,
		message:state.alert.message,
		code:state.alert.code,
		status:state.alert.status,
		token:state.alert.token,
		loading:state.mainLoader.loading,
		title:state.alert.title,
		StaffsTE:state.TeacherBookingStaff,

	}
}

const actionCreators = {
	mainLoader: alertActions.mainLoader,
	clearAlerts: alertActions.clear,
	storeUser: SingPassAuth.storeUser,
	errorPopup:alertActions.error,
	checkValidRole:userActions.checkValidRole

};

export default connect(mapState, actionCreators)(App);
