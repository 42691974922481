import { tourBookingConstants } from '../constants';

export function getProgrammeSlot(state = {}, action) {
	switch (action.type) {
		case tourBookingConstants.PUT_PROGRAMME_SLOT_REQUEST:
			return { getSlot: 'loading' };
		case tourBookingConstants.PUT_PROGRAMME_SLOT_SUCCESS:
			return { getSlot: action.data };
		case tourBookingConstants.PUT_PROGRAMME_SLOT_FAILURE:
			return { getSlot: false };
		default:
			return state;
	}
}

export function booktour(state = {}, action) {
	switch (action.type) {
		case tourBookingConstants.BOOK_TOUR_REQUEST:
			return { getData: false };
		case tourBookingConstants.BOOK_TOUR_SUCCESS:
			return {getData: action.data };
		case tourBookingConstants.BOOK_TOUR_FAILURE:
			return { getData: 'error' };
		default:
			return state;
	}
}

export function getbookedtour(state = {}, action) {
	switch (action.type) {
		case tourBookingConstants.GET_BOOKED_TOUR_REQUEST:
			return { getData: false, isLoad:true };
		case tourBookingConstants.GET_BOOKED_TOUR_SUCCESS:
			return {getData: action.data, isLoad:false };
		case tourBookingConstants.GET_BOOKED_TOUR_FAILURE:
			return { getData: false, isLoad:false };
		default:
			return state;
	}
}

export function storedtourbookedid(state = {}, action) {
	switch (action.type) {
		case tourBookingConstants.STROED_BOOKED_ID:
			return {getData: action.data };
		case tourBookingConstants.CLEAR_BOOKED_ID:
			return {getData: action.data };

		default:
			return state;
	}
}

export function confirmSlotSelection(state = {}, action){
	switch(action.type){
		case tourBookingConstants.GET_CONFIRM_SLOT_REQUEST:
			return {getData: false,  isLoading: true };
		case tourBookingConstants.GET_CONFIRM_SLOT_SUCCESS:
			return {getData: action.data,  isLoading: false };
		case tourBookingConstants.GET_CONFIRM_SLOT_FAILURE:
			return {getData: false,  isLoading: false };

		default:
			return state;

	}
}
