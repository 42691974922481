import { manageConstants } from '../constants';

export function museumcapacity(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_CAPACITY_REQUEST:
			return { gotcapacity: false };
		case manageConstants.GET_MUSEUM_CAPACITY_SUCCESS:
			return { gotcapacity: action.data };
		case manageConstants.GET_MUSEUM_CAPACITY_FAILURE:
			return { gotcapacity: false };
		default:
			return state;
	}
}

export function putmuseumcapacity(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_MUSEUM_CAPACITY_REQUEST:
			return { updatedcapacity: false };
		case manageConstants.PUT_MUSEUM_CAPACITY_SUCCESS:
			return { updatedcapacity: action.data };
		case manageConstants.PUT_MUSEUM_CAPACITY_FAILURE:
			return { updatedcapacity: false };
		default:
			return state;
	}
}
 