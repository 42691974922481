import { baseUrl } from '../helpers/env.config';
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const walkinService = {
  getWalkin,
  postWalkin,
  getMasterWalkinData,
  postSearchProgramme,
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
          sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
      }
      if(response.status === 500){
        let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
        window.location.reload(true);

      }
      return Promise.reject(data);
    }
    return data;
  });
}

function getWalkin(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
  let Tab = data.Tab;
  let bookingNumber = data.bookingNumber;
  return fetch(
    baseUrl + '/booking/walkin?bookingNumber=' + bookingNumber + '&Tab=' + Tab,requestOptions).then(handleResponse);
}


function postWalkin(data) {
  let tourDt = {};
  let bookingDt = {};
  let contactDt = {};
  let purposesWalkschool = [];
  let paymentDT = {};
  let confirmationDt = {};

  if (
    (data.GroupType === 'WalkinSchoolGroup' ||
      data.GroupType === 'BackDatedSchoolGroup') &&
    data.Purpose
  )
    for (let [key, value] of Object.entries(data.Purpose)) {
      purposesWalkschool.push(value.name);
    }

  tourDt = {
    CalendarId: data.CalendarId,
    ProgrammeId: data.ProgrammeId,
    GroupType: data.GroupType,
    Tab: data.Tab,
    IsBackDatedBooking: data.IsBackDatedBooking,
    BookingNumber: data.BookingNumber,
  };

  bookingDt = {
    BookingNumber: data.BookingNumber,
    ProgrammeId: data.ProgrammeId,
    GroupType: data.GroupType,
    CalendarId: data.CalenderId,
    TourType: data.TourType,
    Date: data.Date,
    Tab: data.Tab,
    IsBackDatedBooking: data.IsBackDatedBooking,
  };

  if (
    data.GroupType === 'WalkinPrivateGroup' ||
    data.GroupType === 'BackDatedPrivateGroup'
  ) {
    bookingDt.PrivateGroupData = {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      NoOfPax: data.NoOfPax,
      SlotData: {
        Language:data.Language,
        Purpose: data.Purpose,
        MCRemarks: data.MCRemarks,
        Attendees: {
          Adults: {
            Citizen: data.CitizenAdult,
            NonCitizen: data.NonCitizenAdult,
          },
          Children: {
            Citizen: data.CitizenChildren,
            NonCitizen: data.NonCitizenChildren,
          },
          Seniors: {
            Citizen: data.CitizenSenior,
            NonCitizen: data.NonCitizenSenior,
          },
          CMIO: data.CMIO
            ? {
                Chinese: data.Chinese,
                Malay: data.Malay,
                Indian: data.Indian,
                Others: data.Others,
              }
            : null,
        },
        PerPaxCost: {
          Adults: {
            Citizen: data.CitizenAdultCost,
            NonCitizen: data.NonCitizenAdultCost,
          },
          Children: {
            Citizen: data.CitizenChildrenCost,
            NonCitizen: data.NonCitizenChildrenCost,
          },
          Seniors: {
            Citizen: data.CitizenSeniorCost,
            NonCitizen: data.NonCitizenSeniorCost,
          },
        },
      },
    };
  }
  if (
    data.GroupType === 'WalkinSchoolGroup' ||
    data.GroupType === 'BackDatedSchoolGroup'
  ) {
    bookingDt.SchoolGroupData = {
      NoOfPax: data.NoOfPax,
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        Language:data.Language,
        PurposeOfVisit: purposesWalkschool,
        Remarks: {
          MCRemarks: data.MCRemarks,
        },
        EducationLevels:Array.isArray(data.EducationLevel)?data.EducationLevel:[data.EducationLevel] ,
        Attendees: {
          Students: {
            Citizen: data.CitizenStudent,
            NonCitizen: data.NonCitizenStudent,
          },
          Adults: {
            Citizen: data.CitizenAdult,
            NonCitizen: data.NonCitizenAdult,
          },
          CMIO: data.CMIO
            ? {
                Chinese: data.Chinese,
                Malay: data.Malay,
                Indian: data.Indian,
                Others: data.Others,
              }
            : null,
        },
      },
    };
  }

  contactDt = {
    BookingNumber: data.BookingNumber,
    ProgrammeId: data.ProgrammeId,
    GroupType: data.GroupType,
    CalendarId: data.CalenderId,
    TourType: 'Self-Guided',
    Date: data.Date,
    PrivateGroupData: {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        CoOrdinator: {
          Name: data.FullName,
          Email: data.Email,
          MobileNumber: data.MobileNumber,
          School: {
            SchoolId: 'Orgaination ID',
            SchoolName: data.Organisation,
          },
        },
      },
    },

    Tab: data.Tab,
    IsBackDatedBooking: data.IsBackDatedBooking,
  };

  if (
    data.GroupType === 'WalkinPrivateGroup' ||
    data.GroupType === 'BackDatedPrivateGroup'
  ) {
    contactDt.PrivateGroupData = {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        CoOrdinator: {
          Name: data.FullName,
          Email: data.Email,
          MobileNumber: data.MobileNumber,
          School: {
            SchoolId: 'Orgaination ID',
            SchoolName: data.Organisation,
          },
        },
      },
    };
  }

  if (
    data.GroupType === 'WalkinSchoolGroup' ||
    data.GroupType === 'BackDatedSchoolGroup'
  ) {
    contactDt.SchoolGroupData = {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        TeacherDetails: {
          Name: data.FullName,
          Email: data.Email,
          MobileNumber: data.MobileNumber,
          School: {
            SchoolId: 'Orgaination ID',
            SchoolName: data.Organisation,
          },
        },
      },
    };
  }

  paymentDT = {
    BookingNumber: data.BookingNumber,
    ProgrammeId: data.ProgrammeId,
    GroupType: data.GroupType,
    CalendarId: data.CalenderId,
    TourType: 'Self-Guided',
    Date: data.Date,

    Tab: data.Tab,
    IsBackDatedBooking: data.IsBackDatedBooking,
  };

  if (
    data.GroupType === 'WalkinPrivateGroup' ||
    data.GroupType === 'BackDatedPrivateGroup'
  ) {
    paymentDT.PrivateGroupData = {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        BookingPaymentDetails: {
          ModeOfPayment: data.ModeOfPayment,
          PaymentReferenceNumber: data.PaymentReferenceNumber,
          IsPaymentReceived: data.IsPaymentReceived,
          DateOfReceived :data.IsPaymentNotApplicable
          ?'':data.DateOfReceived,
          IsPaymentNotApplicable: data.IsPaymentNotApplicable
        },
      },
    };
  }

  if (
    data.GroupType === 'WalkinSchoolGroup' ||
    data.GroupType === 'BackDatedSchoolGroup'
  ) {
    paymentDT.SchoolGroupData = {
      CalenderId: data.CalenderId,
      ProgrammeId: data.ProgrammeId,
      SlotData: {
        BookingPaymentDetails: {
          ModeOfPayment: data.ModeOfPayment,
          PaymentReferenceNumber: data.PaymentReferenceNumber,
          IsPaymentReceived: data.IsPaymentReceived,
          DateOfReceived :data.IsPaymentNotApplicable
          ?'':data.DateOfReceived,
          IsPaymentNotApplicable: data.IsPaymentNotApplicable
        },
      },
    };
  }

  confirmationDt = {
    BookingNumber: data.BookingNumber,
    ProgrammeId: data.ProgrammeId,
    GroupType: data.GroupType,
    CalendarId: data.CalenderId,
    TourType: 'Self-Guided',
    Tab: data.Tab,
    IsBackDatedBooking: data.IsBackDatedBooking,
  };

  let requestOptions = '';
  requestOptions = {
    method: data.BookingNumber === '' ? 'POST' : 'PUT',
    headers: { 'Content-Type': 'application/json',...token },
    body:
      data.Tab === 1
        ? JSON.stringify(tourDt)
        : data.Tab === 2
        ? JSON.stringify(bookingDt)
        : data.Tab === 3
        ? JSON.stringify(contactDt)
        : data.Tab === 4
        ? JSON.stringify(paymentDT)
        : JSON.stringify(confirmationDt),
  };

  return fetch(baseUrl + '/booking/walkin', requestOptions).then(handleResponse);
}

function getMasterWalkinData(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
  let queryString = 'bookingDateTime=' + data.bookingDate;
  return fetch(
    baseUrl + '/master/managebookingwalkindropdowndata/?' + queryString,requestOptions).then(handleResponse);
}

function postSearchProgramme(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',...token },
    body: JSON.stringify({
      ProgrammeId: data.ProgrammeId,
      GroupType: data.GroupType,
      Programme: data.Programme,
      Date: data.Date,
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      Tab: data.Tab,
    }),
  };

  return fetch(baseUrl + '/booking/walkin', requestOptions).then(
    handleResponse
  );
}
