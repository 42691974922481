import React, { useEffect } from 'react'
import { signoutRedirectCallback } from '../services/userService'
import { useHistory } from 'react-router-dom'

function SignoutOidc() {
const SessionExpired = localStorage.getItem('SessionExpired');

  const history = useHistory()
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      if(SessionExpired){
      history.push('/sessionTimeout')
			localStorage.removeItem('SessionExpired', { path: '/' })
    }
      else
      history.push('/')
    }
    signoutAsync()
  }, [history])

  return (
    <div>
      Redirecting...
    </div>
  )
}

export default SignoutOidc
