export const manageBookingConstants = {
  GET_BOOKED_SLOT_REQUEST: "GET_BOOKED_SLOT_REQUEST",
  GET_BOOKED_SLOT_SUCCESS: "GET_BOOKED_SLOT_SUCCESS",
  GET_BOOKED_SLOT_FAILURE: "GET_BOOKED_SLOT_FAILURE",

  SAVE_BOOKED_SLOT_REQUEST: "SAVE_BOOKED_SLOT_REQUEST",
  SAVE_BOOKED_SLOT_SUCCESS: "SAVE_BOOKED_SLOT_SUCCESS",
  SAVE_BOOKED_SLOT_FAILURE: "SAVE_BOOKED_SLOT_FAILURE",

  GET_INVOICE_REQUEST: "GET_INVOICE_REQUEST",
  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_FAILURE: "GET_INVOICE_FAILURE",

  POST_FEES_REQUEST: "POST_FEES_REQUEST",
  POST_FEES_SUCCESS: "POST_FEES_SUCCESS",
  POST_FEES_FAILURE: "POST_FEES_FAILURE",

  POST_PRIVATE_FEES_REQUEST: "POST_PRIVATE_FEES_REQUEST",
  POST_PRIVATE_FEES_SUCCESS: "POST_PRIVATE_FEES_SUCCESS",
  POST_PRIVATE_FEES_FAILURE: "POST_PRIVATE_FEES_FAILURE",

  POST_REMINDER_REQUEST: "POST_REMINDER_REQUEST",
  POST_REMINDER_SUCCESS: "POST_REMINDER_SUCCESS",
  POST_REMINDER_FAILURE: "POST_REMINDER_FAILURE",

};
