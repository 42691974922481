import { manageConstants } from '../constants';

export function museumleadtime(state = {}, action) {
	switch (action.type) {
		case manageConstants.GET_MUSEUM_LEAD_TIME_REQUEST:
			return { gotleadtime: false };
		case manageConstants.GET_MUSEUM_LEAD_TIME_SUCCESS:
			return { gotleadtime: action.data };
		case manageConstants.GET_MUSEUM_LEAD_TIME_FAILURE:
			return { gotleadtime: false };
		default:
			return state;
	}
}

export function putMuseumleadtime(state = {}, action) {
	switch (action.type) {
		case manageConstants.PUT_MUSEUM_LEAD_TIME_REQUEST:
			return { updatedleadtime: false };
		case manageConstants.PUT_MUSEUM_LEAD_TIME_SUCCESS:
			return { updatedleadtime: true };
		case manageConstants.PUT_MUSEUM_LEAD_TIME_FAILURE:
			return { updatedleadtime: false };
		default:
			return state;
	}
}
 