import { alertConstants } from '../constants';
var initialState  = ['MC','MFD']
export function alert(state = {}, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				type: 'alert-success',
				message: action.message,
				status: action.status,
				code:action.code,
				title:action.title
			};
		case alertConstants.ERROR:
			return {
				type: 'alert-danger',
				message: action.message,
				status: action.status,
				code:action.code,
				token:action.token

			};
		case alertConstants.CLEAR:
			return {};
		default:
			return state;
	}
}
export function verifyEmail(state = {}, action) {
	switch (action.type) {
		
		case 'VERIFY_EMAIL':
				return {
					status: action.status,
					value:action.code
				};

		default:
			return state;
	}
}
export function serverError(state = {}, action) {
	switch (action.type) {
		
		case 'SERVER_ERROR':
				return {
					status: action.status,
					value:action.value
				};

		default:
			return state;
	}
}
export function mainLoader(state = {}, action) {
	switch (action.type) {
		
		case 'MAIN_LOADER':
				return {
					loading:action.loading
				};

		default:
			return state;
	}
}
export function TeacherBookingStaff(state = initialState) {
			return state;
}
export function PaxPlaceholder(state = 'Enter Pax') {
	return state;
}