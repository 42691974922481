import { museumStaffDashboardConstants } from '../constants'

export function getPreviewDashboard(state = {}, action){
	switch(action.type){
		case museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_REQUEST:
			return{getData: false};
		case museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_SUCCESS:
			return{getData: action.data};
		case museumStaffDashboardConstants.GET_MASTER_PREVIEWDASHBOARD_FAILURE:
			return{getData: false};
		default:
			return state;
	}
}