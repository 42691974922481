import { globalSearchConstants } from '../constants';

export function getGlobalSearch(state = {}, action) {
	switch (action.type) {
		case globalSearchConstants.GET_GLOBAL_SEARCH_REQUEST:
			return { getData: false };
		case globalSearchConstants.GET_GLOBAL_SEARCH_SUCCESS:
			return { getData: action.data };
		case globalSearchConstants.GET_GLOBAL_SEARCH_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function postFeedback(state = {}, action) {
	switch (action.type) {
		case globalSearchConstants.POST_FEEDBACK_REQUEST:
			return { getData: false };
		case globalSearchConstants.POST_FEEDBACK_SUCCESS:
			return { getData: action.data };
		case globalSearchConstants.POST_FEEDBACK_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}