import { userConstants } from '../constants';

export function resendEmail(state = {}, action) {
	switch (action.type) {
		case userConstants.RESEND_EMAIL_REQUEST:
			return { resetlink: false };
		case userConstants.RESEND_EMAIL_SUCCESS:
			return { resetlink: true };
		case userConstants.RESEND_EMAIL_FAILURE:
			return { resetlink: false };
		default:
			return state;
	}
}
