import { userConstants } from '../constants';

export function getschools(state = {}, action) {
	switch (action.type) {
		case userConstants.GETSCHOOLS_REQUEST:
			return { gotschools: false };
		case userConstants.GETSCHOOLS_SUCCESS:
			return { gotschools: action.data };
		case userConstants.GETSCHOOLS_FAILURE:
			return { gotschools: false };
		default:
			return state;
	}
}
