import { newBookingConstants } from '../constants';
import { newBookingService } from '../services';
import { alertActions } from '.';

export const newBookingActions = {
	getNewBooking,
	postNewBooking,
	getPrivateBookedSlot,
	putPrivateBookedSlot
};


function getNewBooking(data) {
	return dispatch => {
		dispatch(request({ data }));
		newBookingService.getNewBooking(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: newBookingConstants.GET_NEW_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: newBookingConstants.GET_NEW_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: newBookingConstants.GET_NEW_BOOKING_FAILURE, data };
	}
}

function postNewBooking(data,i) {
	return dispatch => {
		dispatch(request({ data }));
		if(!i){
			newBookingService.postNewBooking(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));

			}
		);
		}
	};

	function request(data) {
		return { type: newBookingConstants.POST_NEW_BOOKING_REQUEST, data };
	}

	function success(data) {
		return { type: newBookingConstants.POST_NEW_BOOKING_SUCCESS, data };
	}

	function failure(data) {
		return { type: newBookingConstants.POST_NEW_BOOKING_FAILURE, data };
	}
}

function getPrivateBookedSlot(data,clear) {
	return dispatch => {
		dispatch(request({ data }));
		if(!clear){
		newBookingService.getPrivateBookedSlot(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
		}
	};

	function request(data) {
		return { type: newBookingConstants.GET_PRIVATE_BOOKED_SLOT_REQUEST, data };
	}

	function success(data) {
		return { type: newBookingConstants.GET_PRIVATE_BOOKED_SLOT_SUCCESS, data };
	}

	function failure(data) {
		return { type: newBookingConstants.GET_PRIVATE_BOOKED_SLOT_FAILURE, data };
	}
}

function putPrivateBookedSlot(data) {
	return (dispatch) => {
	  dispatch(request({ data }));
	  newBookingService.putPrivateBookedSlot(data).then(
		(data) => {
		  dispatch(success(data));
		//   dispatch(getPrivateBookedSlot());
		  dispatch(alertActions.success(data.Message, true));
		},
		(error) => {
		  dispatch(failure(error.Message));
		  dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
		}
	  );
	};
	function request(data) {
	  return { type: newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_REQUEST, data };
	}
	function success(data) {
	  return { type: newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_SUCCESS, data };
	}
	function failure(data) {
	  return { type: newBookingConstants.PUT_PRIVATE_BOOKED_SLOT_FAILURE, data };
	}
  }


