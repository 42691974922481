import { baseUrl } from "../helpers/env.config";
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import  store from '../helpers/store.js'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});
export const manageBookingService = {
  getBookedSlotDetails,
  saveBookedSlotDetails,
  getInvoice,
  postFeesCalculation,
  postPrivateFeesCalculation,
  postRemainder
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        	var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
          sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
        return null
      }
      if(response.status === 500){
        let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
        window.location.reload(true);

      }
      return Promise.reject(data);
    }
    return data;
  });
}

function getBookedSlotDetails(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
  const bookingNumber = data;
  return fetch(
    `${baseUrl}/managebooking/bookedslot?bookingNumber=${bookingNumber}`,requestOptions).then(handleResponse);
}

function saveBookedSlotDetails(data) {
  let roleId = localStorage.getItem('UserRoleType');
  data.TourTypeKey=data.TourType
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  if(roleId === "MC"){
    return fetch(baseUrl + '/managebooking/bookedslot?managedBy=1', requestOptions).then(handleResponse);
  } else if(roleId === "MFD"){
    return fetch(baseUrl + '/managebooking/bookedslot?managedBy=2', requestOptions).then(handleResponse);
  }
  else if(roleId === "MF"){
    return fetch(baseUrl + '/managebooking/bookedslot?managedBy=3', requestOptions).then(handleResponse);
  }
}

function getInvoice(data) {
  const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
    responseType: 'blob'
	};
  let queryString = 'bookingNumber=' + data.bookingNumber ;
  return fetch(baseUrl + '/managebooking/Invoice?' + queryString,requestOptions).then(response => response.blob())
  .then(function(myBlob) {
    const file = new Blob(
      [myBlob],
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  });
}

function postFeesCalculation(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + '/managebooking/bookedslot/calculatefees', requestOptions).then(handleResponse);
}

function postPrivateFeesCalculation(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + '/managebooking/private/bookedslot/calculatefees', requestOptions).then(handleResponse);
}

function postRemainder(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": 'application/json',...token },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl + '/managebooking/sendbookingreminder', requestOptions).then(handleResponse);
}