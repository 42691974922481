export const newBookingConstants = {

	GET_NEW_BOOKING_REQUEST: 'GET_NEW_BOOKING_REQUEST',
	GET_NEW_BOOKING_SUCCESS: 'GET_NEW_BOOKING_SUCCESS',
	GET_NEW_BOOKING_FAILURE: 'GET_NEW_BOOKING_FAILURE',

	POST_NEW_BOOKING_REQUEST: 'POST_NEW_BOOKING_REQUEST',
	POST_NEW_BOOKING_SUCCESS: 'POST_NEW_BOOKING_SUCCESS',
	POST_NEW_BOOKING_FAILURE: 'POST_NEW_BOOKING_FAILURE',

	GET_PRIVATE_BOOKED_SLOT_REQUEST: 'GET_PRIVATE_BOOKED_SLOT_REQUEST',
	GET_PRIVATE_BOOKED_SLOT_SUCCESS: 'GET_PRIVATE_BOOKED_SLOT_SUCCESS',
	GET_PRIVATE_BOOKED_SLOT_FAILURE: 'GET_PRIVATE_BOOKED_SLOT_FAILURE',

	PUT_PRIVATE_BOOKED_SLOT_REQUEST: 'PUT_PRIVATE_BOOKED_SLOT_REQUEST',
	PUT_PRIVATE_BOOKED_SLOT_SUCCESS: 'PUT_PRIVATE_BOOKED_SLOT_SUCCESS',
	PUT_PRIVATE_BOOKED_SLOT_FAILURE: 'PUT_PRIVATE_BOOKED_SLOT_FAILURE',

}