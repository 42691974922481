import { favoriteConstants } from '../constants';

export function getFavorite(state = {}, action) {
	switch (action.type) {
		case favoriteConstants.GET_FAVORITE_REQUEST:
			return { getData: false };
		case favoriteConstants.GET_FAVORITE_SUCCESS:
			return { getData: action.data };
		case favoriteConstants.GET_FAVORITE_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function postFavorite(state = {}, action) {
	switch (action.type) {
		case favoriteConstants.POST_FAVORITE_REQUEST:
			return { getData: false };
		case favoriteConstants.POST_FAVORITE_SUCCESS:
			return { getData: action.data,addedData: action.addedData };
		case favoriteConstants.POST_FAVORITE_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function deleteFavorite(state = {}, action) {
	switch (action.type) {
		case favoriteConstants.DELETE_FAVORITE_REQUEST:
			return { getData: false };
		case favoriteConstants.DELETE_FAVORITE_SUCCESS:
			return { getData: action.data,deletedData: action.deletedData};
		case favoriteConstants.DELETE_FAVORITE_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}