import { walkinConstants } from '../constants';

export function getWalkin(state = {}, action) {
	switch (action.type) {
		case walkinConstants.GET_WALKIN_REQUEST:
			return { getData: false };
		case walkinConstants.GET_WALKIN_SUCCESS:
			return {getData: action.data };
		case walkinConstants.GET_WALKIN_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}

export function postWalkin(state = {}, action) {
	switch (action.type) {
		case walkinConstants.POST_WALKIN_REQUEST:
			return { getData: false };
		case walkinConstants.POST_WALKIN_SUCCESS:
			return {getData: action.data };
		case walkinConstants.POST_WALKIN_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}


export function getMasterWalkinData(state = {}, action) {
	switch (action.type) {
		case walkinConstants.GET_MASTER_WALKIN_REQUEST:
			return { getData: false };
		case walkinConstants.GET_MASTER_WALKIN_SUCCESS:
			return { getData: action.data };
		case walkinConstants.GET_MASTER_WALKIN_FAILURE:
			return { getData: false };
		default:
			return state;
	}
}