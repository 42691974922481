import { baseUrl } from '../helpers/env.config';
import store from '../helpers/store'
import { authHeader } from '../helpers';
import {alertActions} from '../actions/alert.actions'
import { history } from '../helpers';
import {ErrorResponse} from './ErrorResponseConstants'
import { signoutRedirect } from './userService'

let JWT=authHeader()
let token={}
JWT.then(function(data) {
	token= data
});

export const museumProgrammeService = {
	getMuseumProgrammeMasterData,
	getProgrammePreview,
	putProgrammeSubmited,
	getProgrammeListing,
	putProgrammeApproveReject,
	deleteProgramme,
	submitProgrammeDetails,
	getProgrammeDetails,
	getSearchProgramme,
	postFilterProgramme,
	putGetProgrammeSlot,
	getCardProgramme,
    getProgrammeRecommendations,
	postExistingProgramme


}
function getMuseumProgrammeMasterData(Id){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	};
    return fetch(baseUrl + '/master/programdropdowndetails',requestOptions).then(handleResponse);
}
function submitProgrammeDetails(data){
	let jsonData  = {};
	let Method = "POST";
	if(data.ProgrammeId){
		Method = "PUT";
	}
	var localUser = JSON.parse(localStorage.getItem('user'));

	if(data.step === 1){
		let fieldStateValue = data.fieldStateValue;
		let EducationLevelAppliesTo = [];
		let resourceId = [];
		let Purposes = [];
		let Languages = [];
		let TourType = "";
		let sortededulevelArray = [];
			if(fieldStateValue.EducationLevel){
				fieldStateValue.EducationLevel.forEach((value=>{
					sortededulevelArray.push(value.value);
				}))
			}

		for(let [key, value] of Object.entries(data.EducationLevelAppliesTo)){

			let innerValue = [];
			if(sortededulevelArray.includes(key)){
			value.forEach((val)=>{
				innerValue.push(val.name);
			})}
			if(key === "Pre_School")
				EducationLevelAppliesTo.push({key:key.replace(/_/g,"-"),value:innerValue});
			else
				EducationLevelAppliesTo.push({key:key.replace(/_/g," "),value:innerValue});

		}

		if(fieldStateValue.TourType){
			fieldStateValue.TourType.forEach((value)=>{
				TourType ={Key: value.Key, Value: value.name} ;
			})
		}
		if(TourType !== "" && data.Purposes)
		for(let [key, value] of Object.entries(data.Purposes)){
			Purposes.push(value.name);

		}
		if(TourType !== "" && data.Languages)
		for(let [key, value] of Object.entries(data.Languages)){
			Languages.push(value.name);

		}
		if(fieldStateValue.ThumbnailImage&&fieldStateValue.ThumbnailImage.ResourceId) resourceId.push(fieldStateValue.ThumbnailImage.ResourceId);
		if(fieldStateValue.DetailImage&&fieldStateValue.DetailImage.ResourceId) resourceId.push(fieldStateValue.DetailImage.ResourceId);
		if(fieldStateValue.ListingPreviewImage&&fieldStateValue.ListingPreviewImage.ResourceId) resourceId.push(fieldStateValue.ListingPreviewImage.ResourceId);
		if(fieldStateValue.Resources) {
			fieldStateValue.Resources.forEach((obj)=>{
				if(obj.ResourceId){
					resourceId.push(obj.ResourceId);}
			})
		}
		if(fieldStateValue.TermsAndConditions&&fieldStateValue.TermsAndConditions.ResourceId) resourceId.push(fieldStateValue.TermsAndConditions.ResourceId);
		if(fieldStateValue.CancellationPolicy&&fieldStateValue.CancellationPolicy.ResourceId) resourceId.push(fieldStateValue.CancellationPolicy.ResourceId);

		jsonData = {
			ProgrammeId: data.ProgrammeId?data.ProgrammeId:"",
			Step:data.step,
			Title:data.ProgrammeTitle?data.ProgrammeTitle:"",
			TourType:TourType,
			BriefDescription:fieldStateValue.BriefDescription,
			FullDescription:fieldStateValue.FullDescription,
			MetaTags:data.MetaTags?data.MetaTags:null,
			EducationLevelAndAppliesTo :EducationLevelAppliesTo,

			ProgramDetails:{
				ResourceId: resourceId,
				Purposes:Purposes,
				Languages:Languages
			},
		}
	}
	if(data.step === 2){

		let SlotValue = data;
		let Days = [];
		let exceptionData = [];
		let durationSlot = SlotValue.DuartionPerSlot+"."+SlotValue.DurationPerSlotMin;
		if(SlotValue.Days){
		SlotValue.Days.forEach((value)=>{
				Days.push(value.key)
			})
		}
		if(SlotValue.Exceptions&&SlotValue.Exceptions.length>0){
			SlotValue.Exceptions.forEach((item)=>{
				let WeeksOfTheMonth=[]
				let ExDays=[]
				let exceptionObj={}
				if(item.WeeksOfTheMonth){
					item.WeeksOfTheMonth.forEach((value)=>{
							WeeksOfTheMonth.push(value.key)
						})
					}
					if(item.Days){
						item.Days.forEach((value)=>{
							ExDays.push(value.key)
						})
					}
				   if(item.Occurrence &&item.ExceptionTimeSpan){
					exceptionObj = {
							Occurrence:item.Occurrence,
							WeeksOfTheMonth:WeeksOfTheMonth,
							Days:ExDays,
							ExceptionTimeSpan:item.ExceptionTimeSpan,
						}
				   }
				   exceptionData.push(exceptionObj)
			})
		}
	   jsonData = {
		        ProgrammeId: data.ProgrammeId?data.ProgrammeId:"",
				Step:data.step,
				ProgramDetails:{
					SlotConfiguration:{
						Occurrence:SlotValue.SlotOccuerence,
						StartDate:SlotValue.StartDate,
						EndDate:SlotValue.EndDate,
						StartTime:SlotValue.StartTime,
						EndTime:SlotValue.EndTime,
						DuartionPerSlot:durationSlot,
						MaximumPax:SlotValue.MaxPax,
						MinimumPax:SlotValue.MinPax,
						ProgramHasFlexibleSlot:SlotValue.checkBoxState.ProgramHasFlexibleSlot,
						PgmCapacityNotAffectMuseumCapacity:SlotValue.checkBoxState.PgmCapacityNotAffectMuseumCapacity,
						AllowBookingOnHolidays:SlotValue.checkBoxState.AllowBookingOnHolidays,
						OnlyOneSlotCanBooked:SlotValue.checkBoxState.OnlyOneSlotCanBooked,
						Days:Days,
					  },
					  Exception:exceptionData
				}
	   }
	}
	if(data.step === 3){
		let FeesInfo = data;
		let feesObject ={};
		let schoolLevelValue = [];
		let adultFeesObject;
		let SpecialNeedsFeesObject;
		let feesApplicable;
		let resourceFeesObject;
		let docentFeesObject;
		let schoolLevel = [];
		let primarylevel = [];
		let PreSchoollevel = [];
		let Secondary = [];
		let JuniorCollegelevel = [];
		let Polytechnic = [];
		let ITE = [];
		let University = [];
		if(!FeesInfo.specialNeeds){
			if(FeesInfo.FeesType==="Free"){
				feesObject ={ FeesType:FeesInfo.FeesType}
			}

			if(FeesInfo.FeesType==="VariedForCitizens"){
				feesObject ={ FeesType:FeesInfo.FeesType,
					VariedForCitizensFee:{
					CitizenOrPRCostPerPax:FeesInfo.FeesType==="VariedForCitizens"?FeesInfo.CitizenOrPRCostPerPax:0,
					ForeignersCostPerPax:FeesInfo.FeesType==="VariedForCitizens"?FeesInfo.ForeignersCostPerPax:0,
				}}

			}else if(FeesInfo.FeesType==="FixedProgramCost"){
				feesObject ={FeesType:FeesInfo.FeesType,
					FixedCostProgramFee:{
						ProgrammeCost:FeesInfo.FeesType==="FixedProgramCost"?FeesInfo.ProgrammeCost:0,
						MaximumPax:FeesInfo.FeesType==="FixedProgramCost"?FeesInfo.MaximumPax:0,
						MinimumPax:FeesInfo.FeesType==="FixedProgramCost"?FeesInfo.MinimumPax:0,
					}
				}
			}else if(FeesInfo.FeesType==="FreeSpecified"){
				feesObject ={FeesType:FeesInfo.FeesType,
					FreeForSpecifiedStudentsFee:{
						NumberOfStudents:FeesInfo.FeesType==="FreeSpecified"?FeesInfo.NumberOfStudents:0,
						SubsequentCost:FeesInfo.FeesType==="FreeSpecified"?FeesInfo.SubsequentCost:0,
						MinimumPax:FeesInfo.FeesType==="FreeSpecified"?FeesInfo.MinimumPax:0,
						MaximumPax:FeesInfo.FeesType==="FreeSpecified"?FeesInfo.MaximumPax:0,
					}
				}
			}else if(FeesInfo.FeesType==="VariedEducationLevel"){
				let educationalLevel = FeesInfo.educationalLevel;
					if(educationalLevel){
						for (const property in educationalLevel) {
							schoolLevel.push(property.substring(property.indexOf("/") + 1));
							let key = property.substr(0, property.indexOf('/'));
							if(property.substring(property.indexOf("/") + 1)==="Pre-School")
								PreSchoollevel.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="Primary")
								primarylevel.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="Secondary")
								Secondary.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="Junior College")
								JuniorCollegelevel.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="Polytechnic")
								Polytechnic.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="ITE")
								ITE.push({[key]:educationalLevel[property]})

							if(property.substring(property.indexOf("/") + 1)==="University")
								University.push({[key]:educationalLevel[property]})

						}
					}
					let tempArry = [...new Set(schoolLevel)];
					tempArry.forEach((val)=>{
						if(val==="Pre-School")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:PreSchoollevel[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:PreSchoollevel[1].ForeignersCostPerPax}})
						if(val==="Primary")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:primarylevel[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:primarylevel[1].ForeignersCostPerPax}})
						if(val==="Secondary")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:Secondary[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:Secondary[1].ForeignersCostPerPax}})
						if(val==="Polytechnic")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:Polytechnic[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:Polytechnic[1].ForeignersCostPerPax}})
						if(val==="ITE")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:ITE[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:ITE[1].ForeignersCostPerPax}})
						if(val==="University")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:University[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:University[1].ForeignersCostPerPax}})
						if(val==="Junior College")
						schoolLevelValue.push({"EducationLevel":val,"Cost":{CitizenOrPRCostPerPax:JuniorCollegelevel[0].CitizenOrPRCostPerPax,ForeignersCostPerPax:JuniorCollegelevel[1].ForeignersCostPerPax}})
					})
				feesObject ={FeesType:FeesInfo.FeesType,
					VariedAcrossEducationLevel:FeesInfo.FeesType==="VariedEducationLevel"?schoolLevelValue:[],
				}
			}else if(FeesInfo.FeesType==="VariedContactMuseum"){
				feesObject ={ FeesType:FeesInfo.FeesType}
			}
			if(FeesInfo.AdultFeesType==="VariedForCitizens"){
				adultFeesObject = {
					FeesType:FeesInfo.AdultFeesType,
					VariedForCitizensFee:{
						CitizenOrPRCostPerPax:FeesInfo.AdultFeesType==="VariedForCitizens"?FeesInfo.AdultCitizenOrPRCostPerPax:0,
						ForeignersCostPerPax:FeesInfo.AdultFeesType==="VariedForCitizens"?FeesInfo.AdultForeignersCostPerPax:0,
					}
				}
			}else if(FeesInfo.AdultFeesType==="Free"){
				adultFeesObject = {
					FeesType:FeesInfo.AdultFeesType
				}
			}

		}else{
			SpecialNeedsFeesObject ={FeesPerPair:FeesInfo.FeesPerPair,IsEnabled:FeesInfo.specialNeeds}
		}
		feesApplicable = {
			NonCitizensFees: {
				StudentsPerPax: FeesInfo.feeApplicable1==="IsNonCitizensEnabled"?FeesInfo.StudentsPerPaxNCS:0,
				AdultsPerPax: FeesInfo.feeApplicable1==="IsNonCitizensEnabled"?FeesInfo.AdultsPerPaxNCS:0,
			  },
			  ForeignExchangeFees: {
				StudentsPerPax:  FeesInfo.feeApplicable2==="IsForeignExchangeEnabled"?FeesInfo.StudentsPerPaxFEF:0,
				AdultsPerPax:  FeesInfo.feeApplicable2==="IsForeignExchangeEnabled"?FeesInfo.AdultsPerPaxFEF:0,
			  }
		}

	if(FeesInfo.resourceFees){
		resourceFeesObject = {
			IsEnabled:FeesInfo.resourceFees,
			FeesPerResource: FeesInfo.FeesPerResource,
			MinQuantity: FeesInfo.MinQuantity,
		}
	}
	if(FeesInfo.docentFees){
		docentFeesObject = FeesInfo.docentFees

	}else{
		docentFeesObject= FeesInfo.docentFees
	}
		jsonData = {
			ProgrammeId: data.ProgrammeId?data.ProgrammeId:"",
			Step:data.step,
			ProgramDetails:{
				Fees:{
					SpecialNeedsStudentFees:SpecialNeedsFeesObject,
					StudentFees:feesObject,
					AdultFees: adultFeesObject,
					SeperateProgramFees: feesApplicable,
					ResourcesFees:resourceFeesObject,
					IsDocentFeesEnabled:docentFeesObject,
				}
			}


		}
	}


	const requestOptions = {
		method: Method,
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/programme', requestOptions).then(handleResponse);
}


function getProgrammePreview(data) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token },
	}
	return fetch(baseUrl + '/programme/preview?programmeID='+data.ProgrammeId+'&viewType='+data.viewType,requestOptions).then(handleResponse);
}


function putProgrammeSubmited(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify({
			  ProgrammeId: data.ProgrammeId
		}),
	};
	return fetch(baseUrl + '/programme/submit', requestOptions).then(handleResponse);
}

function getProgrammeListing(roleId){
	var localUser = JSON.parse(localStorage.getItem('user'));
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	let queryString = "";
	if(roleId ==="SA"||(roleId==="MA"&&localUser.MuseumType==='Private'))
	   queryString = '?status=Pending Approval';
    return fetch(baseUrl + '/programme/list'+queryString,requestOptions).then(handleResponse);
}

function putProgrammeApproveReject(data) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/programme/request', requestOptions).then(handleResponse);
}

function deleteProgramme(data) {
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(data),
	};
	return fetch(baseUrl + '/programme', requestOptions).then(handleResponse);
}

function getProgrammeDetails(data) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	//return fetch(baseUrl + '/programme?programmeId='+ProgrammeId).then(handleResponse);
	if(data.IsFileContentRequired){
	return fetch(baseUrl + '/programme?programmeId='+data.programmeId+'&IsFileContentRequired='+data.IsFileContentRequired,requestOptions).then(handleResponse);
	}else{
		return fetch(baseUrl + '/programme?programmeId='+data,requestOptions).then(handleResponse);
	}
}
function getSearchProgramme() {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
	return fetch(baseUrl + '/master/searchprogramdropdowndata',requestOptions).then(handleResponse);
}


function postFilterProgramme(data) {
	let jsonData = {
		TourTypeKey:data.TourType,
		EducationLevel: data.EducationLevel,
		TourDate: data.TourDate,
		MuseumId : [],
		Pagination: data.Pagination,
		NoOfPax: data.NoOfPax,
	}
	if(data.redirection){
		jsonData.ProgrammeId=data.ProgrammeIds
	}
	if(data.MuseumId){
		jsonData.MuseumId= data.MuseumId

	}
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/programme/search', requestOptions).then(handleResponse);
}

function putGetProgrammeSlot(data){
	let jsonData = {
		StartDate:data.StartDate,
		EndDate: data.EndDate,
		ProgrammeIds : [],
	}
	if(data.ProgrammeIds){
		jsonData.ProgrammeIds= [data.MuseumId]
	}

	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json',...token},
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/booking/calender', requestOptions).then(handleResponse);

}
function getCardProgramme(data) {

	let cardsNo=3
	let museumID =[]

	if(data?.PageSize){
		cardsNo=data.PageSize
	}
	if(data?.Museum){
		museumID =[data.Museum]
	}
	let jsonData = {
		TourTypeKey: data?.Programme ,
		EducationLevel: data?.Education ,
		MuseumId: museumID,
		Pagination: {
		  PageNumber: data?.PageNumber,
		  PageSize: cardsNo,
		  OrderBy: data?.OrderBy
		}
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};
	return fetch(baseUrl + '/programme/home/search', requestOptions).then(handleResponse);
}
function getProgrammeRecommendations(data){
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json',...token }
	}
        return fetch(baseUrl + '/programme/recommendations?programmeId='+data.programmeId+'&pageNo='+data.pageNo+'&pageSize='+data.pageSize,requestOptions).then(handleRecommmendationResponse);
}
function postExistingProgramme(data) {
	let jsonData = {
		"ProgrammeId":data.ProgramId
	}


	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json',...token },
		body: JSON.stringify(jsonData),
	};

	return fetch(baseUrl + '/programme', requestOptions).then(handleResponse);
}
function handleRecommmendationResponse(response) {
	return response.text().then((text) => {
	  const data = text && JSON.parse(text);
	  if (!response.ok) {
		if (response.status === 401) {
			var localUser = JSON.parse(localStorage.getItem('user'));
			if (localUser) {
				localStorage.clear()
				sessionStorage.setItem('sessionTimeout', data && data.Message)
				signoutRedirect()
			}
			 return null
		}
		if(response.status === 500){
			let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
			window.location.reload(true);

		  }
		return Promise.reject(data);
	  }
	  if(response.status === 204){
		return {TotalCount: 0, Results: null}
	  }
	  return data;
	});
  }

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				var localUser = JSON.parse(localStorage.getItem('user'));
				if (localUser) {
					localStorage.clear()
					sessionStorage.setItem('sessionTimeout', data && data.Message)
					signoutRedirect()
				}
				return null
			  }
			  if(response.status === 500){
				let correlationId = data.Id
        if (correlationId) {
          history.push(`/error/500?Id=${correlationId}`)
        }
        else {
          history.push('/error/500')
        }
				window.location.reload(true);

			  }
			return Promise.reject(data);
		}
		if(response.status===204){
		    return null;
		}

		return data;
	});
}