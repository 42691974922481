import { userConstants } from '../constants';

export function deleteuserprofile(state = {}, action) {
	switch (action.type) {
		case userConstants.DELETE_PROFILE_REQUEST:
			return { deleteprofile: false };
		case userConstants.DELETE_PROFILE_SUCCESS:
			return { deleteprofile: true };
		case userConstants.DELETE_PROFILE_FAILURE:
			return { deleteprofile: false };
		default:
			return state;
	}
}
